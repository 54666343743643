import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { auth, forgotEmailStatusReducer } from './reducers/auth';
import { highlights } from './reducers/highlights';
import { savedFilters } from './reducers/savedFilters';
import { logoReducer } from './reducers/logo';
import { spacesReducer } from './reducers/spaces';
import { summary } from './reducers/summary';
import { userReducer } from './reducers/user';
import { systemFeaturesReducer } from './reducers/systemfeatures';
import { heatmapReducer } from './reducers/heatmapReducer';
import { footfallReducer } from "./reducers/footfallReducer";
import { categoryReducer } from "./reducers/categoryReducer";
import { comparisonReducer } from './reducers/comparisonReducer';
import { insightsReducer } from './reducers/insights';
import { conversionReducer } from './reducers/conversionReducer';

import { actionTypes } from './constants/action-types';
import { storage } from '../helpers/common';
import { floorReducer } from './reducers/floorReducer';
import {employeActivity} from "./reducers/employeActivity"

const combinedReducer = combineReducers({
  auth: auth,
  highlights: highlights,
  summary: summary,
  savedFilters: savedFilters,
  clientsUser : userReducer,
  spaces : spacesReducer,
  logo : logoReducer,
  systemFeatures : systemFeaturesReducer,
  emailStatus : forgotEmailStatusReducer,
  heatmapReducer : heatmapReducer,
  footfallReducer : footfallReducer,
  compare: comparisonReducer,
  insights : insightsReducer,
  categoryReducer : categoryReducer,
  floorReducer : floorReducer,
  conversion: conversionReducer,
  employeActivity : employeActivity,
});

const rootReducer = (state, action) => {
   if (action.type === actionTypes.LOGOUT) {
    state = undefined;
    storage.clear();
    
  }
  return combinedReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      immutableCheck: false,
    serializableCheck: false,
    }),
});
