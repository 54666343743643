import {actionTypes} from '../constants/action-types'

export const setLoading = (payload) => {
    return {
        type: actionTypes.LOADING,
        payload: payload
    }
}

export const setSuccess = (payload) => {
    return {
        type: actionTypes.SUCCESS,
        payload: payload
    }
}

export const setError = (payload) => {
    return {
        type: actionTypes.ERROR,
        payload: payload
    }
}

export const setLocalLoader = (payload) => {
    return {
        type: actionTypes.LOCAL_LOADER,
        payload: payload
    }
}