import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Card, CardBody, Col, ListGroup, ListGroupItem, Row } from 'reactstrap';
import LeaderBoardCard from './cards/LeaderBoardCard';

const LeaderBoard = ({ dataOptions }) => {
    const dataPoints = {};
    dataOptions.forEach(val => {
        dataPoints[val.value] = val.label;
    });
    const summary = useSelector(state => state.summary);
    const [sortBy, setSortBy] = useState(Object.keys(dataPoints)[0]);
    const [sortByLabel, setSortByLabel] = useState(dataPoints[sortBy]);
    const [ranking, setRanking] = useState([]);
    useEffect(() => {
        if (summary.current && summary.current.length) {
            setSortByLabel(dataPoints[sortBy]);
            try {
                var _current = summary.current.slice();
                var _previous = summary.previous.slice();
                _current = _current.map((val) => {
                    return { ...val, previous: (_previous.filter((a) => a._id == val._id))[0] };
                });
                var ranked = _current.sort((a, b) => b[sortBy] - a[sortBy]);
                setRanking(ranked);
            } catch (e) {
                // console.log(e)
            }
        }
    }, [summary, sortBy])
    return (
        <Fragment>
            <Card>
                <CardBody>
                    <Row>
                        <Col md={2} className='border-right-1'>
                            <h4 className='filter-heading'>Sort By</h4>
                            <ListGroup flush>
                                {
                                    Object.keys(dataPoints).map((key, index) => {
                                        return (
                                            <ListGroupItem tag="li" onClick={() => setSortBy(key)} key={"selected-data-point-" + index} className={'w-100 data-point-card pointer ' + (sortBy == key ? 'active' : '')}>
                                                {dataPoints[key]}
                                            </ListGroupItem>
                                        )
                                    })
                                }
                            </ListGroup>
                        </Col>
                        <Col>
                            <h4 className='filter-heading'>Leaderboard</h4>
                            <Row>
                                {
                                    ranking.length > 0 &&
                                    ranking.map((row, index) => {
                                        return (
                                            <Col className='mb-3' md={4} key={"leaderboard-rank-" + index}>
                                                <LeaderBoardCard data={row} sortBy={sortBy} position={index + 1} sortByLabel={sortByLabel} />
                                            </Col>
                                        )
                                    })
                                }
                            </Row>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </Fragment>
    )
}

export default LeaderBoard;