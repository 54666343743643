import React, { Fragment, useEffect, useState } from 'react'
import ReactECharts from 'echarts-for-react';
import * as echarts from 'echarts'

export default function DoubleAnuallyData({ seriesData, max, min, range1,range2 ,heatmapView,seriesData2}) {
    const [options, setOption] = useState()
    
    useEffect(() => {

       let option = {
            tooltip: {
              position: 'top',
              formatter: function (p) {
                var format = echarts.format.formatTime('yyyy-MM-dd', p.data[0]);
                return format + ': ' + p.data[1];
              }
            },
            visualMap: {
                min: min ,
                max: max ,
              calculable: true,
              orient: 'vertical',
              left: '470',
              top: 'center'
            },
            calendar: [
              {
                orient: 'vertical',
                range: range1,
                cellSize: [20, 'auto'],

              },
              {
                left: 300,
                orient: 'vertical',
                range: range2,
                cellSize: [20, 'auto'],

              },
            
            ],
            series: [
              {
                type: 'heatmap',
                coordinateSystem: 'calendar',
                calendarIndex: 0,
                data: seriesData
              },
              {
                type: 'heatmap',
                coordinateSystem: 'calendar',
                calendarIndex: 1,
                data: seriesData2
              },
            ]
          };
        
        setOption(option)

    }, [seriesData, max, min])

    return (
        <Fragment>
            {options != undefined &&
                <ReactECharts
                    option={options}
                    style={{ 
                      height: "calc(100vh - 140px)" 
                    
                    }}
                />
            }
        </Fragment>
    )
}
