import React, { useEffect, useRef, useState } from 'react'
import { Page, Text, View, Document, StyleSheet, Image, PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import { BarChart, Download, Layout, Star } from "react-feather";
import { CardBody, CardHeader, Col, Row, Card } from 'reactstrap';
import html2canvas from 'html2canvas';
import moment from "moment";
import { styles } from '../helpers/utils';
import { useSelector } from 'react-redux';

export default function ChatgptResponse(props) {
    const [imageData, setImageData] = useState();
    let body = document.getElementById("domEl");
    const chatgptQuery = useSelector((state) => state.summary.chatGptQuery)
    console.log('chatgptQuery',chatgptQuery);
    const domEl = useRef(null);
    const user = useSelector((state) => state.auth.user);

    useEffect(async () => {

        if (typeof body !== undefined && body) {
            setTimeout(async () => {
                const canvas = await html2canvas(body);
                const dataURI = canvas.toDataURL('image/png');
                let array = dataURI.split(",")
                setImageData(dataURI);
            }, 100);
        }


    }, [props.response])

    const Quixote = () => {

        return (
            <Document>

                <Page style={styles.body} wrap={false}>
                    <View style={[styles.descriptionPage, { position: "absolute", right: "3%", left: "86%", top: `5%`, width: "100px", paddingLeft: "3px", backgroundColor: "", height: "20px" }]}
                    >
                        <Image
                            style={{ width: "100%" }}
                            src={user.client_id[0].logo}
                        >

                        </Image>
                    </View>
                    <Text style={[styles.header, { marginTop: "25px", fontWeight: "bold" }]} fixed>
                        Insights Report
                    </Text>
                    {<Text
                        style={{ fontSize: "10px", marginTop: "15px", marginLeft: "2px", fontWeight: 400, fontFamily: "Helvetica", letterSpacing: "", color: "#999999", }}           >
                        {chatgptQuery}
                        <br></br>
                    </Text>}

                    <View style={{ width: "100%", marginTop: "10px" }}>

                        {

                            typeof imageData !== undefined && imageData &&
                            <Image
                                style={{ width: "100%" }}
                                src={imageData}
                            >

                            </Image>
                        }

                    </View>

                    <Text style={{ fontSize: "10px", marginTop: "35px", marginLeft: "2px", fontWeight: 400, fontFamily: "Helvetica", letterSpacing: "", color: "#999999", }}>
                        Generated at {moment(new Date()).format("DD MMM YYYY   hh:mmA")}
                    </Text>
                </Page>
            </Document>

        )
    }

    return (
        <>



            <Card>
                <CardHeader className=''>
                    <Row>
                        <Col >
                            <h4 className="m-0 p-2" style={{ fontSize: '14px' }}>Insight Report</h4>

                        </Col>
                        <Col className="text-end">
                            <PDFDownloadLink document={<Quixote />} fileName={`${'Report'}.pdf`} className='btn btn-primary'>
                                {({ blob, url, loading, error }) =>
                                    loading ? 'loading...!' : <Download size={16} />
                                }
                            </PDFDownloadLink>
                        </Col>
                    </Row>



                </CardHeader>
                <CardBody>
                    <div id="domEl" ref={domEl}>{props.response}</div>

                </CardBody>
            </Card>



        </>
    )
}
