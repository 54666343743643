import React, { Fragment, useEffect, useState } from 'react';
import ReactECharts from 'echarts-for-react';
import echarts from 'echarts/lib/echarts';
import 'echarts/lib/chart/bar';
import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/legend';
import { adlyticFormatter } from '../../helpers/utils';

const getOption = ({ legends, labels, data }) => {
    console.log('data in there', data);


    let _visualObject = data.map((item, index) => {

        let pieces = [];

        item.data.forEach((dataPiece, dataIndex) => {
            if ('impt' in dataPiece && dataPiece.impt) {

                let piece = {
                    gt: dataIndex - 1,
                    lte: dataIndex,
                    color: 'red'
                }
                pieces.push(piece)



            }
            else {
                let piece = {
                    gt: dataIndex - 1,
                    lte: dataIndex,
                    color: dataPiece.itemStyle.color
                }
                pieces.push(piece)


            }


        })



        let obj = {
            seriesIndex: index,
            show: false,
            dimension: 0,
            pieces: pieces
        }

        return obj
    })



    return {
        title: {
            text: ''
        },
        tooltip: {
            trigger: 'axis',
            confine: true

        },
        toolbox: {
            show: true,
            feature: {
                saveAsImage: { show: true }
            }
        },
        legend: {
            data: data.map((item) => { return item.name })
        },
        grid: {
            left: '1%',
            right: '2%',
            bottom: '3%',
            containLabel: true
        },
        xAxis: {
            type: 'category',
            boundaryGap: true,
            data: labels,
            axisLine: { onZero: false },
            axisTick: { alignWithLabel: true, interval: 'auto' },

            // axisLabel : {
            //     margin : -5
            // }
        },
        yAxis: {
            type: 'value',
            axisLabel: {
                margin: 60,
            }
        },
        visualMap: _visualObject,
        series: data


    };
}
const LineCore2 = (props) => {


    const [options, setOptions] = useState(null);
    const onChartReady = (echarts) => {
        echarts.hideLoading();
    }

    useEffect(() => {
        setOptions(getOption({ legends: props.legend, labels: props.labels, data: props.data }));
    }, [props])

    return (
        <Fragment>
            {options != undefined && options !== null && options &&
                <ReactECharts
                    echarts={echarts}
                    option={options}
                    style={{ height: 300 }}
                    onChartReady={onChartReady}
                    notMerge={true}

                />
            }
        </Fragment>
    )
}

export default LineCore2;