import { actionTypes } from "../constants/action-types";
import { adlyticFormatter, graphColors } from '../../helpers/utils';
import moment from 'moment';
import { storage } from "../../helpers/common";

const tempSeries = (series = [], color = graphColors.primary, title = "", type = "bar", smooth = false) => {
    return {
        title: "Title_" + title,
        label: "Label_" + title,
        name: title,
        type: type,
        color: color,
        data: series,
        lineStyle: { color: color, width: 4 },
        symbol: 'circle',
        symbolSize: 5,
        smooth: smooth,
        hoverAnimation: true,
        barMaxWidth: 20,
    }
}

export const lineSeries = (series = [], color = graphColors.primary, title = "", type = "line", smooth = false, areaStyle = null) => {
    return {
        title: "Title_" + title,
        label: "Label_" + title,
        name: title,
        type: type,
        color: color,
        data: series,
        lineStyle: { color: color, width: 2 },
        symbol: 'circle',
        symbolSize: 5,
        smooth: smooth,
        hoverAnimation: true,
        barMaxWidth: 20,
        areaStyle: areaStyle

    }
}

const getLineLabels = (startDate, endDate) => {
    var labels = [];
    let start = moment(startDate);
    let end = moment(endDate);
    if (end.diff(start, "day") < 1) {
        while (end.diff(start, "hour") > 0) {
            labels.push(start.format("hh:mm a"));
            start = start.add(1, "hour");
        }
    } else {
        while (end.diff(start, "day") >= 0) {
            labels.push(start.format("MMM D"));
            start = start.add(1, "day");
        }
    }
    return labels;
}


const compileData = (rawdata, entity, title) => {
    var data = [];
    let index = 0;
    if ('data' in rawdata && 'requestData' in rawdata) {

        let start = moment(rawdata.requestData.start);
        let end = moment(rawdata.requestData.end)

        if (rawdata.data.length > 0) {
            rawdata.data.forEach((val, index) => {
                if (entity === "avg_dt") {
                    data.push(parseFloat(val[entity] / 60).toFixed(1));
                } else {
                    data.push(Math.round(val[entity]));
                }
            });
        }
        else {

            if (end.diff(start, "day") < 1) {
                while (end.diff(start, "hour") > 0) {
                    if (rawdata.data.length && index < rawdata.data.length) {
                        if (start.format("HH") === rawdata.data[index]._id) {
                            if (entity === "avg_dt") {
                                data.push(parseFloat(rawdata.data[index][entity] / 60).toFixed(1));
                            }
                            else {
                                data.push(parseFloat(rawdata.data[index][entity]));
                            }
                            index++;
                        } else {
                            data.push(0);
                        }
                    } else {
                        data.push(0);
                    }
                    start = start.add(1, "hour");
                }
            } else {
                rawdata.data = rawdata.data.sort((a, b) => { return new Date(a._id) - new Date(b._id) });
                while (end.diff(start, "day") >= 0) {
                    if (rawdata.data.length && index < rawdata.data.length) {
                        if (start.format("YYYY-MM-DD") === rawdata.data[index]._id) {
                            if (entity === "avg_dt") {
                                data.push(parseFloat(rawdata.data[index][entity] / 60).toFixed(1));
                            } else {
                                data.push(parseFloat(rawdata.data[index][entity]));

                            }

                            index++;
                        } else {
                            data.push(0);
                        }
                    } else {
                        data.push(0);
                    }
                    start = start.add(1, "day");
                }
            }
        }

        return tempSeries(data, ["footfall"].indexOf(entity) >= 0 ? graphColors.primary : graphColors.secondary, title);
    }

}

export const compileLineData = (startDate, endDate, rawdata, entity, title, type) => {
    var data = [];
    var index = 0;
    let start = moment(startDate);
    let end = moment(endDate);
    if (end.diff(start, "day") < 1) {
        rawdata = rawdata.sort((a, b) => { return a._id - b._id });
        while (end.diff(start, "hour") > 0) {
            if (rawdata.length && index < rawdata.length) {

                let _hour = (start.format("HH"));
                let dataOfHOur = rawdata.filter((item) => { return item._id == _hour })[0];
                console.log('_hour===', typeof _hour, _hour, 'dataOfHOur==', typeof dataOfHOur?._id, dataOfHOur?._id);

                if (typeof dataOfHOur !== undefined && dataOfHOur) {
                    data.push(parseFloat(dataOfHOur[entity] / 60).toFixed(1));

                }
                else {
                    data.push(0);

                }


                // if (start.format("HH") === rawdata[index]._id) {
                //     console.log('start.format("HH")',start.format("HH"), rawdata[index]._id );
                //     data.push(parseFloat(rawdata[index][entity] / 60).toFixed(1));
                //     index++;
                // } else {
                //     data.push(0);
                // }
            } else {
                data.push(0);
            }
            start = start.add(1, "hour");
        }
    } else {
        rawdata = rawdata.sort((a, b) => { return new Date(a._id) - new Date(b._id) });
        while (end.diff(start, "day") >= 0) {
            if (rawdata.length && index < rawdata.length) {
                if (start.format("YYYY-MM-DD") === rawdata[index]._id) {
                    data.push(parseFloat(rawdata[index][entity] / 60).toFixed(1));
                    index++;
                } else {
                    data.push(0);
                }
            } else {
                data.push(0);
            }
            start = start.add(1, "day");
        }
    }
    return lineSeries(data, ["avg_dt"].indexOf(entity) >= 0 ? graphColors.primary : graphColors.secondary, title, type);
}

const initialState = {
    labels: getLineLabels(new Date(), new Date()),
    graphLabels: [],
    data: compileData([]),
    dataEntry: compileData([]),
    graphLabelsEntry: [],

    employeSalesUnits: [],
    slotCounts: [],
    salesItem: [],
    totalTraffic: [],
    totalTrafficEntry: [],
    graph: null,
    startDate: moment(new Date()).startOf("day"),
    endDate: moment(new Date()).endOf("day"),
    fid: null,
    sid: null,
    filter: {
        startDate: moment(new Date()).startOf("day"),
        endDate: moment(new Date()).endOf("day"),
        fid: null,
    },
    loader: false,
    customerJourney: []
}

export const categoryReducer = (state = initialState, { type, payload = {} }) => {
    switch (type) {
        case actionTypes.SET_SALE_ITEM:
            return { ...state, salesItem: payload }
        case actionTypes.SET_CATEGORY:
            var sid = state.sid;
            var fid = state.fid;
            var user = storage.get("user")
            var store;
            user.access.forEach((val) => {
                if (val._id === sid) {
                    store = val;
                }
            });
            var floor;
            store.floors.forEach((val) => {
                if (val._id === fid) {
                    floor = val;
                }
            });


            var fids = [];
            var flabels = [];
            floor.sections.forEach(val => {
                fids.push(val._id);
                flabels.push(val.label);
            });

            let sectionWithTraffic = [];
            payload.data.filter((pay) => {
                floor.sections.find(sec => {
                    if (pay._id === sec._id) {
                        sectionWithTraffic.push({
                            ...pay, label: sec.label
                        })
                    }
                })
            })

            var _payload = [];
            var _graphLabels = [];
            payload.data = payload.data.sort((a, b) => a.footfall - b.footfall);
            payload.data.forEach(val => {
                if (fids.indexOf(val._id) >= 0) {
                    _graphLabels.push(flabels[fids.indexOf(val._id)]);
                    _payload.push(val);
                }
            });
            console.log('flabels', flabels);
            console.log('_graphLabels', _graphLabels);
            console.log('payload.data', payload.data);
            payload.data = _payload.map((val, ind) => {
                return { ...val, avg_dt: Math.ceil(val.avg_dt), footfall: Math.ceil(val.footfall) }
            });
            var data = [compileData(payload, "footfall", "Visitors"), compileData(payload, "avg_dt", "ADT (m)")];

            return { ...state, data: data, graphLabels: _graphLabels, totalTraffic: sectionWithTraffic, };

        // case actionTypes.SET_CATEGORY:
        //     console.log('entry reducer is running');

        //     var sid = state.sid;
        //     var fid = state.fid;
        //     var user = storage.get("user")

        //     var store;
        //     user.access.some(val => {
        //         if (val._id === sid) {
        //             store = val;
        //             return true;
        //         }
        //         return false;
        //     });

        //     let floor;
        //     store.floors.some(val => {
        //         if (val._id === fid) {
        //             floor = val;
        //             return true;
        //         }
        //         return false;
        //     });

        //     const allSectionsOne = floor.entrySections.map(val => ({ label: val.label, _id: val.linked_section }));

        //     const dataObjectOne = payload.data.reduce((acc, item) => {
        //         acc[item._id] = item;
        //         return acc;
        //     }, {});

        //     const allPayloadOne = allSectionsOne.map(section => {
        //         const itemFoundOne = dataObjectOne[section._id];

        //         if (itemFoundOne) {
        //             return {
        //                 ...itemFoundOne,
        //                 ...section,
        //                 avg_dt: Math.ceil(itemFoundOne.avg_dt),
        //                 footfall: Math.ceil(itemFoundOne.footfall),
        //             };
        //         } else {
        //             return { ...section, footfall: 0, avg_dt: 0 };
        //         }
        //     });

        //     const _graphLabels = allPayloadOne.map(val => val.label);

        //     payload.data = allPayloadOne.sort((a, b) => a.footfall - b.footfall);

        //     const _dataEntryOne = [
        //         compileData(payload, "footfall", "Visitors"),
        //         compileData(payload, "avg_dt", "ADT (m)"),
        //     ];

        //     return { ...state, data: _dataEntryOne, graphLabels: _graphLabels, totalTrafficEntry: allSectionsOne };

        case actionTypes.SET_CATEGORY_ENTRY:
            console.log('entry reducer is running');

            var sidEntry = state.sid;
            var fidEntry = state.fid;
            var userEntry = storage.get("user")

            var storeEntry;
            userEntry.access.some(val => {
                if (val._id === sidEntry) {
                    storeEntry = val;
                    return true;
                }
                return false;
            });

            let floorEntry;
            storeEntry.floors.some(val => {
                if (val._id === fidEntry) {
                    floorEntry = val;
                    return true;
                }
                return false;
            });

            const allSections = floorEntry.entrySections.map(val => ({ label: val.label, _id: val.linked_section }));

            const dataObject = payload.data.reduce((acc, item) => {
                acc[item._id] = item;
                return acc;
            }, {});

            const allPayload = allSections.map(section => {
                const itemFound = dataObject[section._id];

                if (itemFound) {
                    return {
                        ...itemFound,
                        ...section,
                        avg_dt: Math.ceil(itemFound.avg_dt),
                        footfall: Math.ceil(itemFound.footfall),
                    };
                } else {
                    return { ...section, footfall: 0, avg_dt: 0 };
                }
            });

            const _graphLabelsEntry = allPayload.map(val => val.label);

            payload.data = allPayload.sort((a, b) => a.footfall - b.footfall);

            const _dataEntry = [
                compileData(payload, "footfall", "Visitors"),
                compileData(payload, "avg_dt", "ADT (m)"),
            ];

            return { ...state, dataEntry: _dataEntry, graphLabelsEntry: _graphLabelsEntry, totalTrafficEntry: allSections };

        case actionTypes.SET_CATEGORY_GRAPH:
            payload = payload.map((val, ind) => {
                return { ...val, avg_dt: Math.ceil(val.avg_dt) }
            });
            console.log('payloadpayloadpayload', payload);
            payload = payload.sort((a, b) => a.footfall - b.footfall);
            var temp = {
                labels: getLineLabels(state.startDate, state.endDate),
                graph: compileLineData(state.startDate, state.endDate, payload, "avg_dt", "ADT (m)")
            }
            console.log('temptemp', temp);
            return { ...state, graph: temp.graph, labels: temp.labels };
        case actionTypes.SET_CATEGORY_FILTER:
            return { ...state, startDate: payload._startDate, endDate: payload._endDate, fid: payload._fid, sid: payload._sid };
        case actionTypes.SET_CATEGORY_LOADER:
            return { ...state, loader: payload };
        case actionTypes.SET_EMPLOYEE_SALE_UNITS:
            return { ...state, employeSalesUnits: payload }
        case actionTypes.SET_JOURNEY:
            return { ...state, customerJourney: payload }
        case actionTypes.SLOT_COUNTS:
            return { ...state, slotCounts: payload }
        default:
            return state;
    }
}