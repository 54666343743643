import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { store } from './redux/store';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.css';
import { storage } from './helpers/common';
import TimeAgo from 'javascript-time-ago'

import en from 'javascript-time-ago/locale/en'
import ru from 'javascript-time-ago/locale/ru'

TimeAgo.addDefaultLocale(en)
TimeAgo.addLocale(ru)

// Global error handler
window.onerror = function (message, source, lineno, colno, error) {
  // Check if the error message contains "Malformed UTF-8 data"
  if (message && message.includes("Malformed UTF-8 data")) {
    // Clear local storage and refresh the page
    console.log('going to logout', message);
    storage.clear();
    window.location.reload();
  }
};


ReactDOM.render(

  <Provider store={store}>
    <App />
  </Provider>

  , document.getElementById('root')
);

serviceWorker.unregister();
