import React, { Fragment } from 'react';

const Settings = () => {
    const tag = "SETTINGS";
    return (
        <Fragment>
            {tag}
        </Fragment>
    )
}

export default Settings;