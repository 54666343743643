import React, { Fragment, useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import './App.css';
import Authenticate from './components/Authenticate';
import Branches from './pages/Branches';
import Categories from './pages/Categories';
import Comparison from './pages/Comparison';
import Heatmap from './pages/Heatmaps';
import Home from './pages/Home';
import Insights from './pages/Insights';
import Layout from './pages/Layout';
import Login from './pages/Login';
import NoMatch from './pages/NoMatch';
import Sales from './pages/Sales';
import Settings from './pages/Settings';
import ResetPassowrd from './pages/Templates/ResetPassowrd';
import Users from './pages/Users';
import Visitors from './pages/Visitors';
import Conversion from './pages/conversions/Conversion';
import EmployeActivity from './pages/EmployeActivity';
import Reporting from './pages/conversions/Reporting';
import { updateToken } from "./helpers/requests";
import { useDispatch, useSelector } from 'react-redux';
import Journey from './pages/Journey';
import Profile from './pages/Profile';
import ProfileMenu from './components/ProfileMenu';
import UsersActivity from './pages/Users/UsersActivity';
import UserHistory from './pages/Users/UserHistory';
import { enums } from './helpers/common';
import NewTraffic from './pages/NewTraffic';
import NewHome from './pages/new-pages/NewHome';
import HeatmapSummary from './pages/HeatmapSummary';
import Errors from './components/errors/Errors';
import Error404 from './components/errors/Error404';
import Storeactivity from './pages/Storeactivity';
import Storehistory from './pages/Storehistory';
import Home_chatgpt from './pages/Home-chatgpt';
import Slotsetting from './pages/Slotsetting';
import SlotsTraffic from './pages/SlotsTraffic';
import Alarm from './pages/Alarm';
import SlotsTrafficNew from './pages/SlotTrafficNew';
import Gallery from './pages/Gallery';
import Visitors2 from './pages/Visitors2';
import InStoreTraffic from './pages/InStoreTraffic';
import Clients_Activities from './pages/Client_Activities';



function App() {
  const dispatch = useDispatch();
  const user = useSelector(state => state.auth.user);
  const [menu, setMenu] = useState();





  useEffect(() => {
    if (user !== undefined && user) {
      let _menu = {};

      if ("client_id" in user) {

        user.client_id[0].menu.forEach((item) => {
          if (item.children.length > 0) {

            item.children.forEach((child) => {
              _menu[child.to] = true;
            })

          }
          else {
            _menu[item.to] = true;
          }
        })
      }
      setMenu(_menu)
    }

  }, [user])





  useEffect(() => {
    if (performance.navigation.type === 1) {
      if (user.token) {
        updateToken({ dispatch });
      }
      console.log("update jwt token");
    }
  }, []);

  return (
    <Fragment>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path={'/reset-password/:token'} element={<ResetPassowrd />} />
          <Route path="/" element={<Authenticate><Layout /></Authenticate>}>
            <Route index element={<Home />} />
            <Route path={'/sales'} element={<Sales />} />
            <Route path={'/settings'} element={<Settings />} />
            <Route path={'/users'} element={<Users />} />
            <Route path={'/branches'} element={<Branches />} />
            <Route path={'/conversions/:id/:title'} element={<Conversion />} />
            <Route path='/new-traffic' element={<NewTraffic />} />
            <Route path='/new-summary' element={<NewHome />} />
            <Route path={'/heatmap-summary'} element={<HeatmapSummary />} />
            <Route path={'/store-activity'} element={<Storeactivity/>} />
            <Route path={'/slot-setting'} element={<Slotsetting/>} />
            <Route path={'/store-history/:id'} element={<Storehistory/>} />
            <Route path={'/table-search'} element={<Home_chatgpt/>} />
            <Route path={'/slot-traffic'} element={<SlotsTraffic />} />
            <Route path={'/slot-traffic-new'} element={<SlotsTrafficNew />} />
            <Route path={'/gallery'} element={<Gallery />} />
            <Route path={'/traffic-im'} index element={<Visitors2 />} />
            <Route path={'/instore-traffic'} element={<InStoreTraffic />} />
            {/* <Route path='clients-activities' element={<Clients_Activities />} /> */}



          



            {
              // (user.role == enums.ROLE_ADMIN || user.role == enums.ROLE_SUPER_ADMIN) &&
              <>
                <Route path="/users-activity" element={<UsersActivity />}></Route>
                <Route path="/user-history/:id" element={<UserHistory />}></Route>
              </>

            }

            {/* add dynamic route here with condition by its route  */}

            {menu !== undefined && menu && '/traffic' in menu && <Route path={'/traffic'} element={<Visitors />} />}
            {menu !== undefined && menu && '/insights' in menu && <Route path={'/insights'} element={<Insights />} />}
            {menu !== undefined && menu && '/reporting' in menu && <Route path={'/reporting'} element={<Conversion />} />}
            {menu !== undefined && menu && '/comparison' in menu && <Route path={'/comparison'} element={<Comparison />} />}
            {menu !== undefined && menu && '/categories' in menu && <Route path={'/categories'} element={<Categories />} />}
            {menu !== undefined && menu && '/journey' in menu && <Route path={'/journey'} element={<Journey />} />}
            {menu !== undefined && menu && '/heatmap' in menu && <Route path={'/heatmap'} element={<Heatmap />} />}
            {menu !== undefined && menu && '/heatmap-summary' in menu && <Route path={'/heatmap-summary'} element={<HeatmapSummary />} />}

            {menu !== undefined && menu && '/employe-activity' in menu && <Route path={'/employe-activity'} element={<EmployeActivity />} />}


          </Route>

          <Route path="*" element={<Error404 />} />
        </Routes>
      </Router>
    </Fragment>
  );
}

export default App;