import React, { Fragment, useState, useEffect } from "react";
import MainFilter from "../components/filters/MainFilter";
import { Card, CardBody, Row, Col, Progress, Table, CardHeader, UncontrolledTooltip, Button, ButtonGroup, Input } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { categoryRequest, categoryGraphRequest, singleFloorFetch, employeSalesUnitRequest, getSaleItemRequest, getSlotTrafficRequest, categoryRequestTwo, getSlotTrafficNewRequest, dwelltimeMergeRequest, saveMergeDTRequest, deleteDTReqeust, addDwellTimeRequest } from "../helpers/requests";
import Sections from "./Templates/Sections";
import { setCategoryLoader, setCategoryFilter } from "../redux/actions/categoryActions";
import LineGraph from "../components/charts/LineGraph";
import SectionWithScale from "./Templates/SectionWithScale";
import '../App.css'
import '../pages/css/categories.css'
import NewMainFiltrer from "../components/filters/newMainFilter";
import PaginationTable from "../components/pagination-table/PaginationTable";
import Column from "antd/es/table/Column";
import { adlyticFormatter, globalTime } from "../helpers/utils";
import { BarChart, Layout } from "react-feather";
import SimpleBarGraph from "../components/charts/SimpleBarGraph";
import { Pagination, Space } from "antd";
import CustomPagination from "../components/pagination-table/CustomPagination";
import moment from "moment";
import DwelltimeAdd from "../components/modals/DwelltimeAdd";


const SlotsTrafficNew = () => {
    const [filters, setFilters] = useState();

    const dispatch = useDispatch();
    const labels = useSelector((state) => state.categoryReducer.labels);
    const graphLabels = useSelector((state) => state.categoryReducer.graphLabels);
    const floors = useSelector((state) => state.floorReducer.floors);
    const data = useSelector((state) => state.categoryReducer.data);
    const graph = useSelector((state) => state.categoryReducer.graph);
    const currentFloor = useSelector((state) => state.floorReducer.floor);
    const [sections, setSections] = useState([]);
    const [sectionSaleData, setSectionSaleData] = useState([]);
    const employeSalesUnits = useSelector((state) => state.categoryReducer.employeSalesUnits);
    const salesItem = useSelector((state) => state.categoryReducer.salesItem)
    const totalTraffic = useSelector((state) => state.categoryReducer.totalTraffic);
    const [saleItem, setSalesItem] = useState([])
    const [section, setSection] = useState();
    const [currentView, setCurrentView] = useState("table")
    const [selectedSection, setSelectedSection] = useState();
    const [slotsCount, setSlotsCount] = useState();
    console.log('slotsCount==', slotsCount);
    const [pgObject, setPGObject] = useState({ page: 1, limit: 10, total: 0 })
    const [mergeRecord, setMergeRecord] = useState({});
    const [addFlag, setAddFlag] = useState(false)

    console.log('mergeRecord', mergeRecord);
    var filter = useSelector((state) => state.categoryReducer.filter);
    var loader = useSelector((state) => state.categoryReducer.loader);

    const updateFilter = (e) => {
        console.log('eeeeeeeeeeee', e);
        setFilters(e);
        e["dispatch"] = dispatch;

        let slotRequest = JSON.parse(JSON.stringify(e));
        console.log('slotRequest', slotRequest);



        let requestdata = e;


        let floorRequestdata = {
            _id: e.fid,
            dispatch,
        };


        let _filter = {
            _startDate: e.start,
            _endDate: e.end,
            _fid: e.fid,
            _sid: e.sid,
        };


        let employeSaleObject = {
            sid: requestdata.sid,
            start: requestdata.start,
            end: requestdata.end,
            dispatch,
        };


        let saleItemObject = {
            sid: [requestdata.sid],
            start: requestdata.start,
            end: requestdata.end,
            dispatch,
        }



        // console.log('slotTrafficRequest',slotTrafficRequest);
        getSaleItemRequest(saleItemObject);
        employeSalesUnitRequest(employeSaleObject);
        dispatch(setCategoryFilter(_filter));
        singleFloorFetch(floorRequestdata);
        setTimeout(() => {
            let obj = {
                end: e.end,
                start: e.start,
                fid: e.fid,
                sid: e.sid,
                dispatch
            }
            categoryRequestTwo(obj);
            // categoryGraphRequest(requestdata);
        }, 200);
    };

    const getBarLabels = () => {

        if (graphLabels.length > 0) {
            return graphLabels;
        }
        else {
            return currentFloor?.sections.map((val, ind) => {
                return val.label;
            });
        }
    };

    const getSlot = async (obj) => {



        let slots = await getSlotTrafficNewRequest(obj);
        if (slots.status) {
            setSlotsCount(slots.data.docs);
            setPGObject({ page: slots.data.page, limit: slots.data.limit, total: slots.data.total })
            setMergeRecord({})


        }
        else {
            setSlotsCount([]);
            setPGObject({ page: slots.data.page, limit: slots.data.limit, total: slots.data.total })
            setMergeRecord({})


        }



    }

    const onPageChange = (page) => {

        console.log('page is', page);
        let object = filters;
        object["sec_id"] = section?._id;

        let obj = {
            body: object,
            params: { ...pgObject, page: page },
            dispatch
        }

        getSlot(obj)

    }

    useEffect(() => {
        if (currentFloor) {
            setSection(currentFloor?.sections[0]);
            setSelectedSection(currentFloor?.sections[0]);
        }
    }, [currentFloor]);

    useEffect(async () => {

        if (section && typeof filters !== undefined && filters) {
            let obj = filters;
            if (typeof obj === "object") {
                obj["sec_id"] = section?._id;
                categoryGraphRequest(obj);

                let object = {
                    body: obj,
                    params: { page: 1, limit: 10, total: 1 },
                    dispatch
                }
                getSlot(object);


            }
        }
    }, [section, filters]);


    useEffect(() => {
        if (floors) {
            let sections = [];
            floors.map((item) => {
                item.sections.map((section) => {
                    sections.push({
                        value: section._id,
                        label: section.label,
                    });
                });
            });
            setSections(sections);
        }
    }, [floors]);

    useEffect(() => {
        if (employeSalesUnits.length && totalTraffic.length) {
            let salesTrafficData = [];
            employeSalesUnits.filter((sales) => {
                return totalTraffic.find((traffic) => {
                    if (traffic._id === sales.sec_id) {
                        salesTrafficData.push({
                            ...sales,
                            traffic: traffic.footfall,
                            label: traffic.label,
                        });
                    }
                });
            });
            setSectionSaleData(salesTrafficData);
        }
    }, [totalTraffic, employeSalesUnits]);

    function getSelectedValueBack(data) {
        setSelectedSection(data);
    }

    useEffect(() => {
        if (salesItem) {
            let size = 6;
            let items = salesItem
            items = items.sort((a, b) => {
                return b.sale - a.sale;
            })
            setSalesItem(items);
        }

    }, [salesItem])


    const summaryMenu = [
        ["table", <Layout size={16} />, "Tabular View"],
        ["bar", <BarChart size={16} />, "Stacked Chart"],
    ];


    let tooltip;

    const addToMerge = (item) => {


        let _mergeRecord = { ...mergeRecord }

        if (item.checked) {
            _mergeRecord[item.record._id] = item.record;
        } else {
            delete _mergeRecord[item.record._id]
        }

        setMergeRecord(_mergeRecord)



    }

    const getMerge = async () => {
        let data = [];

        Object.keys(mergeRecord ? mergeRecord : {}).map((key) => {
            data.push(mergeRecord[key])
        });

        let payload = {
            data,
            dispatch
        }

        let response = await dwelltimeMergeRequest(payload);
        if (response.status) {

            let object = filters;
            object["sec_id"] = section?._id;

            let obj = {
                body: object,
                params: { ...pgObject },
                dispatch
            }

            getSlot(obj)

        }

    }

    const saveMergedData = async (e) => {


        let payload = {
            data: e,
            dispatch
        }

        await saveMergeDTRequest(payload)
        setMergeRecord({})


    }


    const deleteSlot = async () => {

        let data = [];

        Object.keys(mergeRecord ? mergeRecord : {}).map((key) => {
            data.push(key)
        });

        let payload = {
            data: { ids: data },
            dispatch
        }

        let response = await deleteDTReqeust(payload);
        setMergeRecord({});

        let object = filters;
        object["sec_id"] = section?._id;

        let obj = {
            body: object,
            params: { ...pgObject },
            dispatch
        }

        getSlot(obj)




    }


    const addDwelltime = async (data) => {
        console.log("data of dad", data);
        let obj = {
            A: -1,
            EMP: "-1",
            EN: "-1",
            EX: "-1",
            GD: "-1",
            cid: slotsCount.length > 0 && slotsCount[0]?.cid,
            fid: filters.fid,
            sid: filters.sid,
            sec: section?._id,
        }
        let _data = data.map((item, ind) => {

            const newDate = new Date(filters.start);
            newDate.setHours(item.hour);
            newDate.setMinutes(item.minutes);
            newDate.setSeconds(item.seconds);

            let d = moment(new Date(newDate)).add(Math.abs(10 + (item.dt * 60)), "seconds").format();
            const dateStringWithoutOffset = d.split('+')[0];

            // Define the target time zone

            // Parse the modified date string
            const modifiedDate = moment(dateStringWithoutOffset);

            // Format the modified date in the target time zone
            const formattedDate = modifiedDate.format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");





            let object = { ...obj, T: formattedDate, dt: item.dt * 60, tid : item.tid }
            return object
        })

        let ob = {
            data: _data,
            dispatch
        }

        let response = await addDwellTimeRequest(ob)

        let object = filters;
        object["sec_id"] = section?._id;

        let obje = {
            body: object,
            params: { ...pgObject },
            dispatch
        }

        getSlot(obje)

        console.log('all _datais', response);


    }


    return (
        <Fragment>
            <MainFilter
                pageTitle="Categories"
                updateFilter={(e) => updateFilter(e)}
                type="gold"
                floorToShow={true}

            ></MainFilter>


            <Row>
                <Col>
                    <h4 className="my-3">Sectionwise Traffic & Dwelltime</h4>
                </Col>
            </Row>

            <Row>
                <Col style={{ height: "20px" }}>
                    {loader && (
                        <Progress
                            animated
                            className="mb-2"
                            color="warning"
                            value="100"
                            style={{ height: "5px" }}
                        />
                    )}
                </Col>
            </Row>
            <Row className="mb-3">
                <Col md={6}>
                    <Card>
                        <CardBody>
                            {currentFloor && (
                                <SectionWithScale
                                    setSection={(section) => setSection(section)}
                                    dye={currentFloor ? currentFloor.dye_image : ""}
                                    map={currentFloor ? currentFloor.image : ""}
                                    sections={currentFloor.sections ? currentFloor.sections : []}
                                    section={section ? section : ""}
                                    selectedSection={getSelectedValueBack}
                                    heatmap={false}
                                    heatmapData={[]}
                                    sectionEditFlag={true}

                                />
                            )}
                        </CardBody>
                    </Card>


                </Col>
                <Col md={6}>

                    {filters && filters.sid != "6234c4d7115aa9163039ad5a" && (

                        <>
                            <Row className="mb-3">
                                <Col>
                                    {data && (
                                        <LineGraph
                                            seriesData={{
                                                data: data
                                                    ? data[0]
                                                    : new Array(getBarLabels().length).fill(0),
                                                labels: getBarLabels(),
                                            }}
                                            hideControls={true}
                                            title={"Visitors For All Sections"}
                                            space={40}
                                            rotation={45}
                                            height={"19rem"}
                                        />
                                    )}
                                </Col>
                            </Row>

                        </>
                    )}

                </Col>
            </Row>

            <Row className="mb-3">
                {typeof selectedSection !== undefined && selectedSection && slotsCount?.length > 0 && <Col md={6}>

                    <Card className="mt-2">
                        <CardHeader style={{ backgroundColor: 'none', display: 'flex' }} >

                            <h4 className="m-0 mt-2" style={{ fontSize: '14px' }}>{selectedSection.label}</h4>
                            <div style={{ marginLeft: 'auto' }}>
                                <Button className="btn btn-secondary" onClick={() => setAddFlag(true)} >Add</Button>
                                <Button disabled={!Object.keys(mergeRecord ? mergeRecord : {}).length} className="btn btn-danger mx-2" onClick={() => deleteSlot()}>Delete</Button>
                                <Button disabled={!Object.keys(mergeRecord ? mergeRecord : {}).length} className="btn btn-secondary" onClick={() => getMerge()} >Merge</Button>


                            </div >
                        </CardHeader>
                        <CardBody className="py-0">



                            <CustomPagination
                                columns={
                                    [<Column title="#" key="#" dataIndex="#" />,


                                    <Column title="Start" key="T" dataIndex="T" render={(_, record) => {

                                        const offset = new Date().getTimezoneOffset();
                                        const date = new Date(record.T);
                                        date.setSeconds(date.getSeconds() - 10 - record.dt);
                                        date.setMinutes(date.getMinutes() + offset);
                                        const formattedDate = date.toLocaleString("en-US", {
                                            month: "short",
                                            day: "numeric",
                                            year: "numeric",
                                            hour: "numeric",
                                            minute: "2-digit",
                                            second: "2-digit",
                                        });



                                        return (
                                            <Row>
                                                <Col>
                                                    <Space size="middle" >
                                                        {formattedDate}
                                                    </Space>
                                                </Col>
                                            </Row>
                                        )


                                    }} />,

                                    <Column title="End" key="end" dataIndex="end" render={(_, record) => {
                                        const offset = new Date().getTimezoneOffset();
                                        const date = new Date(record.T);
                                        date.setSeconds(date.getSeconds() - 10);
                                        date.setMinutes(date.getMinutes() + offset);
                                        const formattedDate = date.toLocaleString("en-US", {
                                            month: "short",
                                            day: "numeric",
                                            year: "numeric",
                                            hour: "numeric",
                                            minute: "2-digit",
                                            second: "2-digit",
                                        });

                                        return (
                                            <Row>
                                                <Col>
                                                    <Space size="middle" >
                                                        {formattedDate}
                                                    </Space>
                                                </Col>
                                            </Row>
                                        )
                                    }} />,
                                    <Column title="Dwell Time" key="dt" dataIndex="dt" render={(_, record) => {


                                        return (
                                            <Row>
                                                <Col>
                                                    <Space size="middle" >
                                                        {adlyticFormatter(record.dt)}
                                                    </Space>
                                                </Col>
                                            </Row>
                                        )
                                    }} />,
                                    <Column title="Action" key="#" dataIndex="#" render={(_, record) => {


                                        return (
                                            <Row>
                                                <Col>
                                                    <Space size="middle" >
                                                        <Input type="checkbox" name="" value={record} onChange={(e) => addToMerge({ checked: e.target.checked, record })} checked={record._id in mergeRecord} />
                                                    </Space>
                                                </Col>
                                            </Row>
                                        )
                                    }} />,
                                    ]}
                                data={(typeof slotsCount !== undefined && slotsCount && slotsCount?.length > 0) ? slotsCount : []}

                                loader={false}


                            />
                            <Row>
                                <Col md={8}>
                                    <Pagination
                                        current={pgObject.page}
                                        pageSize={pgObject.limit}
                                        total={pgObject.total}
                                        onChange={onPageChange}
                                        style={{ margin: '10px', textAlign: 'left', }}
                                        showSizeChanger={false}
                                    />
                                </Col>
                                <Col md={4} className="p-2" style={{ textAlign: 'right' }}>
                                    <Button className="btn btn-primary" onClick={() => saveMergedData(filters)} disabled={!filters}>
                                        Save changes
                                    </Button>
                                </Col>
                            </Row>
                            <div></div>












                        </CardBody>
                    </Card>







                </Col>}


            </Row>

            {addFlag &&
                <DwelltimeAdd modal={addFlag} setModal={(e) => setAddFlag(e)} addData={addDwelltime} />
            }

        </Fragment>
    );
};
export default SlotsTrafficNew;
