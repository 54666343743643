import React, { Fragment, useEffect, useState } from "react";
import {  Col, Row, } from "reactstrap";
import InsightQuestionCard from "../../components/cards/InsightQuestionCard";


export default function BasicInsightQuestion({ defaultQuestion, customQuestion, deleteCustom }) {
    const [indexToShowDeleteIcon, setIndexToShowDeleteIcon] = useState();

    return (
        <Fragment>
            {customQuestion !== undefined && customQuestion && customQuestion.length > 0 &&  <h5 className="p-0 mb-3 fw-normal">Your Queries</h5>}
            <Row>
                {customQuestion?.length > 0 && customQuestion.map((question, index) => {

                    return (
                        <>
                            <Col md={3} className="" key={"traffic-card-" + index}
                                onMouseEnter={() => setIndexToShowDeleteIcon(index)}
                                onMouseLeave={() => setIndexToShowDeleteIcon("")}
                            >
                                {question.answers?.length > 0 &&
                                    <>
                                        <InsightQuestionCard
                                            title={question.question.question}
                                            current={question.answers[0].value}
                                            previous={''}
                                            active={
                                                indexToShowDeleteIcon === index ? true : false
                                            }
                                            deleteFlag={
                                                indexToShowDeleteIcon === index ? true : false

                                            }
                                            deleteCustom={(item) => deleteCustom(item)}
                                            index_id={question.question._id}
                                        >
                                        </InsightQuestionCard>
                                    </>}
                            </Col>
                        </>
                    )

                })}
            </Row>
            <h5 className="p-0 mb-3 fw-normal">Prime Queries</h5>

            <Row className="mb-4 g-2">
                {defaultQuestion.length > 0 && defaultQuestion.map((question, index) => {
                    return (
                        <>
                            <Col md={3} className="" key={"traffic-card-" + index}>
                                {question.answers?.length > 0 &&
                                    <InsightQuestionCard
                                        title={question.question.question}
                                        current={question.answers[0].value}
                                        previous={''}
                                    >
                                    </InsightQuestionCard>}
                            </Col>
                        </>
                    )

                })}

            </Row>

        </Fragment>
    )
}
