import { Row, Col, Card, CardBody, ButtonGroup, Button, Progress, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { CustomInput, Form, FormGroup, Input, Label, } from 'reactstrap';
import React, { useState, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Flex, Select, Space } from 'antd';
import { getCameraByLinkedSection, updateSectionProducts } from '../../helpers/requests';
import { Crosshair, X } from 'react-feather';
import { setError, setSuccess } from '../../redux/actions/highlights';
import DeleteModal from './DeleteModal';
import { useRef } from 'react';
import { ImCross } from 'react-icons/im';



export default function Product(props) {

    console.log('props.section', props.section);
    const [modal, setModal] = useState(false);
    const [selectedProducts, setSelectedProducts] = useState([])
    console.log('selectedProducts', selectedProducts);
    const [deleteModal, setDeleteModal] = useState(false);
    const [deleteAllModal, setDeleteAllModal] = useState(false);
    const dropdownRef = useRef()
    const [currentProduct, setCurrentProduct] = useState()
    const dispatch = useDispatch();
    const [currentCam, setCurrentCam] = useState();

    const toggle = () => {
        setModal(!modal)
        props.setModal(!modal)
    }

    const deleteItem = () => {
        props.setModal(false)
        setModal(false)
        props.delete();

    }



    const addProducts = async () => {
        let _section = { ...props.section };
        _section.productCategories = [..._section.productCategories, ...selectedProducts];
        console.log('updated sections are', _section);
        let obj = {
            data: _section,
            dispatch
        }

        const response = await updateSectionProducts(obj);
        console.log('response of status', response);
        if (response.data.status) {
            props.updateSection(_section)
            dispatch(setSuccess('Product added Successfully'))
        }

        setSelectedProducts([])

    }

    const removeProduct = async (item) => {


        try {
            // Deep clone the section object to ensure immutability
            let _section = JSON.parse(JSON.stringify(props.section));

            // Remove the item from the productCategories array
            _section.productCategories = _section.productCategories.filter((sec) => sec !== item);

            // Dispatch an action to update the section data
            const response = await updateSectionProducts({ data: _section, dispatch });

            // Check the response status and dispatch appropriate messages
            if (response.data.status) {
                props.updateSection(_section); // Assuming updateSection is a prop function to update the state
                dispatch(setSuccess('Removed Successfully'));
            } else {
                dispatch(setError('Service is not available right now'));
            }
        } catch (error) {
            console.error('Error during product removal:', error);
            dispatch(setError('Service is not available right now'));
        }

    }

    const removeAllExisting = async () => {
        try {
            // Deep clone the section object to ensure immutability
            let _section = JSON.parse(JSON.stringify(props.section));

            // Remove the item from the productCategories array
            _section.productCategories = [];

            // Dispatch an action to update the section data
            const response = await updateSectionProducts({ data: _section, dispatch });

            // Check the response status and dispatch appropriate messages
            if (response.data.status) {
                props.updateSection(_section); // Assuming updateSection is a prop function to update the state
                dispatch(setSuccess('Removed All Successfully'));
            } else {
                dispatch(setError('Service is not available right now'));
            }
        } catch (error) {
            console.error('Error during product removal:', error);
            dispatch(setError('Service is not available right now'));
        }
    }

    useEffect(async () => {
        setModal(props.modal);
        if (typeof props.section !== undefined && props.section) {

            let obj = {
                linkedsectionid: props.section.linked_section,
                dispatch
            }
            const camera = await getCameraByLinkedSection(obj);
            if (camera.data.status && camera.data.data.length > 0) {
                let _currentCam = camera.data.data[0]
                setCurrentCam(_currentCam)
            }
        }
    }, [props])


    return <div>
        <Modal isOpen={modal} toggle={toggle} centered size='lg' backdrop="static">
            <ModalHeader style={{ height: "40px", paddingTop: "1.5rem" }} className="d-flex justify-content-between align-items-center">
                <div className="modal-header-title">
                    <h4 style={{ fontSize: "16px" }}>Manage Products of {props.section.label}</h4>
                </div>
                <div className="modal-header-close-button" role="button" onClick={toggle}>
                    <ImCross color="#00000030" aria-label="Close modal" />
                </div>
            </ModalHeader>
            {currentCam && <ModalBody className='p-0 m-0 ' style={{ height: "", position: 'relative', minHeight: "400px" }}  >
                <div className='camera-background  p-2 m-0' style={{ backgroundImage: `url(${currentCam?.screenshot})`, minHeight: "400px" }}>

                    <Row style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>

                        <Col md={10}>
                            <Space
                                direction="vertical"
                                style={{
                                    width: '100%',
                                }}
                            >
                                <Select
                                    showSearch
                                    style={{
                                        width: '100%',
                                        maxHeight: '100%'
                                    }}
                                    placeholder="Search"
                                    optionFilterProp="children"
                                    filterOption={(input, option) => (option?.label ?? '').includes(input)}
                                    filterSort={(optionA, optionB) =>
                                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                    }
                                    onChange={(selectedValues) => setSelectedProducts(selectedValues)}  // Updated this line
                                    mode="multiple"
                                    value={selectedProducts}
                                    maxTagCount='responsive'
                                    options={props.products?.filter((item) => !props.section.productCategories.includes(item))
                                        .map((pro) => ({
                                            label: pro,
                                            value: pro,
                                        }))
                                    }


                                />
                            </Space>
                        </Col>
                        <Col md={2} className='d-flex'>
                            <Button style={{ marginLeft: "auto" }} className='my-2 btn btn-primary pointer' onClick={addProducts} disabled={!selectedProducts.length}>Add</Button>

                        </Col>

                    </Row>

                    <Row>
                        <Col md={12} sm={12} >

                            <div style={{ height: "" }} >
                                <div className='' style={{ alignItems: "center", justifyContent: "center" }}>
                                    <h6 className=' p-2' style={{ color: "white" }}>Existing Product Categories</h6>
                                    {props.section.productCategories.length > 0 && <button onClick={() => setDeleteAllModal(true)} className='deleteButton mx-2 p-1' style={{ marginLeft: "auto", fontSize: "11px", borderRadius: "30px", background: "transparent", color: "white" }}>Remove All Existing Categories</button>}
                                </div>
                                {!props.section?.productCategories.length &&
                                    <span className="d-block small opacity-30 p-2 "><i>Product not added yet</i></span>

                                }
                                <Flex wrap="wrap" gap="small" className='p-2'>

                                    {
                                        [...props.section?.productCategories].reverse().map((item, i) => (
                                            <div key={i} type="primary" className='btn btn-secondary d-flex product-items'>
                                                {item}
                                                <X size={14} style={{ marginLeft: "5px" }} color='#d9d9d9' onClick={() => { setCurrentProduct(item); setDeleteModal(true) }} />
                                            </div>
                                        ),)}

                                </Flex>
                            </div>



                        </Col>

                    </Row>







                </div>


                {
                    typeof currentProduct !== undefined && currentProduct && deleteModal &&
                    <DeleteModal
                        modal={deleteModal}
                        setModal={(output) => setDeleteModal(output)}
                        delete={() => removeProduct(currentProduct)}
                    />
                }

                {
                    deleteAllModal &&
                    <DeleteModal
                        modal={deleteAllModal}
                        setModal={(output) => setDeleteAllModal(output)}
                        delete={() => removeAllExisting(currentProduct)}
                    />
                }





            </ModalBody>}
        </Modal>
    </div>;
}
