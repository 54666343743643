import {actionTypes} from '../constants/action-types'

export const listFilter = (payload) => {
    return {
        type: actionTypes.LIST_FILTER,
        payload: payload
    }
}
export const createFilter = (payload) => {
    return {
        type: actionTypes.CREATE_FILTER,
        payload: payload
    }
}
export const deleteFilter = (payload) => {
    return {
        type: actionTypes.DELETE_FILTER,
        payload: payload
    }
}