import React, { Fragment } from 'react'
import "../MainFilter.css"
import "../../../App.css"
import { getDateString, getDifference } from './FilterCommon'
import { ChevronLeft, ChevronRight, ChevronsLeft, ChevronsRight } from 'react-feather'
import { isGreaterThanToday } from '../../../helpers/utils'
import { useDispatch } from 'react-redux'
import { setError } from '../../../redux/actions/highlights'
import { useEffect } from 'react'
import { useState } from 'react'
import { Col, Row } from 'reactstrap'

export default function FilterDescription(
    {
        selectedDayRange,
        setSelectedDayRange,
        option,
        title,
        updateStoreNameURL,
        type,
        floorId,
        storeName,
        storeOneName,
        dateFilterFlag,
        dateSelection,
        dates,
        singleRangeDateFilters,
        dateSelectionTwo,
        storeTwoName,
        dateFilterFlagTwo,
        selectedDayRangeTwo,
        pageTitle,
        compareType,
        sidForInsights,
        floorToShow

    }) {

    console.log('typetype', type);

    const dispatch = useDispatch();
    const [currentType, setCurrentType] = useState(type)
    const [findlen, setFindlen] = useState()
    console.log('fafgagg', findlen);
    const decreaseIntervelDate = () => {

        let date = (new Date(selectedDayRange.to.year, selectedDayRange.to.month - 1, selectedDayRange.to.day));
        date.setDate(date.getDate() - 1);
        let newDate = {
            day: date.getDate(),
            month: date.getMonth() + 1,
            year: date.getFullYear(),
        }
        let _dateRange = {
            from: newDate,
            to: newDate
        }
        setSelectedDayRange(_dateRange)

    }

    const increaseIntervelDate = () => {

        let date = (new Date(selectedDayRange.to.year, selectedDayRange.to.month - 1, selectedDayRange.to.day));
        date.setDate(date.getDate() + 1);
        if (!isGreaterThanToday(date)) {
            let newDate = {
                day: date.getDate(),
                month: date.getMonth() + 1,
                year: date.getFullYear(),
            }
            let _dateRange = {
                from: newDate,
                to: newDate
            }
            setSelectedDayRange(_dateRange)
        }
        else {
            dispatch(setError('You cannot select date greater than today'))
        }

    }

    const decreaseDateRange = () => {
        let from = (new Date(selectedDayRange.from.year, selectedDayRange.from.month - 1, selectedDayRange.from.day));
        let to = (new Date(selectedDayRange.to.year, selectedDayRange.to.month - 1, selectedDayRange.to.day));
        let diff = getDifference(selectedDayRange.from, selectedDayRange.to);
        from.setDate(from.getDate() - diff - 1);
        to.setDate(to.getDate() - diff - 1);


        if (!isGreaterThanToday(from) && !isGreaterThanToday(to)) {
            let fromDate = {
                day: from.getDate(),
                month: from.getMonth() + 1,
                year: from.getFullYear(),
            }
            let toDate = {
                day: to.getDate(),
                month: to.getMonth() + 1,
                year: to.getFullYear(),
            }
            let _dateRange = {
                from: fromDate,
                to: toDate
            }
            setSelectedDayRange(_dateRange)
        }
        else {
            dispatch(setError('You cannot select date greater than today'))
        }

    }

    const decreaseStartDate = () => {
        let date = (new Date(selectedDayRange.from.year, selectedDayRange.from.month - 1, selectedDayRange.from.day));
        date.setDate(date.getDate() - 1);
        if (!isGreaterThanToday(date)) {
            let newDate = {
                day: date.getDate(),
                month: date.getMonth() + 1,
                year: date.getFullYear(),
            }
            let _dateRange = {
                from: newDate,
                to: selectedDayRange.to
            }
            setSelectedDayRange(_dateRange)
        }
        else {
            dispatch(setError('You cannot select date greater than today'))
        }

    }

    const increaseStartDate = () => {
        let date = (new Date(selectedDayRange.from.year, selectedDayRange.from.month - 1, selectedDayRange.from.day));
        date.setDate(date.getDate() + 1);
        if (!isGreaterThanToday(date)) {
            let newDate = {
                day: date.getDate(),
                month: date.getMonth() + 1,
                year: date.getFullYear(),
            }
            let _dateRange = {
                from: newDate,
                to: selectedDayRange.to
            }
            setSelectedDayRange(_dateRange)
        }
        else {
            dispatch(setError('You cannot select date greater than today'))
        }

    }

    const decreaseEndDate = () => {
        let date = (new Date(selectedDayRange.to.year, selectedDayRange.to.month - 1, selectedDayRange.to.day));
        date.setDate(date.getDate() - 1);
        if (!isGreaterThanToday(date)) {
            let newDate = {
                day: date.getDate(),
                month: date.getMonth() + 1,
                year: date.getFullYear(),
            }
            let _dateRange = {
                from: selectedDayRange.from,
                to: newDate
            }
            setSelectedDayRange(_dateRange)
        }
        else {
            dispatch(setError('You cannot select date greater than today'))
        }

    }

    const increaseEndDate = () => {
        let date = (new Date(selectedDayRange.to.year, selectedDayRange.to.month - 1, selectedDayRange.to.day));
        date.setDate(date.getDate() + 1);
        if (!isGreaterThanToday(date)) {
            let newDate = {
                day: date.getDate(),
                month: date.getMonth() + 1,
                year: date.getFullYear(),
            }
            let _dateRange = {
                from: selectedDayRange.from,
                to: newDate
            }
            setSelectedDayRange(_dateRange)
        }
        else {
            dispatch(setError('You cannot select date greater than today'))
        }

    }

    const increaseDateRange = () => {
        let from = (new Date(selectedDayRange.from.year, selectedDayRange.from.month - 1, selectedDayRange.from.day));
        let to = (new Date(selectedDayRange.to.year, selectedDayRange.to.month - 1, selectedDayRange.to.day));
        let diff = getDifference(selectedDayRange.from, selectedDayRange.to);

        from.setDate(from.getDate() + diff + 1);
        to.setDate(to.getDate() + diff + 1);


        if (!isGreaterThanToday(from) && !isGreaterThanToday(to)) {
            let fromDate = {
                day: from.getDate(),
                month: from.getMonth() + 1,
                year: from.getFullYear(),
            }
            let toDate = {
                day: to.getDate(),
                month: to.getMonth() + 1,
                year: to.getFullYear(),
            }
            let _dateRange = {
                from: fromDate,
                to: toDate
            }
            setSelectedDayRange(_dateRange)
        }
        else {
            dispatch(setError('You cannot select date greater than today'))
        }

    }

    useEffect(() => {
        if (type !== undefined && type) {
            setCurrentType(type)

        }

    }, [type])

    useEffect(() => {
        const len = sidForInsights !== undefined && Object.keys(sidForInsights).length
        setFindlen(len)
    }, [sidForInsights])


    console.log('currentType', currentType);
    return (
        <Fragment>
            <Row>
                <Col>
                    <div>
                        {
                            (currentType === "default" || currentType === "store_activity"
                                || currentType === "brown" || currentType === "silver" || currentType === "visitor"
                            ) &&
                            <>
                                {
                                    getDifference(selectedDayRange.from, selectedDayRange.to) == 0 ?

                                        <Row>
                                            <Col>
                                                <p className='filter-p-tag' >You have selected <strong className='filterSelected'>{option.length > 1 ? option.length : option[0]?.label}</strong> {option.length > 1 ? `${title ? '' : 'branches'} to view data from` : `${updateStoreNameURL ? '' : 'branch'} to view data from`}
                                                    <button title='Previous day' style={{ background: 'none', border: 'none', marginLeft: "", marginTop: "", position: '', width: '' }}>
                                                        <ChevronLeft className='chevronLeft' style={{ color: 'black', cursor: "pointer", marginLeft: "" }} onClick={() => decreaseIntervelDate()} />
                                                    </button>

                                                    <strong>{getDateString(selectedDayRange.from, "ll")}</strong>
                                                    <button title='Next day' style={{ background: 'none', border: 'none', marginLeft: "", marginTop: "", position: '' }} >
                                                        <ChevronRight className='chevronRight' style={{ color: 'black', cursor: "pointer", marginRight: "" }} onClick={() => increaseIntervelDate()} />
                                                    </button>
                                                </p>
                                            </Col>
                                        </Row>

                                        :
                                        <Row>
                                            <Col>
                                                <p className='filter-p-tag' >You have selected <strong>{option.length > 1 ? option.length : option[0]?.label}</strong> {option.length > 1 ? `${title ? '' : 'branches'} to view data from` : `${updateStoreNameURL ? '' : 'branch'} to view data from`}

                                                    <button title='Previous interval' style={{ background: 'none', border: 'none', marginLeft: "", marginTop: "", position: '' }} >
                                                        <ChevronsLeft className='chevronsLeft' style={{ color: 'black', cursor: "pointer", marginRight: "" }} onClick={() => decreaseDateRange()} />

                                                    </button>
                                                    <button title='Previous day' style={{ background: 'none', border: 'none', marginLeft: "", marginTop: "", position: '', width: '' }}>
                                                        <ChevronLeft className='chevronLeft' style={{ color: 'black', cursor: "pointer", marginLeft: "" }} onClick={() => decreaseStartDate()} />
                                                    </button>
                                                    <strong>{getDateString(selectedDayRange.from, "ll")}</strong>
                                                    <button title='Next day' style={{ background: 'none', border: 'none', marginLeft: "", marginTop: "", position: '' }} >
                                                        <ChevronRight className='chevronRight' style={{ color: 'black', cursor: "pointer", marginRight: "" }} onClick={() => increaseStartDate()} />

                                                    </button>
                                                    to
                                                    <button title='Previous day' style={{ background: 'none', border: 'none', marginLeft: "", marginTop: "", position: '', width: '' }}>
                                                        <ChevronLeft className='chevronLeft' style={{ color: 'black', cursor: "pointer", marginLeft: "" }} onClick={() => decreaseEndDate()} />
                                                    </button>                                                <strong>{getDateString(selectedDayRange.to, "ll")}</strong>
                                                    <button title='Next day' style={{ background: 'none', border: 'none', marginLeft: "", marginTop: "", position: '' }} >
                                                        <ChevronRight className='chevronRight' style={{ color: 'black', cursor: "pointer", marginRight: "" }} onClick={() => increaseEndDate()} />

                                                    </button>
                                                    <button title='Next Interval' style={{ background: 'none', border: 'none', marginLeft: "", marginTop: "", position: '' }} >
                                                        <ChevronsRight className='chevronsRight' style={{ color: 'black', cursor: "pointer", marginRight: "" }} onClick={() => increaseDateRange()} />

                                                    </button>
                                                </p>
                                            </Col>
                                        </Row>

                                }
                            </>
                        }
                        {
                            (currentType === "calendar") && <>
                                <div className=''>
                                    {
                                        getDifference(selectedDayRange.from, selectedDayRange.to) == 0 ?
                                            <p className='filter-p-tag' >You have selected <strong>{getDateString(selectedDayRange.from, "ll")}</strong>.</p>
                                            :
                                            <p className='filter-p-tag' >You have selected <strong>{getDateString(selectedDayRange.from, "ll")}</strong> to <strong>{getDateString(selectedDayRange.to, "ll")}</strong>.</p>
                                    }
                                </div>
                            </>
                        }

                        {/* {
                (currentType === "store_activity") &&
                <>
                    {
                        getDifference(selectedDayRange.from, selectedDayRange.to) == 0 ?
                            <p className='' style={{ padding: 0, margin: 0, lineHeight: "2rem", whiteSpace: 'nowrap' }}>You have selected <strong className='filterSelected'>{findlen}</strong> {'Stores'}
                                <button title='Previous day' style={{ background: 'none', border: 'none', marginLeft: "", marginTop: "", position: '', width: '' }}>
                                    <ChevronLeft className='chevronLeft' style={{ color: 'black', cursor: "pointer", marginLeft: "" }} onClick={() => decreaseIntervelDate()} />
                                </button>
                                <strong>{getDateString(selectedDayRange.from, "ll")}</strong>
                                <button title='Next day' style={{ background: 'none', border: 'none', marginLeft: "", marginTop: "", position: '' }} >
                                    <ChevronRight className='chevronRight' style={{ color: 'black', cursor: "pointer", marginRight: "" }} onClick={() => increaseIntervelDate()} />
                                </button>
                            </p>
                            :
                            <p className='' style={{ padding: 0, margin: 0, lineHeight: "2rem", whiteSpace: 'nowrap' }}>You have selected <strong className='filterSelected'>{findlen}</strong> {'Stores'}
                                <button title='Previous interval' style={{ background: 'none', border: 'none', marginLeft: "", marginTop: "", position: '' }} >
                                    <ChevronsLeft className='chevronsLeft' style={{ color: 'black', cursor: "pointer", marginRight: "" }} onClick={() => decreaseDateRange()} />
                                </button>
                                <button title='Previous day' style={{ background: 'none', border: 'none', marginLeft: "", marginTop: "", position: '', width: '' }}>
                                    <ChevronLeft className='chevronLeft' style={{ color: 'black', cursor: "pointer", marginLeft: "" }} onClick={() => decreaseStartDate()} />
                                </button>
                                <strong>{getDateString(selectedDayRange.from, "ll")}</strong>
                                <button title='Next day' style={{ background: 'none', border: 'none', marginLeft: "", marginTop: "", position: '' }} >
                                    <ChevronRight className='chevronRight' style={{ color: 'black', cursor: "pointer", marginRight: "" }} onClick={() => increaseStartDate()} />
                                </button>
                                to
                                <button title='Previous day' style={{ background: 'none', border: 'none', marginLeft: "", marginTop: "", position: '', width: '' }}>
                                    <ChevronLeft className='chevronLeft' style={{ color: 'black', cursor: "pointer", marginLeft: "" }} onClick={() => decreaseEndDate()} />
                                </button>                                                <strong>{getDateString(selectedDayRange.to, "ll")}</strong>
                                <button title='Next day' style={{ background: 'none', border: 'none', marginLeft: "", marginTop: "", position: '' }} >
                                    <ChevronRight className='chevronRight' style={{ color: 'black', cursor: "pointer", marginRight: "" }} onClick={() => increaseEndDate()} />

                                </button>
                                <button title='Next Interval' style={{ background: 'none', border: 'none', marginLeft: "", marginTop: "", position: '' }} >
                                    <ChevronsRight className='chevronsRight' style={{ color: 'black', cursor: "pointer", marginRight: "" }} onClick={() => increaseDateRange()} />
                                </button>
                            </p>
                    }
                </>
            } */}
                        {
                            (currentType === "gold" || currentType === 'gallery' ) &&
                            <>
                                <div className='text-end'>
                                    <div className='' style={{ overflowX: 'scroll', width: '100%' }} id='style-horizontal'>
                                        {
                                            getDifference(selectedDayRange.from, selectedDayRange.to) == 0 ?
                                                <p className='filter-p-tag' >You have selected
                                                    {option.length > 0 && floorToShow && typeof option[0].floors !== undefined && option[0].floors && option[0].floors.filter((item) => { return item.showInFilter }).length > 1 &&
                                                        <>
                                                            <strong className='mx-1'>{floorId?.name}
                                                            </strong>
                                                            of
                                                        </>
                                                    }
                                                    <strong className='mx-1'>{storeName}</strong>  to view data for
                                                    <button title='Previous day' style={{ background: 'none', border: 'none', marginLeft: "", marginTop: "", position: '', width: '' }}>
                                                        <ChevronLeft className='chevronLeft' style={{ color: 'black', cursor: "pointer", marginLeft: "" }} onClick={() => decreaseIntervelDate()} />
                                                    </button>
                                                    <strong>{getDateString(selectedDayRange.from, "ll")} </strong>
                                                    <button title='Next day' style={{ background: 'none', border: 'none', marginLeft: "", marginTop: "", position: '' }} >
                                                        <ChevronRight className='chevronRight' style={{ color: 'black', cursor: "pointer", marginRight: "" }} onClick={() => increaseIntervelDate()} />
                                                    </button>
                                                </p>

                                                :
                                                <p className='filter-p-tag' >You have selected
                                                    {option.length > 0 && floorToShow && typeof option[0].floors !== undefined && option[0].floors && option[0].floors.filter((item) => { return item.showInFilter }).length > 1 &&
                                                        <>
                                                            <strong className='mx-1'>{floorId?.name}
                                                            </strong>
                                                            of
                                                        </>

                                                    }
                                                    <strong className='mx-1'>{storeName}</strong>
                                                    to view data from

                                                    <button title='Previous interval' style={{ background: 'none', border: 'none', marginLeft: "", marginTop: "", position: '' }} >
                                                        <ChevronsLeft className='chevronsLeft' style={{ color: 'black', cursor: "pointer", marginRight: "" }} onClick={() => decreaseDateRange()} />

                                                    </button>
                                                    <button title='Previous day' style={{ background: 'none', border: 'none', marginLeft: "", marginTop: "", position: '', width: '' }}>
                                                        <ChevronLeft className='chevronLeft' style={{ color: 'black', cursor: "pointer", marginLeft: "" }} onClick={() => decreaseStartDate()} />
                                                    </button>
                                                    <strong>{getDateString(selectedDayRange.from, "ll")}</strong>
                                                    <button title='Next day' style={{ background: 'none', border: 'none', marginLeft: "", marginTop: "", position: '' }} >
                                                        <ChevronRight className='chevronRight' style={{ color: 'black', cursor: "pointer", marginRight: "" }} onClick={() => increaseStartDate()} />

                                                    </button>
                                                    to
                                                    <button title='Previous day' style={{ background: 'none', border: 'none', marginLeft: "", marginTop: "", position: '', width: '' }}>
                                                        <ChevronLeft className='chevronLeft' style={{ color: 'black', cursor: "pointer", marginLeft: "" }} onClick={() => decreaseEndDate()} />
                                                    </button>
                                                    <strong>{getDateString(selectedDayRange.to, "ll")}
                                                    </strong>
                                                    <button title='Next day' style={{ background: 'none', border: 'none', marginLeft: "", marginTop: "", position: '' }} >
                                                        <ChevronRight className='chevronRight' style={{ color: 'black', cursor: "pointer", marginRight: "" }} onClick={() => increaseEndDate()} />

                                                    </button>
                                                    <button title='Next interval' style={{ background: 'none', border: 'none', marginLeft: "", marginTop: "", position: '' }} >
                                                        <ChevronsRight className='chevronsRight' style={{ color: 'black', cursor: "pointer", marginRight: "" }} onClick={() => increaseDateRange()} />

                                                    </button>
                                                </p>

                                        }
                                    </div>
                                </div>
                            </>
                        }

                        {
                            // pageTitle === 'Comparisons' && compareType === 'single' &&
                            currentType === "compareMultiple" &&
                            <div className='text-end'>

                                <div className='d-flex coloum_filter' style={{ overflowX: 'auto', width: '100%' }} id='style-horizontal'>
                                    <p className='filter-p-tag setMarging' style={{ padding: 0, margin: 0, lineHeight: "2rem", whiteSpace: 'nowrap', marginLeft: 'auto' }}>Compare <span className='mx-1'><strong>{storeOneName} </strong></span> from </p>

                                    {
                                        dateFilterFlag && dateSelection in dates ?
                                            <p style={{ padding: 0, margin: 0, lineHeight: "2rem" }} className='px-1 filter-p-tag'>
                                                {dateSelection in dates && dates[dateSelection].label}
                                            </p>
                                            :

                                            singleRangeDateFilters.filter((item) => { return item.id === dateSelection }).length > 0 ?

                                                <p style={{ padding: 0, margin: 0, lineHeight: "2rem" }} className='px-1 filter-p-tag '>
                                                    {singleRangeDateFilters.filter((item) => { return item.id === dateSelection })[0].label}
                                                </p>
                                                :
                                                getDifference(selectedDayRange.from, selectedDayRange.to) == 0 ?
                                                    <>
                                                        <p style={{ padding: 0, margin: 0, lineHeight: "2rem" }} className='px-1  '>
                                                            <strong>{getDateString(selectedDayRange?.from, "ll")}</strong>
                                                        </p>
                                                    </>
                                                    :
                                                    <>
                                                        <p style={{ whiteSpace: 'nowrap', padding: 0, margin: 0, lineHeight: "2rem" }} className='px-1  '>
                                                            <strong>{getDateString(selectedDayRange?.from, "ll")}</strong> to <strong>{getDateString(selectedDayRange?.to, "ll")}</strong>
                                                        </p>
                                                    </>


                                    }

                                    <p className='px-1 filter-p-tag' style={{ padding: 0, margin: 0, lineHeight: "2rem", whiteSpace: 'nowrap' }}>with <strong>{storeTwoName}</strong> from</p>

                                    {
                                        dateFilterFlagTwo && dateSelectionTwo in dates ?
                                            <p style={{ padding: 0, margin: 0, lineHeight: "2rem" }} className='px-1  '>
                                                {dateSelectionTwo in dates && dates[dateSelectionTwo].label}
                                            </p>
                                            :
                                            singleRangeDateFilters?.filter((item) => { return item.id === dateSelectionTwo }).length > 0 ?
                                                <p style={{ padding: 0, margin: 0, lineHeight: "2rem" }} className='px-1 '>
                                                    {singleRangeDateFilters?.filter((item) => { return item.id === dateSelectionTwo })[0]?.label}

                                                </p>

                                                :
                                                getDifference(selectedDayRangeTwo.from, selectedDayRangeTwo.to) == 0 ?

                                                    <p className='px-1 ' style={{ padding: 0, margin: 0, lineHeight: "2rem" }}>
                                                        <strong>{getDateString(selectedDayRangeTwo?.from, "ll")}</strong>
                                                    </p> :

                                                    <p className='px-1 ' style={{ whiteSpace: 'nowrap', padding: 0, margin: 0, lineHeight: "2rem" }}>
                                                        <strong>{getDateString(selectedDayRangeTwo?.from, "ll")}</strong> to <strong>{getDateString(selectedDayRangeTwo?.to, "ll")}</strong>
                                                    </p>

                                    }
                                </div>
                            </div>
                        }
                    </div>
                </Col>
            </Row>
        </Fragment>

    )
}
