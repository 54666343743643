import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { BarChart, Hash, Layout, PieChart, Star, ArrowLeft } from "react-feather";
import { useParams, Link } from "react-router-dom";
import MainFilter from '../../components/filters/MainFilter';
import { Card, CardBody, Row, Col, Progress, Table, Button } from "reactstrap";
import { getUserActivityRequest, user } from '../../helpers/requests';
import moment from 'moment';
import { convertMsToHM } from '../../helpers/utils';

export default function UserHistory() {

    let userActivities = useSelector((state) => state.clientsUser.userActivities);
    const [currentUser, setCurrentUser] = useState();

    const { id } = useParams();
    const dispatch = useDispatch();

    const updateFilter = (filter) => {
        if (user !== undefined && user) {
            getUserActivityRequest({ ...filter, client_id: user.access[0].client_id, dispatch })

        }
    }

    useEffect(() => {
        if (id !== undefined && id) {
            let currentUser = userActivities.filter((item) => { return item.user_id === id })[0]
            setCurrentUser(currentUser)
        }

    }, [id])


    useEffect(() => {
        if (userActivities) {
            let currentUser = userActivities.filter((item) => item.user_id === id)[0];
            setCurrentUser(currentUser)
        }

    }, [userActivities])
    return (
        <div>

            <div className='back_arrow my-1'>
                <Link style={{ textDecoration: 'none' }} to={'/users-activity'}>
                    <button style={{ background: 'none', border: 'none' }} title='Back to users'>
                        <ArrowLeft size={16} />
                    </button>
                </Link>
            </div>
            <div className=''>


                <MainFilter
                    pageTitle="Users Activity"
                    updateFilter={(e) => updateFilter(e)}
                    type="calendar"
                    currentView={null}
                ></MainFilter>
            </div>
            <Row className="mb-3">
                <Col>
                    <Table
                        bordered
                        style={{ fontSize: "40px", textAlign: "center" }}
                    >
                        <thead>
                            <tr style={{ topBorder: '' }}>
                                <th>Username</th>
                                <th>Duration</th>
                                <th>Login</th>
                                <th>Logout</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentUser !== undefined && currentUser && "history" in currentUser && currentUser.history.length > 0 && currentUser.history.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td>
                                            {/* <Link to={"/user-history/"+item.user_id} > */}
                                            {currentUser.user_name}
                                            {/* </Link> */}
                                        </td>
                                        <td>  {convertMsToHM(item.duration)} </td>
                                        <td> {moment(new Date(item.x)).format("MMM DD,yyyy hh:mm a")}</td>
                                        <td> {moment(new Date(item.y)).format("MMM DD,yyyy hh:mm a")}</td>
                                    </tr>
                                )
                            })}

                        </tbody>

                    </Table>
                </Col>
            </Row>

        </div >
    )
}
