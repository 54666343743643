import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import { salesTrafficPerformance, user } from "../../../helpers/requests";
import Performance from "./Performance";
import SalesPerformance from "./SalesPerformance";

export default function InsightPerformance(props) {


    const dispatch = useDispatch();
    const performance = useSelector((state) => state?.insights?.insightPerformance);
    const [activeView, setActiveView] = useState('store')
   
    useEffect(() => {
        if (typeof props.filters !== "undefined" && props.filters) {

            var sids = user.access.map((item) => {
                return item._id;
            });

            let obj = {
                start: props.filters.start,
                end: props.filters.end,
                sid: sids,
                type: activeView,
                dispatch,
            };

            salesTrafficPerformance(obj);
        }
    }, [activeView, props.filters])



    return (
        <div>
            {props.currentView === "trafficPerformance" &&
                <Performance
                    data={performance}
                    filters={props.filters}
                    currentView={props.currentView}
                    activeView={activeView}
                    setActiveView={(output) => setActiveView(output)}
                />

            }
            {
                props.currentView === "salesPerformance" &&
                <SalesPerformance
                    data={performance}
                    currentView={props.currentView}
                    activeView={activeView}
                    filters={props.filters}
                    setActiveView={(output) => setActiveView(output)}
                />

            }
        </div>
    )
}
