import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { BarChart2, Hash, Layout, PieChart, Star, TrendingUp } from "react-feather";
import { Button, ButtonGroup, Card, Col, Row, CardBody, Progress } from "reactstrap";
import InsightsLinegraph from "../../components/charts/InsightsLinegraph";
import moment from "moment";
import { getAllPrimaryQuestionRequest, user } from "../../helpers/requests";
import { StatusChecker } from "../../components/StatusChecker";

export default function InsightsTrends({ trends, customTrends, deleteCustom, filters }) {
   
    const stores = user.access.map((item) => { return { label: item.label, _id: item._id } })
    const [currentStore, setCurrentStore] = useState(stores[0]);
    const [cutom, setCustom] = useState([])
    const dispatch = useDispatch();


    useEffect(() => {
        if(filters !== undefined && filters){

            let _currentStore = stores.filter((item) => {return item._id === filters.sid[0]});
            setCurrentStore(_currentStore[0]);
        }

    }, [filters])

    useEffect(() => {

        let obj = {
            sid: [currentStore._id],
            dispatch
        }
        getAllPrimaryQuestionRequest(obj)

        const _customTrend = customTrends.filter((trend) => {
            return trend.question.payload.sid[0] === currentStore._id
        })
        setCustom(_customTrend);




    }, [currentStore])

    useEffect(() => {
        if (customTrends) {
            const _customTrend = customTrends.filter((trend) => {
                return trend.question.payload.sid[0] === currentStore._id
            })
            setCustom(_customTrend);
        }
    }, [customTrends])



    const getLinegraphData = (item) => {
        item.answers = item.answers.sort((a, b) => {
            return new Date(a._id) - new Date(b._id);
        })
        let lineGraphData = { data: [], labels: [] };
        let _labels;
        let granularity = item.question.payload.type;
        let _data = item.answers?.map((item) => item.value);
        let basicTrends = item?.answers;
        if (granularity == "weekly") {
            _labels = basicTrends?.map((val, index) => {
                return moment(val._id).format("MMM Do YY");
            });
        } else if (granularity == "monthly") {
            _labels = basicTrends?.map((val, index) => {
                return moment(val._id).format("MMM YY");
            });
        } else if (granularity == "daily") {
            _labels = basicTrends?.map((val, index) => {
                return moment(val._id).format("Do MMM YY");
            });
        }
        lineGraphData.data = _data;
        lineGraphData.labels = _labels;
        return lineGraphData;



    }


    let array = [9,43,3,5,7,8,];
    let sum = array.reduce((acc,curr) => {
        console.log('acc,curracc,curr',acc,curr);
    })
    console.log('sum',sum);

    return (
        <div>

            <div className="btn-group" role="group" aria-label="Basic example"
                style={{ height: "", overflowX: 'scroll', width: '100%' }}
                id='style-horizontal'
            >
                {stores.map((store, index) => {
                    return (

                        <button type="button"
                        key={index}
                            className={currentStore.label === store.label ? "btn btn-primary force-overflow-horizontal my-3" : "my-3 px-3 force-overflow-horizontal btn btn-sm btn-outline-primary"}
                            style={{ maxWidth: "12rem" }}
                            onClick={() => setCurrentStore(store)}
                        >{store.label}</button>

                    )
                })}
            </div>


            {cutom.length > 0 && <h5 className="p-0 m-3 fw-normal">Your Queries</h5>}

            <Row>
                {cutom?.map((trend, index) => {

                    let _seriesData = getLinegraphData(trend)
                    return (
                        <Col md={6} className="my-3" key={"traffic-card-" + index}
                        >
                            <InsightsLinegraph
                                custom={true}
                                seriesData={_seriesData}
                                hideControls={true}
                                title={trend.question.question}
                                graphType={"line"}
                                deleteCustom={(output) => deleteCustom(output)}
                                index_id={trend.question._id}
                                showPercentage={trend.question.payload.growth}
                            />
                        </Col>
                    )
                })}
            </Row>

            <h5 className="p-0 m-3 fw-normal">Prime Queries</h5>
            <Row>
                {trends.map((trend, index) => {
                    // if (trend.length)
                    {
                        let _seriesData = getLinegraphData(trend)
                        // if (_seriesData !== undefined && _seriesData) 
                        {

                            return (
                                <Col md={6} className="my-3" key={"traffic-card-" + index}
                                >
                                    <InsightsLinegraph
                                        custom={false}
                                        seriesData={_seriesData}
                                        hideControls={true}
                                        title={trend.question.question}
                                        graphType={"line"}
                                        showPercentage={trend.question.payload.growth}
                                    />
                                </Col>
                            )
                        }
                    }
                })}

            </Row>
        </div>
    )
}
