import { actionTypes } from "../constants/action-types"


export const setInsights = (payload) => {
    return {
        payload : payload,
        type : actionTypes.SET_INSIGHT
    }
}

export const setInsightData = (payload) => {
    return {
        type: actionTypes.SET_INSIGHT_DATA,
        payload: payload
    }
}

export const setInsightFilter = (payload) => {
    return {
        type: actionTypes.SET_INSIGHT_FILTER,
        payload: payload
    }
}

export const setInsightProgress = (payload) => {
    return {
        type: actionTypes.SET_INSIGHT_PROGRESS,
        payload: payload
    }
}


export const setBasicInsights = (payload) => {
    return {
        payload : payload,
        type : actionTypes.GET_BASIC_INSIGHTS
    }
}

export const setBasicTrends = (payload) => {
    return {
        payload : payload,
        type : actionTypes.GET_BASIC_TRENDS
    }
}

export const setShowFilters = (payload) => {
    return {
        payload : payload,
        type : actionTypes.SET_SHOW_FILTER
    }
}
export const setPrimaryQuestion = (payload) => {
    return {
        payload : payload,
        type : actionTypes.SET_PRIMARY_INSIGHTS_QUESTION
    }
}

export const setCustomQuestion = (payload) => {
    return {
        payload : payload,
        type : actionTypes.SET_CUSTOM_QUESTION
    }
}

export const setInsightPerformance = (payload) => {
    return {
        payload : payload,
        type : actionTypes.SET_INSIGHT_PERFORMANCE
    }
}








