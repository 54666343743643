import moment from 'moment';
import { encryptStorage } from './encryptStorage';
import { user } from './requests';
import { useRef } from 'react';
import { useEffect } from 'react';
import { numberFormater } from './common';
import { Page, Text, View, Document, StyleSheet, PDFDownloadLink, Image } from '@react-pdf/renderer';



export const isIterableArray = array => Array.isArray(array) && !!array.length;


//===============================
// Breakpoints
//===============================
export const breakpoints = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1540
};

//===============================
// Store
//===============================
export const getItemFromStore = (key, defaultValue, store = encryptStorage) =>
  JSON.parse(store.getItem(key)) || defaultValue;
export const setItemToStore = (key, payload, store = encryptStorage) => store.setItem(key, JSON.stringify(payload));
export const getStoreSpace = (store = encryptStorage) =>
  parseFloat((escape(encodeURIComponent(JSON.stringify(store))).length / (1024 * 1024)).toFixed(2));

//===============================
// Cookie
//===============================
export const getCookieValue = name => {
  const value = document.cookie.match('(^|[^;]+)\\s*' + name + '\\s*=\\s*([^;]+)');
  return value ? value.pop() : null;
};

export const createCookie = (name, value, cookieExpireTime) => {
  const date = new Date();
  date.setTime(date.getTime() + cookieExpireTime);
  const expires = '; expires=' + date.toUTCString();
  document.cookie = name + '=' + value + expires + '; path=/';
};

//===============================
// Moment
//===============================
export const getDuration = (startDate, endDate) => {
  if (!moment.isMoment(startDate)) throw new Error(`Start date must be a moment object, received ${typeof startDate}`);
  if (endDate && !moment.isMoment(endDate))
    throw new Error(`End date must be a moment object, received ${typeof startDate}`);

  return `${startDate.format('ll')} - ${endDate ? endDate.format('ll') : 'Present'} • ${startDate.from(
    endDate || moment(),
    true
  )}`;
};

export const numberFormatter = (number, fixed = 2) => {
  // Nine Zeroes for Billions
  return Math.abs(Number(number)) >= 1.0e9
    ? (Math.abs(Number(number)) / 1.0e9).toFixed(fixed) + 'B'
    : // Six Zeroes for Millions
    Math.abs(Number(number)) >= 1.0e6
      ? (Math.abs(Number(number)) / 1.0e6).toFixed(fixed) + 'M'
      : // Three Zeroes for Thousands
      Math.abs(Number(number)) >= 1.0e3
        ? (Math.abs(Number(number)) / 1.0e3).toFixed(fixed) + 'K'
        : Math.abs(Number(number)).toFixed(fixed);
};

//===============================
// Colors
//===============================
export const hexToRgb = hexValue => {
  let hex;
  hexValue.indexOf('#') === 0 ? (hex = hexValue.substring(1)) : (hex = hexValue);
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(
    hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b)
  );
  return result ? [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)] : null;
};

export const rgbColor = (color = colors[0]) => `rgb(${hexToRgb(color)})`;
export const rgbaColor = (color = colors[0], alpha = 0.5) => `rgba(${hexToRgb(color)},${alpha})`;

export const colors = [
  '#2c7be5',
  '#00d97e',
  '#e63757',
  '#39afd1',
  '#fd7e14',
  '#02a8b5',
  '#727cf5',
  '#6b5eae',
  '#ff679b',
  '#f6c343'
];

export const charCheck = (input) => {
  let str = input.split("")
  let i = removeSpaces(str);
  let value = str.join("").substring(0, i);
  var input_char = value.charCodeAt(0);

  // CHECKING FOR ALPHABET
  if (input_char >= 65 && input_char <= 90) {
    return input
  }

  else if (input_char >= 97 && input_char <= 122) {
    return input
  }
  else {
    return null
  }
}

export const themeColors = {
  primary: '#FFCD30',
  secondary: '#FF9D01',
  success: '#FF7701',
  info: '#27bcfd',
  warning: '#f5803e',
  danger: '#e63757',
  light: '#f9fafd',
  dark: '#0b1727'
};
// [
//   "#FFCD30", "#FF9D01", "#FF7701", "#E24800", "#CC2200", "#860102", "#0000DB", "#0040FF", "#0080FF", "#00C0FF", "#00FFFF", "#00FFB4", "#02C39A", "#93E2D5", "#34DDDD", "#7AF5F5", "#78D5E3", "#89E894", "#BED661", "#A70100", "#FE0000", "#FF5455", "#FE7A7C", "#FFBBB9", "#BB0001", "#7B0001", "#400102", "#2C83C6", "#39A8F0", "#62BEEF", "#A6D4EC", "#E1A793", "#DD6D55", "#E24C33", "#C33726"
// ]
export const graphColors = {
  primary: '#FFCD30',
  secondary: '#FF9D01',
  success: '#FF7701',
  info: '#27bcfd',
  warning: '#f5803e',
  danger: '#e63757',
}

export const grays = {
  white: '#fff',
  100: '#f9fafd',
  200: '#edf2f9',
  300: '#d8e2ef',
  400: '#b6c1d2',
  500: '#9da9bb',
  600: '#748194',
  700: '#5e6e82',
  800: '#4d5969',
  900: '#344050',
  1000: '#232e3c',
  1100: '#0b1727',
  black: '#000'
};

export const darkGrays = {
  white: '#fff',
  1100: '#f9fafd',
  1000: '#edf2f9',
  900: '#d8e2ef',
  800: '#b6c1d2',
  700: '#9da9bb',
  600: '#748194',
  500: '#5e6e82',
  400: '#4d5969',
  300: '#344050',
  200: '#232e3c',
  100: '#0b1727',
  black: '#000'
};

export const getGrays = isDark => (isDark ? darkGrays : grays);

export const rgbColors = colors.map(color => rgbColor(color));
export const rgbaColors = colors.map(color => rgbaColor(color));

//===============================
// Echarts
//===============================
export const getPosition = (pos, params, dom, rect, size) => ({
  top: pos[1] - size.contentSize[1] - 10,
  left: pos[0] - size.contentSize[0] / 2
});

//===============================
// E-Commerce
//===============================
export const calculateSale = (base, less = 0, fix = 2) => (base - base * (less / 100)).toFixed(fix);
export const getTotalPrice = (cart, baseItems) =>
  cart.reduce((accumulator, currentValue) => {
    const { id, quantity } = currentValue;
    const { price, sale } = baseItems.find(item => item.id === id);
    return accumulator + calculateSale(price, sale) * quantity;
  }, 0);

//===============================
// Helpers
//===============================
export const getPaginationArray = (totalSize, sizePerPage) => {
  const noOfPages = Math.ceil(totalSize / sizePerPage);
  const array = [];
  let pageNo = 1;
  while (pageNo <= noOfPages) {
    array.push(pageNo);
    pageNo = pageNo + 1;
  }
  return array;
};

export const capitalize = str => (str.charAt(0).toUpperCase() + str.slice(1)).replace(/-/g, ' ');
export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const captialLetterOfEachWord = (mySentence) => {
  const words = mySentence.split(" ");

  let item = words.map((word) => {

    return word[0]?.toUpperCase() + word.substring(1);

  }).join(" ");

  return item
}
export const toLowerCase = item => {
  return item.target.value.toLowerCase();
}

export const routesSlicer = ({ routes, columns = 3, rows }) => {
  const routesCollection = [];
  routes.map(route => {
    if (route.children) {
      return route.children.map(item => {
        if (item.children) {
          return routesCollection.push(...item.children);
        }
        return routesCollection.push(item);
      });
    }
    return routesCollection.push(route);
  });

  const totalRoutes = routesCollection.length;
  const calculatedRows = rows || Math.ceil(totalRoutes / columns);
  const routesChunks = [];
  for (let i = 0; i < totalRoutes; i += calculatedRows) {
    routesChunks.push(routesCollection.slice(i, i + calculatedRows));
  }
  return routesChunks;
};

export const getPageName = pageName => {
  return window.location.pathname.split('/').slice(-1)[0] === pageName;
};

export const copyToClipBoard = textFieldRef => {
  const textField = textFieldRef.current;
  textField.focus();
  textField.select();
  document.execCommand('copy');
};


export const getDifference = (from, to) => {
  let _startDate = new Date(from);
  let _endDate = new Date(to);
  if (from && to) {
    var start = moment(new Date(_startDate.getFullYear(), _startDate.getMonth(), _startDate.getDate())).startOf("day");
    var end = moment(new Date(_endDate.getFullYear(), _endDate.getMonth(), _endDate.getDate())).endOf("day");
    return Math.abs(end.diff(start, "days"));
  } else {
    return 0;
  }
}

/****** to get fids of floor which is not included in total count but added in store  ******/
export const getExludedFids = (stores) => {
  let _stores = stores.slice();
  let _excludedFids = [];
  _stores?.map((row) => {
    if (row.floors.length > 0) {
      for (let i = 0; i < row.floors.length; i++) {
        if (row.floors[i] && row.floors[i].included == false) {
          _excludedFids.push(row.floors[i]?._id)
        }
      }
    }

  })
  return _excludedFids;

}

/****** to get fids of floor which is included in total count   ******/

export const getIncludedFids = (stores) => {

  let _stores = stores.slice();
  let _includedFids = [];
  _stores?.map((row) => {
    if (row.floors.length > 0) {
      for (let i = 0; i < row.floors.length; i++) {
        if (row.floors[i] && row.floors[i].included && row.floors[i].included == true) {
          _includedFids.push(row.floors[i]?._id)
        }
      }
    }

  })
  return _includedFids;


}


export const convertItemIntoArray = (item) => {
  let array = Object.keys(item ? item : {}).map((arr) => { return arr });
  return array
}


export const getDateString = (dateObject, format = "YYYY-MM-DD HH:MM") => {
  let _dateObject = new Date(dateObject);

  let date = moment(new Date(_dateObject?.getFullYear(), _dateObject?.getMonth(), _dateObject?.getDate())).startOf("day").format(format);
  return date
}

export const checkString = (value, key) => {
  var digits = /^[0-9]+$/
  var letters = /^([A-Za-z ]+|\d+)$/;
  if (value.target.value == "" || (value.target.value.match(letters) && !value.target.value.match(digits))) {
    return value
  }
}

export const emailValidation = (value) => {
  var email = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/;
  if (value.match(email)) {
    return true
  }
  else {
    return false
  }
}


export const tabStyle = (type, selected) => {
  return {
    fontSize: "14px",
    whiteSpace: 'nowrap',
    border: "0px",
    color: selected === type ? themeColors.dark : "inherit",
    background: "white",
    padding: "20px 20px 17px 20px",
    borderBottom:
      selected === type
        ? "3px solid " + graphColors.primary
        : "3px solid transparent",
  };
};




export const timeFormat = (hour) => {
  var suffix = hour >= 12 ? "PM" : "AM";
  var hours = ((hour + 11) % 12 + 1) + suffix
  return hours

}

export const numberFixed = (x) => {
  var num = Number(parseFloat(x).toFixed(2));
  return num
}

export const numberFixedToOne = (x) => {
  var num = Number(parseFloat(x).toFixed(1));
  return num
}




export const getTimeDifference = (alarmdate) => {
  let currentdate = new Date();
  var alarmCreationDate = new Date(alarmdate);
  var difference_In_Time = currentdate.getTime() - alarmCreationDate.getTime();
  var dateInmins = Math.floor(difference_In_Time / 60000);
  var dateInHrs = Math.floor(dateInmins / 60);
  var difference_In_Days = difference_In_Time / (1000 * 3600 * 24);
  let response;
  if (Math.round(difference_In_Days) === 1) {
    response = Math.round(difference_In_Days) + ' ' + "day ago";
  } else if (Math.round(difference_In_Days) > 1) {
    response = Math.round(difference_In_Days) + ' ' + "days ago";
  } else if (dateInmins < 120 && dateInmins >= 60) {
    response = dateInHrs + ' ' + "hour ago";
  } else if (dateInmins >= 120) {
    response = dateInHrs + ' ' + "hours ago";
  } else if (dateInmins < 1) {
    response = "Just now";
  } else if (dateInmins == 1) {
    response = dateInmins + ' ' + "mins ago";
  }
  else {
    response = dateInmins + ' ' + "mins ago";
  }
  let obj = {
    response: response,
    dateInmins: dateInmins
  }
  return obj;
}
export const getTimeDifferenceByMinutes = (alarmdate) => {
  let currentdate = new Date();
  var alarmCreationDate = new Date(alarmdate);
  var difference_In_Time = currentdate.getTime() - alarmCreationDate.getTime();
  var dateInmins = Math.floor(difference_In_Time / 60000);

  return dateInmins
}


export const getLabelsBySid = (sid) => {

  let store = user.access.filter((item) => { return item._id == sid })[0]
  return store.label
}

export const padTo2Digits = (num) => {
  return num.toString().padStart(2, '0');
}

export const convertMsToHM = (milliseconds) => {
  let seconds = Math.floor(milliseconds / 1000);
  let minutes = Math.floor(seconds / 60);
  let hours = Math.floor(minutes / 60);

  seconds = seconds % 60;
  // 👇️ if seconds are greater than 30, round minutes up (optional)
  minutes = seconds >= 30 ? minutes + 1 : minutes;

  minutes = minutes % 60;

  // 👇️ If you don't want to roll hours over, e.g. 24 to 00
  // 👇️ comment (or remove) the line below
  // commenting next line gets you `24:00:00` instead of `00:00:00`
  // or `36:15:31` instead of `12:15:31`, etc.
  hours = hours % 24;

  return `${padTo2Digits(hours)}:${padTo2Digits(minutes)}`;
}

export const convertMinutesIntoHour = (_minutes) => {
  let minutes = _minutes;
  let hours = Math.floor(minutes / 60);

  // 👇️ if seconds are greater than 30, round minutes up (optional)

  minutes = minutes % 60;

  // 👇️ If you don't want to roll hours over, e.g. 24 to 00
  // 👇️ comment (or remove) the line below
  // commenting next line gets you `24:00:00` instead of `00:00:00`
  // or `36:15:31` instead of `12:15:31`, etc.
  hours = hours % 24;

  return `${padTo2Digits(hours)}:${padTo2Digits(minutes)}:00`;
}

export const convertMinutesIntoHourInString = (_minutes) => {
  let minutes = _minutes;
  let hours = Math.floor(minutes / 60);

  // 👇️ if seconds are greater than 30, round minutes up (optional)

  minutes = minutes % 60;

  // 👇️ If you don't want to roll hours over, e.g. 24 to 00
  // 👇️ comment (or remove) the line below
  // commenting next line gets you `24:00:00` instead of `00:00:00`
  // or `36:15:31` instead of `12:15:31`, etc.
  hours = hours % 24;

  return `${hours} hours and ${minutes} minutes`;
}




export const convertIntoMillion = (num) => {

  if (typeof num !== undefined && num) {
    if (num >= 1000000) {
      return (num / 1000000).toFixed(1) + 'M'; // convert to M for number from > 1 million 
    } else {
      return num; // if value < 1000, nothing to do
    }
  }
  else {
    return 0
  }
}





export const removeSpaces = (str) => {
  // To keep track of non-space
  // character count
  var count = 0;

  // Traverse the given string. If current
  // character is not space, then place
  // it at index 'count++'
  for (var i = 0; i < str.length; i++)
    if (str[i] !== " ") str[count++] = str[i];
  // here count is
  // incremented

  return count;
}


export const q = (input) => {
  let str = input.split("")
  let i = removeSpaces(str);
  let value = str.join("").substring(0, i);
  var input_char = value.charCodeAt(0);

  // CHECKING FOR ALPHABET
  if (input_char >= 65 && input_char <= 90) {
    return input
  }

  else if (input_char >= 97 && input_char <= 122) {
    return input
  }
  else {
    return null
  }
}

export const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

// To check if a date is today's date

export const isToday = (date) => {
  let today = new Date();
  if (today.toDateString() === date.toDateString()) {
    return true
  }
  else {
    return false
  }
}

// to check date is greater than today's date

export const isGreaterThanToday = (date) => {
  let givenDate = new Date(date);
  let currentDate = new Date();
  if (givenDate > currentDate) {
    return true
  }
  else return false
}

export const convertIntoHours = (item) => {
  if (item >= 60) {

    return (item / 60 + 'hours')

  }
  else return item + 'min'
}

export const adlyticFormatter = (num) => {
  console.log('numnumnum', num);

  if (typeof num !== undefined && num) {
    num = parseFloat(num)
    if (num >= 1000000) {
      return <span title={numberFormater(num.toFixed())}>{(num / 1000000).toFixed(1) + 'M'}</span>
        ; // convert to M for number from > 1 million 
    } else {
      // if (Number.isInteger(num)) 
      {
        if (num > 100) {
          let _num = Number(parseInt(num));
          let number = _num.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          return number
        }
        else {
          let _num = Number(parseFloat(num).toFixed(2));
          let number = _num.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          return number;
        }
      }



    }
  }
  else {
    return 0
  }
}


export const getUserFeatureV_2 = (enabledFeatures) => {

  console.log('enabledFeatures', enabledFeatures);

  if (typeof enabledFeatures !== undefined && enabledFeatures) {

    let _allFeatures = [];
    enabledFeatures.footfall.forEach((item) => {
      item.children.forEach((children) => {
        let obj = { ...children, feature: "footfall", percentage: children.key === "total" ? false : true }
        _allFeatures.push(obj)
      })
    })
    Object.keys(enabledFeatures.sales ? enabledFeatures.sales : {}).forEach((saleFeature) => {
      let obj = {
        label: enabledFeatures.sales[saleFeature],
        key: saleFeature,
        feature: "sale",
        percentage: false
      }
      _allFeatures.push(obj)
    })

    return _allFeatures
  }
}

export const getLengthFlag = (item) => {
  if (item !== undefined && item) {
    if (Array.isArray(item)) {
      if (item.length > 0) {
        return true
      }
      else {
        return false
      }
    }
    else {
      if (Object.keys(item).length > 0) {
        return true
      }
      else {
        return false
      }
    }
  }
  else {
    return false
  }
}

// PDFDownloadLink library styling 
export const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  report: {
    marginTop: 105,
    fontSize: 80,
    textAlign: 'center',
    color: "#E88131",
    fontStyle: "italic"
  },
  title: {
    fontSize: 16,
    textAlign: 'center',
  },
  author: {
    fontSize: 12,
    fontWeight: 100,
    opacity: 0.7,
    textAlign: 'center',
    marginBottom: 10,
  },
  subtitle: {
    fontSize: 18,
    margin: 12,
  },
  text: {
    margin: 12,
    fontSize: 14,
    textAlign: 'justify',
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
    marginBottom: 30
  },
  logo: {
    paddingTop: 75,
    marginVertical: 15,
    marginHorizontal: 100,
    marginBottom: 10,
    marginRight: 30
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: 'center',
    color: 'grey',
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
  row: {
    flex: 1,
    flexDirection: 'row',
    flexGrow: 1,
  },
  descriptionPage: {
    fontFamily: 'Helvetica',
    fontStyle: "normal",
    letterSpacing: "-1px",
    fontWeight: 700

  },
  left: {
    // width: '33%',//<- working alternative
    flexGrow: 0,
    flexShrink: 1,
    flexBasis: 200,
    // backgroundColor: "#E88131"
  },

  right: {
    padding: 2,
    // width: '66%', //<- working alternative
    flexShrink: 1,
    flexGrow: 2,
  },
  tableContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
  },
  page: {
    fontSize: 11,
    flexDirection: "column",
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    borderTop: '1px solid #EEE',
    paddingTop: 8,
    paddingBottom: 8,
  },
  headerTable: {
    borderTop: 'none',
  },
  bold: {
    fontWeight: 'bold',
  },

  description: {
    width: "60%",
  },
  xyz: {
    width: "40%",
  },
  table: {
    width: '100%',
  },
  row1: {
    width: '27%',
    paddingTop: 8,
    paddingBottom: 8,
  },
  row2: {
    width: '73%',
  },
  row2sub1: {
    width: '20%',
    // display: 'flex',
    // justifyContent: 'center',
    // alignItems: 'center'
  },
  row2sub2: {
    width: '60%',
    // flexDirection: 'row',
    // display: 'flex',
    // justifyContent: 'center',
    // alignItems: 'center'
  },
  row2sub3: {
    width: '20%',
    // display: 'flex',
    // justifyContent: 'center',
    // alignItems: 'center'
  },

});

/******** Get labels in seconds ***********/
export const getLabelsInSeconds = (startDate, endDate, format) => {
  // let format = "YYYY-MM-DD HH:mm:ss"
  var labels = [];
  var start = moment(startDate);
  var end = moment(endDate);


  console.log('startstartstart', start.format(format));

  while (end.diff(start, "seconds") > 0) {
    labels.push(start.format(format));
    start = start.add(1, "seconds");
  }
  return labels;

};

export const globalTime = (time) => {

  var offset = new Date().getTimezoneOffset() / 60;
  console.log('offset', offset);
  let key;
  if (offset > 0) {
    key = moment(new Date(time)).subtract(Math.abs(offset), "hours")

  }
  else {
    key = moment(new Date(time)).add(Math.abs(offset), "hours")
  }

  return key

}



export const NewlineText = (item) => {
  const newText = item.split('\n').map(str => <p>{str}</p>);

  return newText;
}

export function convertUTCDateToLocalDate(date, format) {
  let _date = moment(new Date(date).toLocaleString()).format(format);
  return _date
}


export const removeSpaceAndCamelCase = (mySentence) => {

  const words = mySentence.split(" ");

  let item = words.map((word) => {

    console.log('word', word);

    return word.toUpperCase();

  }).join("");

  return item

}

export const getLabelsOfHours = (startHour, endHour, date) => {
  var labels = [];
  var start = moment(new Date(date).setHours(startHour));
  var end = moment(new Date(date).setHours(endHour));

  let _startHour = parseInt(startHour);
  let _endHour = parseInt(endHour);





  if (_endHour > _startHour) {

    for (let i = _startHour; i <= _endHour; i++) {
      let _label = moment(new Date(start).setHours(i)).format("hh:mm a");
      labels.push(_label);
    }

  }
  else if (_endHour < _startHour) {
    for (let i = _startHour; i <= 23; i++) {
      let _label = moment(new Date(start).setHours(i)).format("hh:mm a");
      labels.push(_label);
    }
    for (let i = 0; i <= _endHour; i++) {
      let _label = moment(new Date(start).setHours(padTo2Digits(i))).format("hh:mm a");
      labels.push(_label);
    }

  }


  // labels.pop()
  return labels;

};


export const humanReadAbleDate = (_date) => {
  const date = new Date(_date);

  const formattedDate = date.toLocaleString("en-US", {
    month: "short",
    day: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "2-digit",
    second: "2-digit",
  });

  return formattedDate
}







