import {actionTypes} from '../constants/action-types';
const initialState = {
    branchFilters: [],
    dateFilters: [],
    loaded: false
};
export const savedFilters = (state = initialState, {type, payload={}}) => {
    switch(type){
        case actionTypes.LIST_FILTER:
            var dateFilters = [];
            var branchFilters = [];
            dateFilters = payload.filter((row)=>{return row.type === "date"}, []);
            branchFilters = payload.filter((row)=>{return row.type !== "date"}, []);
            return {...state, dateFilters: dateFilters, branchFilters: branchFilters, loaded: true};
        case actionTypes.CREATE_FILTER:
             dateFilters = state.dateFilters.slice();
             branchFilters = state.branchFilters.slice();
            if(payload.type==="date"){
                dateFilters.push(payload);
            }else{
                branchFilters.push(payload);
            }
            return {...state, dateFilters: dateFilters, branchFilters: branchFilters};
        case actionTypes.DELETE_FILTER:
            branchFilters = state.branchFilters.slice();
            dateFilters = state.dateFilters.slice();
            dateFilters = dateFilters.filter((row)=>{return row._id !== payload});
            branchFilters = branchFilters.filter((row)=>{return row._id !== payload});
            return {...state, dateFilters: dateFilters, branchFilters: branchFilters};
        default:
            return state;
    }
}