import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner, Toast, ToastHeader } from 'reactstrap';
import { settings } from '../helpers/meta';
import { setLoading } from '../redux/actions/highlights';

var handler;
const Loaders = () => {
    const highlights = useSelector(state => state.highlights);
    const dispatch = useDispatch();
    const autoCloseLoader = () => {
        dispatch(setLoading(false));
        if(handler){
            clearTimeout(handler);
        }
    }

    useEffect(()=>{

        if(highlights.loading && settings.autoCloseLoader){
            // handler = setTimeout(()=>{
            //     autoCloseLoader()
            // }, settings.requestTimeout)
        }
    }, [highlights.loading])
    return (
        <Fragment>
            <div style={{position: "fixed", top: "10px", left: "50%", transform: "translateX(-50%)"}}>
                <Toast isOpen={highlights.loading}>
                    <ToastHeader icon={<Spinner size="sm" color="warning">Loading...</Spinner>}>
                        Loading...
                    </ToastHeader>
                </Toast>
            </div>
        </Fragment>
    )
}

export default Loaders;