import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Toast, ToastBody, ToastHeader } from 'reactstrap';
import { settings } from '../helpers/meta';
import { setError, setSuccess } from '../redux/actions/highlights';

var handler;
const Messages = () => {
    const highlights = useSelector(state => state.highlights);
    const dispatch = useDispatch();
    const closeMessage = () => {
        dispatch(setError(""));
        dispatch(setSuccess(""));
        if(handler){
            clearTimeout(handler);
        }
    }
    useEffect(()=>{
        if((highlights.success.length > 0 || highlights.error.length > 0) && settings.autoCloseMessage){
            handler = setTimeout(()=> {
                closeMessage();
            }, settings.autoCloseMessageTimeout);
        }
    }, [highlights]) 
    return (
        <Fragment>
            <div style={{position: "fixed", right: "10px", top: "80px", zIndex: 100000}}>
                <Toast fade isOpen={(highlights.isSuccess && highlights.success.length > 0) || (!highlights.isSuccess && highlights.error.length > 0)}>
                    <ToastHeader icon={highlights.isSuccess ? "success" : "danger"} toggle={()=> closeMessage()}>
                        {highlights.isSuccess? "Success": "Error"}
                    </ToastHeader>
                    <ToastBody>
                        { highlights.isSuccess ? highlights.success : highlights.error }
                    </ToastBody>
                </Toast>
            </div>
        </Fragment>
    )
}

export default Messages;