import React, { useContext, useState, Fragment, useEffect } from 'react';
import { Row, Col, Card, CardBody, CardFooter, Button, CardHeader } from 'reactstrap';
import echarts from 'echarts/lib/echarts';
// import ReactEchartsCore from 'echarts-for-react/lib/core';
import ReactECharts from 'echarts-for-react';
import { getPosition, getGrays, colors, rgbaColor, isIterableArray, capitalize, graphColors } from '../../helpers/utils';
import 'echarts/lib/chart/bar';
import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/legend';

function getFormatter(params) {
    var str = "";
    for (var i = 0; i < params.length; i++) {
        const { seriesName, name, value } = params[i];
        if (i > 0) {
            str += ` | ${seriesName}: ${value}`;
        } else {
            str += `${seriesName}: ${value}`;
        }
    }
    return str;
}

const getOption = (month, isDark) => {
    const grays = getGrays(isDark);
    return null;
};

const LineGraph = ({ className, seriesData, hideControls, title, noHeader, space = 15, rotation = 0, height = '14rem', dataZoomFlag = false }) => {

    console.log('seriesData',seriesData);

    var options = {
        tooltip: {
            trigger: 'axis',
            padding: [10, 10],
            height: '100px',
            backgroundColor: "white",
            borderColor: "#d5d5d5",
            borderWidth: 1,
            textStyle: { color: colors.dark },
            transitionDuration: 0,
            confine: true,
            position(pos, params, dom, rect, size) {
                return getPosition(pos, params, dom, rect, size);
            }
        },
        legend: {
            data: (typeof seriesData.data !== undefined && seriesData.data.length > 0 ) ? seriesData.data.map((item) => {if(typeof item !== undefined && item && "name" in item){return item.name } }) : seriesData?.data?.name,
            // top : "-5%",
            // padding: 5
        },
       
        xAxis: {
            type: 'category',
            data: seriesData.labels,
            boundaryGap: true,
            axisPointer: {
                lineStyle: {
                    color: "#d5d5d5",
                    type: 'dashed',
                    size: "14px"
                },

            },
            splitLine: { show: false },
            axisLine: {
                lineStyle: {
                    color: rgbaColor('#111', 0.01),
                    type: 'dashed'
                }
            },
            axisTick: { show: false },
            axisLabel: {
                color: "#111",
                formatter: function (value) {
                    return `${value}`;
                },
                margin: [14],
                rotate: rotation
            }
        },
        yAxis: {
            type: 'value',
            minInterval : 1,
            axisPointer: { show: false },
            splitLine: {
                lineStyle: {
                    color: "#d5d5d5",
                    type: 'dashed'
                }
            },

            boundaryGap: true,
            axisLabel: {
                show: true,
                color: "#000",
                margin: 8
            },
            axisTick: {
                show: false,
            },
            axisLine: { show: false }
        },
       

        dataZoom: dataZoomFlag && [
            {
                type: 'inside',
                start: 0,
                end: 100,

            },
            {
                top: 255,
                start: 0,
                end: 100
            }
        ],
        series: seriesData.data,
        color: graphColors.primary,
        animationDuration: 1000,
        grid: { right: '28px', left: '60px', bottom: space + '%', top: '15%' }
    };
    const [graphType, setGraphType] = useState("line");
    const setType = (type) => {
        setGraphType(type);
        options.series.forEach((series, index) => series.type = type);
    }

    return (
        <Fragment>
            <Card className="" style={{ backgroundColor: '', overflow: '' }}>
                {
                    !noHeader &&
                    <CardHeader>
                        <Row>
                            <Col style={{ backgroundColor: '' }}>
                                {
                                    title != null &&

                                    <h4 className="m-0" style={{ fontSize: '14px' }}>{title}</h4>
                                }
                            </Col>
                        </Row>
                    </CardHeader>
                }
                <CardBody>
                    <ReactECharts
                        echarts={echarts}
                        option={options}
                        style={{ minHeight: '8.75rem', height: !noHeader ? height : '20rem' }}
                    />
                </CardBody>
            </Card>
        </Fragment>
    )
}

export default LineGraph;