import _logo from '../assets/img/logo.png';


export const menu = [
    { to: "/", label: "Summary", icon: null, children: [] },
    { to: "/traffic", label: "Traffic", icon: null, children: [] },
    { to: "/insights", label: "Insights", icon: null, children: [] },
    // {to: "/comparison", label: "Comparison", icon: null, children: []},
    { to: "/heatmap", label: "Heatmap", icon: null, children: [] },
    // {to: "/more", label: "More", icon: null, children: [
    { to: "/categories", label: "Categories", icon: null, children: [] },
    // {to: "/settings", label: "Settings", icon: null, children: []},
    // ]},

];



 export const baseUrl = "https://api.adlytic.ai/";  
// export const baseUrl = "https://devapi.adlytic.ai/";
// export const baseUrl = "http://localhost:4200/";
// export const baseUrl = "https://awtapi.adlytic.ai/"

// export const baseUrl = "http://3.212.42.202:4300/";
export const logo = _logo;

export const settings = {
    autoCloseLoader: true,
    autoCloseMessage: true, 
    requestTimeout: 60000,
    autoCloseMessageTimeout: 5000
}

export const dataPoints = {
    "total": "Total",
    // "exit": "Entries",
    "entry": "Exits",
    "male": "Male",
    "female": "Female",
    "kid": "Kids",
    "adult": "Adult",
    // "mask": "With Masks",
    // "noMask": "No Masks",
    "sale": "Sale",
    "transaction": "Transaction",
    "unis": "Units",
    // "entry": "Entry",
    // "exit": "Exit",
    "conversion": "Conversion (%)",
    "ipt": "IPT",
    "atv": "ATV",
    "asp": "ASP"
}

export const colors = {
    primary: "#E88131",
    primaryDark: "#e86531",
    primaryLight: "#e89f31",
    secondary: "#e8dc31",
    dark: "#000000",
    lightDark: "#484964",
    white: "#FFFFFF",
    highlight: "#F8F7FA",
    silver: "#E4E4E4",
    success: "#57E777",
    danger: "#F00",

}

export const graphColors = [
    "#FFCD30", "#FF9D01", "#FF7701", "#E24800", "#CC2200", "#860102", "#0000DB", "#0040FF", "#0080FF", "#00C0FF", "#00FFFF", "#00FFB4", "#02C39A", "#93E2D5", "#34DDDD", "#7AF5F5", "#78D5E3", "#89E894", "#BED661", "#A70100", "#FE0000", "#FF5455", "#FE7A7C", "#FFBBB9", "#BB0001", "#7B0001", "#400102", "#2C83C6", "#39A8F0", "#62BEEF", "#A6D4EC", "#E1A793", "#DD6D55", "#E24C33", "#C33726"
]

//===============================
// Echarts
//===============================
export const getPosition = (pos, params, dom, rect, size) => ({
    top: pos[1] - size.contentSize[1] - 10,
    left: pos[0] - size.contentSize[0] / 2
});




