import React from 'react';

const CustomCircleIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="#e89f31" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" r="11" stroke="#e86531" strokeWidth="2" />
      <circle cx="12" cy="12" r="7" fill="#e86531" />
    </svg>
  );
};

export default CustomCircleIcon;