import { actionTypes } from '../constants/action-types'

export const setSummary = (payload) => {

    return {
        type: actionTypes.SUMMARY,
        payload: payload
    }
}

export const setEmployeeSummary = (payload) => {

    return {
        type: actionTypes.EMPLOYEE_SUMMARY,
        payload: payload
    }
}
export const setStoresActivity = (payload) => {

    return {
        type: actionTypes.SET_STORES_ACTIVITY,
        payload: payload
    }
}
export const setChatgptTable = (payload) => {

    return {
        type: actionTypes.SET_CHATGPT_TABLE_QUERIES,
        payload: payload
    }
}

export const setChatpgptQuestion = (payload) => {
    console.log('payloadpayloadpayload', payload);

    return {
        type: actionTypes.SET_CHATGPT_QUESTION,
        payload: payload
    }
}







