import { enums, storage } from '../../helpers/common';
import { menu } from '../../helpers/meta';
import { actionTypes } from '../constants/action-types';
import { dataPoints } from '../../helpers/meta';


const defaultUser = { login: false, token: "" };
var user = storage.getParsed(enums.USER, defaultUser);


const userMenu = (user) => {
    if (!user.login) {
        return [];
    }
    let menu = [];
    let _userMenu = user?.client_id[0]?.menu;
    if (_userMenu?.length) {
        _userMenu.map((item) => {
            menu.push(item)
        })
    }

    return menu
}
const userFeatures = (user) => {
    if (!user.login) {
        return { footfall: [], sales: [], employee : [] };
    } else {
        let _footfall = [];
        let _feature = user?.client_id[0]?.feature;
        if (_feature && _feature.footfall && _feature.footfall.length) {
            _feature.footfall.map((item) => {
                item.children.map((footfall) => {
                    let obj = {
                        label: footfall.label,
                        value: footfall.key
                    }
                    _footfall.push(obj)
                })
            })
        }
        var sales = [];
        if (_feature && _feature.sales) {


            if (Object.keys(_feature?.sales ? _feature?.sales : {})?.length > 0) {
                sales = (Object.keys(_feature?.sales)?.map(key => {
                    return { value: key, label: _feature?.sales[key] }
                }))
            }
        }

        var employee = [];
        if (_feature && _feature.employee) {


            if (Object.keys(_feature?.employee ? _feature?.employee : {})?.length > 0) {
                employee = (Object.keys(_feature?.employee)?.map(key => {
                    return { value: key, label: _feature?.employee[key] }
                }))
            }
        }

        return { footfall: _footfall, sales: sales, employee : employee };
    }
}
const startingData = [{}];
Object.keys(dataPoints).forEach((key) => {
    startingData[0][key] = 0;
})




const initialState = {
    user: user,
    clients: user.clients,
    access: user.access,
    menu: userMenu(user),
    features: userFeatures(user),
};

const forgotEmailStatus = null;

export const auth = (state = initialState, { type, payload = {} }) => {
    switch (type) {
        case actionTypes.LOGIN:
            let user = payload;
            user.login = true;
            storage.set(enums.USER, user);
            return { ...state, user: user, clients: user.clients, access: user.access, features: userFeatures(user), menu: userMenu(user), };
        case actionTypes.LOGOUT:
            return { ...state, user: defaultUser };
        default:
            return state;
    }
}

export const forgotEmailStatusReducer = (state = forgotEmailStatus, { type, payload = {} }) => {
    switch (type) {
        case actionTypes.FORGOT_EMAIL_FIND:
            return { forgotEmailStatus: payload }
        default:
            return { state }
    }
}