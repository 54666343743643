import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardBody, Col, ListGroup, ListGroupItem, Row } from 'reactstrap';
import MainFilter from '../components/filters/MainFilter'
import ProgressiveImg from '../components/ProgressiveImg';
import { heatmapSummaryRequest, spacesRequest, user } from '../helpers/requests';
import { Page, Text, View, Document, StyleSheet, PDFDownloadLink, Image } from '@react-pdf/renderer';
import altImage from "../assets/img/alt.jpg"
import moment from 'moment';
import { logo } from '../helpers/meta';
import { styles } from '../helpers/utils';






export default function HeatmapSummary() {

    const dispatch = useDispatch();
    const [filters, setFilters] = useState();
    const summary = useSelector((state) => state.heatmapReducer.heatmapSummary)
    let initial = [{ _id: 'viewAll', label: "View All" }];
    const [spaces, setSpace] = useState(
        [...initial, ...user.access.map((item) => { return item })]
    );

    const [currentStore, setCurrentStore] = useState(spaces[0])
    const [currentStoreData, setCurrentStoreData] = useState([]);
    const [isImageLoaded, setIsImageLoaded] = useState(false);


    const updateFilter = (e) => {
        setFilters(e)
    }


    useEffect(() => {

        if (typeof filters !== undefined && filters) {
            let obj = {
                ...filters,
                client_id: user.client_id[0]._id,
                dispatch
            }
            heatmapSummaryRequest(obj);
        }

    }, [filters])





    useEffect(() => {
        if (typeof summary !== undefined && summary && summary.length > 0) {

            let _summary = JSON.stringify(summary);
            if (typeof currentStore !== undefined && currentStore) {
                if (currentStore._id === "viewAll") {
                    setCurrentStoreData(summary)

                }
                else {
                    let _currentStoreData = JSON.parse(_summary).filter((item) => {
                        return item._id.sid === currentStore._id
                    })
                    setCurrentStoreData(_currentStoreData);

                }

            }

        }

    }, [summary, currentStore])








    const Quixote = () => {
        return (
            <Document>

                <Page style={styles.body}>
                    <View style={{ backgroundColor: "", height: "45px" }}>


                        <button
                            style={[styles.descriptionPage, { position: "absolute", left: "10.4%", right: "74.94%", top: `5%`, backgroundColor: "#E88131", color: "#fff", width: "125px", paddingLeft: "3px" }]}
                        >
                            <Text style={{ color: "#fff", padding: "3px", fontWeight: 700, fontFamily: "Helvetica", fontSize: "24px", fontStyle: "bold", fontWeight: "bold" }}>
                                Confidential
                            </Text>
                        </button>
                    </View>
                    <View style={[styles.descriptionPage, { height: "45px", position: "absolute", right: "3%", left: "66%", top: `5%`, width: "200px", paddingLeft: "3px", backgroundColor: "", height: "65px" }]}
                    >
                        <Image
                            style={{ width: "100%" }}
                            src={user.client_id[0].logo}
                        >

                        </Image>
                    </View>

                    <View
                        style={{ width: "320px", height: "265px", right: "39.38%", left: "10.62%", top: `10%`, backgroundColor: "" }}
                    >

                        <Text style={{ fontSize: "80px", fontWeight: 700, fontFamily: "Helvetica", letterSpacing: "-1px", color: "#232340", position: "absolute" }}>
                            Daily Heatmap Report
                        </Text>
                    </View>

                    <View
                        style={{ width: "320px", height: "100px", right: "39.38%", left: "17.3%", top: `65%`, position: 'absolute', backgroundColor: "" }}
                    >
                        <Text style={{ textAlign: "", marginTop: '', fontSize: '30px', fontStyle: "", color: "#232340", }}>
                            {moment(new Date(filters?.start)).format("DD MMM YYYY")}
                            <br></br>
                        </Text>

                        <Text style={{ fontSize: "12px", marginTop: "5px", marginLeft: "2px", fontWeight: 400, fontFamily: "Helvetica", letterSpacing: "", color: "#999999", }}>
                            Generated at {moment(new Date()).format("DD MMM YYYY   hh:mmA")}
                        </Text>
                    </View>

                    <View
                        style={{ width: "160px", height: "60px", right: "39.38%", left: "17.3%", bottom: `3%`, position: 'absolute', backgroundColor: "" }}
                    >
                        <Text style={{ marginTop: "13px", fontSize: "12px", fontWeight: 400, color: "#999999", }}>
                            @ {moment(new Date()).format("YYYY")} Powered By <span ><Text style={{ marginLeft: "2px", color: "#232340", fontWeight: 700 }}>Adlytic Ai</Text></span>
                        </Text>

                    </View>
                    <View
                        style={{ width: "160px", height: "60px", left: "75.38%", right: "5%", bottom: `3%`, position: 'absolute', backgroundColor: "" }}
                    >
                        <Image

                            style={{ width: "100%" }}
                            src={logo}
                        >
                        </Image>


                    </View>





                </Page>

                {currentStoreData.length > 0 && currentStoreData.map((store, ind) => {
                    return (
                        <>
                            <Page style={styles.body} wrap={false}>

                                <Text style={styles.header} fixed>
                                    ~ Heatmap Reports ~
                                </Text>
                                <Text style={styles.title}>{store._id.label}</Text>
                                {store.images.map((floor, index) => {
                                    return (
                                        <>
                                            <Text style={styles.author}>{floor.label}</Text>
                                            <Image
                                                style={styles.image}
                                                src={floor.url}
                                            />
                                        </>
                                    )
                                })}
                            </Page>

                        </>

                    )
                })
                }
            </Document>

        )
    }






    return (
        <div>
            <MainFilter
                pageTitle="Heatmap Summary"
                type="calendar"
                singleDate={true}
                updateFilter={(e) => updateFilter(e)}

            />


            <Row>
                <Col>
                    <Card style={{ float: "right" }}>

                        <PDFDownloadLink document={<Quixote />} fileName={`${currentStore.label}.pdf`} className='btn btn-primary'>
                            {({ blob, url, loading, error }) =>
                                loading ? 'Loading document...' : 'Download now!'
                            }
                        </PDFDownloadLink>

                    </Card>
                </Col>
            </Row>

            <Row>
                <Col>
                    {
                        <>

                            <Card className="my-2" style={{ minHeight: '6.75rem', maxheight: "20rem", borderRadius: 'none', }}>

                                <CardBody>
                                    <Row>
                                        <Col sm={2} md={2} className='border-right-1'>
                                            <h4 className='filter-heading'>Stores</h4>
                                            <ListGroup flush>
                                                {
                                                    spaces.length > 0 && spaces.map((store, index) => {
                                                        return (
                                                            <ListGroupItem tag="li" onClick={() => setCurrentStore(store)} key={"selected-data-point-" + index} className={'w-100 data-point-card pointer ' + (currentStore._id == store._id ? 'active' : '')}>
                                                                {store.label}
                                                            </ListGroupItem>
                                                        )
                                                    })
                                                }
                                            </ListGroup>
                                        </Col>
                                        <Col md={10} sm={12}>
                                            {
                                                !currentStoreData &&
                                                <div className="container h-100">
                                                    <div className="row align-items-center h-100">
                                                        <div className="col-6 mx-auto">
                                                            <div className="jumbotron">
                                                                {<span className="d-block small opacity-30 text-center align-middle "><i>Data Fetching...</i></span>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>}


                                            {
                                                currentStoreData.length > 0 &&
                                                <>


                                                    <div className='scrollbar' id="style-3" style={{ width: "100%", height: "50rem" }}>


                                                        {currentStoreData.map((space, index) => {
                                                            return (
                                                                <div key={index}>
                                                                    <h4 className='m-0 p-0 fw-normal  center'> {space._id.label} </h4>
                                                                    {space.images.map((floor, index) => {
                                                                        return (
                                                                            <div className='mb-3 text-center' key={index}>
                                                                                <h6 className="m-0 p-0 mb-2 fw-normal">{floor.label}</h6>
                                                                                <ProgressiveImg

                                                                                    src={floor.url}
                                                                                    placeholderSrc={altImage}


                                                                                />
                                                                            </div>
                                                                        )
                                                                    })}
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                </>
                                            }
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>

                        </>
                    }
                </Col>

            </Row>





        </div >
    )
}
