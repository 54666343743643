import React, { useState, useEffect } from 'react';
import ReactECharts from 'echarts-for-react';
import { useSelector } from 'react-redux';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import Select from 'react-select';
import { colors, dataPoints, graphColors } from '../../helpers/meta';

const getScatterOptions = (markLineData, markAreaLabel, seriesData) => {
  return {
    title: {
      text: '',
      subtext: ''
    },
    grid: {
      left: '3%',
      right: '3%',
      bottom: '7%',
      containLabel: true
    },
    color: colors.dark,
    tooltip: {
      // trigger: 'axis',
      showDelay: 0,
      formatter: function (params) {
        if(params.componentType == "series"){
          return (
            '<b>'+params.value[2]+"</b>" +
            '<br/><br/>'+params.value[3]+': ' +
            params.value[0] +
            '<br/>'+params.value[4]+': ' +
            params.value[1] +
            '<br/>'
          );
        }else{
          return (
            '<b>'+params.name+": </b>" + params.value
          );
        }
      },
      axisPointer: {
        show: true,
        type: 'cross',
        lineStyle: {
          type: 'dashed',
          width: 1
        }
      }
    },
    xAxis: [
      {
        type: 'value',
        scale: true,
        axisLabel: {
          formatter: '{value}'
        },
        splitLine: {
          show: false
        }
      }
    ],
    yAxis: [
      {
        type: 'value',
        scale: true,
        axisLabel: {
          formatter: '{value}'
        },
        splitLine: {
          show: false
        }
      }
    ],
    series: [
      {
        name: '',
        type: 'scatter',
        emphasis: {
          focus: 'series'
        },
        symbolSize: 20,
        data: seriesData,
        markArea: {
          silent: true,
          itemStyle: {
            color: 'transparent',
            borderWidth: 1,
            borderType: 'dashed'
          },
          data: [
            [
              {
                xAxis: 'min',
                yAxis: 'min'
              },
              {
                xAxis: 'max',
                yAxis: 'max'
              }
            ]
          ]
        },
        markPoint: {
          data: [
            { type: 'max', name: 'Max' },
            { type: 'min', name: 'Min' }
          ]
        },
        markLine: {
          lineStyle: {
            type: 'solid'
          },
          data: markLineData
        }
      }
    ]
  };
}
const Scatter = ({dataOptions, summary}) => {
  const dataPoints = {}; 
  dataOptions.forEach(val => {
    dataPoints[val.value] = val.label;
});
  const user = useSelector(state => state.auth.user);
  const options = Object.keys(dataPoints).map((key)=>{
      return {label: dataPoints[key], value: key}
  })

  console.log('options',options);
  const [xAxis, setXAxis] = useState(options[0]);
  const [yAxis, setYAxis] = useState( options.length > 1 ?  options[1] : options[0]);
  console.log('xAxis',xAxis,yAxis);
  const [option, setOption] = useState(null);
  


  const loadingOption = {
    text: 'Loading...',
    color: '#4413c2',
    textColor: '#270240',
    maskColor: 'rgba(255, 255, 255, 0.3)',
    zlevel: 0
  };

  const onChartReady = (echarts) => {
    echarts.hideLoading();
  }

  useEffect(()=>{
    var current = summary.current;
    var xArr = current.map((row) => row[xAxis.value]);
    var yArr = current.map((row) => row[yAxis?.value]);
    var xAvg = xArr.length ? xArr.reduce((a,b)=> a+b, 0)/xArr.length : 0;
    var yAvg = yArr.length ? yArr.reduce((a,b)=> a+b, 0)/yArr.length : 0;
    var markLineData = [{ type: 'average', name: 'Average '+yAxis.label }, { xAxis: xAvg, name: "Average "+xAxis.label, type: "average"}];
    var markAreaLabel = xAxis.label + ' Vs '+ yAxis.label+' Range';
    var seriesData = current.map((val, index) => {
      return {
        name: val.label,
        value: [xArr[index], yArr[index], val.label, xAxis.label, yAxis.label],
        itemStyle: {
          color: (xArr[index] > xAvg && yArr[index] > yAvg) ? colors.success : (xArr[index] < xAvg && yArr[index] < yAvg) ? colors.danger : (xArr[index] < xAvg && yArr[index] > yAvg) ? colors.primary : colors.secondary
        }
      }
    });
    var _options = getScatterOptions(markLineData, markAreaLabel, seriesData);
    setOption(_options);
  }, [xAxis, yAxis])

  return (
    option !== null &&
    <Card>
      <CardHeader>
        <Row>
          <Col md={2}>
            <label><small>Property on X-Axis</small></label>
            <Select options={options} value={xAxis} onChange={setXAxis}/>
          </Col>
          <Col md={2}>
            <label><small>Property on Y-Axis</small></label>
            <Select options={options} value={yAxis} onChange={setYAxis}/>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <ReactECharts
          option={option}
          style={{ height: 500 }}
          onChartReady={onChartReady}
          loadingOption={loadingOption}
          showLoading={false}
        />
      </CardBody>
    </Card>
  );
}
export default Scatter;