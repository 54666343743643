import React from 'react'
import "../MainFilter.css"
import "../../../App.css"
import { Button, ListGroupItem } from 'reactstrap';
import { Edit2, Trash2 } from 'react-feather';
import { useDispatch } from 'react-redux';
import { deleteFilter } from '../../../redux/actions/savedFilters';
import { deleteFilterRequest } from '../../../helpers/requests';

export default function CustomeFilterList({ list, editDateFilter,name,currentDate }) {

    const dispatch = useDispatch();


    const localDeleteFilter = async (id) => {
        let requestData = {
            params: {
                id: id
            },
            dispatch
        };
        await deleteFilterRequest(requestData);
        dispatch(deleteFilter(id));
    }

    return (
        <div>


            {
                list.length > 0 &&
                <ListGroupItem
                    disabled
                    href="#"
                    tag="a"

                >
                    {name} Filters ( {list.length} )
                </ListGroupItem>
            }
            {
                list.map((row, index) => {
                    return (
                        <ListGroupItem key={"delete-branch-filter-" + index} className={row._id === currentDate._id && 'active'}>

                            {row.label} <Button size='sm' color='outline-danger' style={{ float: "right", padding: "2px 5px", }} onClick={() => { localDeleteFilter(row._id) }}><Trash2 size={14} /></Button>
                            <Button size='sm' className='mx-2' color='outline-danger' style={{ float: "right", padding: "2px 5px" }}
                                onClick={() => { editDateFilter(row) }}
                            ><Edit2 size={14} /></Button>
                        </ListGroupItem>
                    );
                })
            }

        </div>
    )
}
