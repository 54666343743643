import React, { Fragment, useEffect, useState } from 'react';

export class StatusChecker extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount = () => {
    }

    componentWillUnmount = () => {
    }

    render() {
        return (
            <Fragment>
                <h1 style={{ textAlign: "center", color: "black" }}>Processing in progress...</h1>
            </Fragment>
        )
    }
}