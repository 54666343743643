import React, { useState } from 'react'
import { Dropdown, DropdownMenu, DropdownToggle, ListGroupItem } from 'reactstrap'
import "../filters/MainFilter.css"
import { BsCircleFill } from 'react-icons/bs';

export default function DateRangeDropdown({ calendar }) {

    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    return (

        <div className='dropdown-container mt-2'>
            <Dropdown isOpen={isOpen} toggle={toggleDropdown} direction='down'>
                <DropdownToggle className="dropdown-button" >
                    <div className="select_Mbl_div">
                        <BsCircleFill size={12} color={"#e86531"} />
                        <div className="about_filter">
                            Date Range
                        </div>
                    </div>
                </DropdownToggle>
                <DropdownMenu style={{ width: "81%", marginLeft: "10px" }} className='mt-2 '>
                    <div className='pt-2'>
                        {calendar}
                    </div>
                </DropdownMenu>
            </Dropdown>


        </div>

    )
}
