import React, { useEffect } from 'react'
import { PaginationProps, Space } from 'antd';
import { Pagination, Table } from 'antd';
import { useState } from 'react';
import Column from 'antd/es/table/Column';

export default function CustomPagination(props) {

    const [_columns, setColumns] = useState(props.columns);
    const [_data, setData] = useState([]);






    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
        },
        {
            title: 'Age',
            dataIndex: 'age',
        },
        {
            title: 'Address',
            dataIndex: 'address',
        },
        {
            title: "Action",
            dataIndex: '',
            key: 'x',
            render: () => <a  >Delete</a>,
        }
    ];


    let data = [];


    for (let i = 0; i < 1200; i++) {
        data.push({
            key: i,
            name: `Edward King ${i}`,
            age: 32,
            address: `London, Park Lane no. ${i}`,
        });
    }

    useEffect(() => {

        if (props.data.length) {
            let _data = props.data.map((item, index) => {

                let obj = { "#": index + 1 }
                Object.keys(item ? item : {}).map((key) => {
                    return (
                        obj[key] = item[key]
                    )


                })

                return (
                    obj
                )
            })
            setData(_data)
        } else {
            setData([])
        }


        if (props.columns.length) {
            let __columns = _columns.map((item, index) => {

                return (
                    <Column key={index} title={item.title} dataIndex={item.dataIndex} key={item.key}></Column>
                )
            })




        }

    }, [props])




    const onShowSizeChange = (current, pageSize) => {
        console.log(current, pageSize);
    };




    return (
        <div className='mobile-table'>
            <Table
                dataSource={_data}
                size={"small"}
                pagination={false}
                loading={props.loader}
            >


                columns={props.columns}

            </Table>



        </div>
    )
}
