import { addUserRequest, spacesRequest, usersRequest, userUpdateRequest, userDeleterequest } from '../helpers/requests';
import { Row, Col, Button, /*Card, CardBody, ButtonGroup,  Progress, Modal, ModalBody, ModalFooter, ModalHeader */ } from 'reactstrap';
import { Input, Label } from 'reactstrap';
import React, { useState, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { MdPowerSettingsNew } from 'react-icons/md'
// import {GoKebabVertical} from 'react-icons/go'
import { Logo } from './Templates/Logo';
import Select from 'react-select';
import '../App.css';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
// import { colors } from '../helpers/meta';
import DeleteModal from '../components/modals/DeleteModal';
// import Messages from '../components/Messages';
import LettersProfile from '../components/Avatar';
import ChangePassword from '../components/modals/ChangePassword';
// import { ArrowDown, ChevronDown } from 'react-feather';
import { FolderPlus, MoreVertical } from 'react-feather';
import { enums, storage } from "../helpers/common";
import { checkString, toLowerCase, emailValidation } from '../helpers/utils';



const Users = () => {


    const dispatch = useDispatch();
    const clientsUser = useSelector((state) => state.clientsUser.users);
    const [currentUser, setCurrentUser] = useState();
    const newUser = useSelector((state) => state.clientsUser.user)
    const highlights = useSelector(state => state.highlights);
    const [existingUser, setExistingUser] = useState()
    const spaces = useSelector((state) => state.spaces.spaces)
    const image = useSelector((state) => state.logo.logoUrl);
    const [modal, setModal] = useState(false)
    const [currentPassword, setCurrentPassword] = useState();
    const [paswdModal, setPaswdModal] = useState(false)
    const [currentIndex, setCurrentIndex] = useState(null)
    const [storeToAssign, setStoreToAssign] = useState([])
    const [errorMessage, setError] = useState('')
    const [updateUserId, setUpdateUserId] = useState()
    const [disabled, setisDisabled] = useState(true);
    const [userImage, setUserImage] = useState();
    const [stores, setStores] = useState([]);
    const [status, setStatus] = useState()
    const user = storage.getParsed('user');
    const [role, setRole] = useState(null)
    const [userData, setUserData] = useState({
        email: '',
        password: '',
        name: '',
    })
    const { email, password, name } = userData;
    const roles = [
        { label: 'Manger', value: 'client', name: 'role' },
        { label: 'Admin', value: 'admin', name: "role" },
    ]

    const optionForStatus = [{ label: 'Inactive', value: 0 }, { label: 'Active', value: 1 }]


    const formSubmit = () => {

        let storeIds = storeToAssign.map(e => e.value);
        let client_id = user?.client_id[0]._id;
        if (currentIndex === null && typeof status !== 'undefined' && status) {
            let _email = emailValidation(email);
            if (_email) {
                addUserRequest({ dispatch, name, email, password, role: role?.value, status: status?.value, access: storeIds, client_id: [client_id], image: userImage });
                setError("")
                setUserData({ name: '', email: '', password: '' })
                setStoreToAssign('')
                setRole('')
                setStatus('')
            }
            else {
                setError("Email is not valid")
            }

        }
        else {
            if (typeof status !== 'undefined' && status) {
                let _email = emailValidation(email);
                if (_email) {
                    userUpdateRequest({ dispatch, _id: updateUserId, name, email, password, role: role.value, status: status.value, access: storeIds, client_id: [client_id], image: userImage })
                    setError("")
                    setUserData({ name: '', email: '', password: '' });
                    setStoreToAssign('')
                    setRole('')
                    setStatus('')
                }
                else {
                    setError("Email is not valid")
                }
            }
        }
    }


    const onInputChange = (e, item) => {
        if (item.target.name === 'email') {
            setUserData({ ...userData, [item.target?.name]: e })
        }
        else {
            setUserData({ ...userData, [e.target?.name]: e.target?.value })
        }
    }


    const onSelectChange = (e) => {

        let _selectAll = e.find((item) => item.value === "all");
        if (typeof _selectAll !== undefined && _selectAll) {
            let filter = stores.filter((item) => item.value != "all");
            setStoreToAssign(filter)
        }
        else {
            setStoreToAssign(e)
        }
    }


    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            background: state.isSelected ? "#fd7e14" : '',
            boxShadow: "0px 4px 37px 9px rgb(0 0 0 / 3%)",
            borderRadius: "0px",
        }),
        control: (provided) => ({
            ...provided,
            height: "40px",
            background: "#FFFFFF",
            boxShadow: "0px 4px 37px 9px rgb(0 0 0 / 3%)",
            borderRadius: "4px",
            border: "1px solid  #ced4da",
        }),
        menu: (provided) => ({
            ...provided,
            zIndex: 10
        })
    }


    const editUser = (id) => {
        var user;
        if (typeof existingUser !== 'undefined' && existingUser) {
            user = existingUser.filter((item) => {
                return item._id === id
            })
        }
        setUpdateUserId(id);
        let _user = user[0];
        setCurrentUser(_user)
        let _access = _user.access;
        setCurrentPassword(_user.password_hash);
        let stores = spaces.filter((item) => (_access.includes(item._id)))
        stores = stores.map(val => ({ ...val, value: val._id }));

        let _status = _user.status;
        _status = { ..._status, value: _status, label: _status === 0 ? 'InActive' : 'Active' }

        let _role = { value: _user.role, label: _user.role === 'client' ? 'Client' : _user.role === 'admin' ? 'Admin' : 'Super Admin' }

        if (user.length) {
            setUserData({
                email: _user.email,
                password: _user.password,
                name: _user.name
            })
            setRole(_role);
            setStoreToAssign(stores);
            setStatus(_status)
            setUserImage(_user.image)

        }
    }


    const deleteUser = () => {
        userDeleterequest({
            dispatch: dispatch,
            _id: updateUserId,
            client_id: user?.client_id[0]._id
        });
        setCurrentIndex(null)
    }



    useEffect(() => {
        usersRequest({ dispatch, client_id: user?.client_id[0]?._id });
        spacesRequest({ dispatch, client_id: user?.client_id[0]?._id })
    }, [1])



    useEffect(() => {
        if (currentIndex === null) {
            setUserData({
                email: '',
                password: '',
                name: '',
            })
            setStoreToAssign([]);
            setRole(null);
            setStatus();
            setUserImage();
        }

    }, [currentIndex])


    useEffect(() => {
        if (spaces) {
            let _spaces = [{ label: 'Select All', value: 'all' }];
            spaces.map((item) => {
                let index = { label: item.label, value: item._id }
                _spaces.push(index)
            })
            setStores(_spaces)
        }
    }, [spaces])


    useEffect(() => {
        if (image) {
            setUserImage(image)
        }
    }, [image])


    useEffect(() => {
        if (currentIndex === null) {
            setisDisabled(!name || !role || !password || !email || !storeToAssign.length)
        }
        if (currentIndex != null) {

            setisDisabled(!name || !role || !email || !storeToAssign.length)
        }
        setError('')
    }, [name, role, password, status, email, storeToAssign, currentIndex])


    useEffect(() => {
        if (highlights.isSuccess) {
            setModal(false)
        }
        // if(!highlights.loading) {
        //     setUserData({})
        // }
    }, [highlights])


    useEffect(() => {
        if (newUser) {
            if (typeof existingUser !== 'undefined' && existingUser) {
                const updatedObject = existingUser.map((item) => (item._id === newUser._id ? newUser : item))
                setExistingUser(updatedObject)
            }
        }
    }, [newUser])

    useEffect(() => {

        if (clientsUser) {
            setExistingUser(clientsUser)
        }
    }, [clientsUser])

    const [dropdownOpen, setDropdownOpen] = useState(false)
    const toggle1 = () => {
        setDropdownOpen(!dropdownOpen);
    }

    const checkEmail = (value) => {
        let x = /^([A-Z ]+|\d+)$/;;
        if (value.target.value.match(x)) {

        }
        else {
            return value
        }
    }






    return (
        <Fragment>
            <Row>
                <Col md={3}>
                    <h4 className='px-3 '>Accounts</h4>
                    <Row>
                        <Col className='px-3 pt-3'>
                            <div className='list-group list-group-checkable m-1' onClick={() => setCurrentIndex(null)}>
                                <label className={currentIndex === null ? "list-group-item py-3 activeUser" : "list-group-item py-3"} >
                                    <FolderPlus size={27} />
                                    <span className='px-3'>Create Account</span>
                                </label>
                            </div>
                            <div className='scrollbar ' id="style-3" style={{ width: "100%", height : '35rem' }}>

                                {existingUser?.map((item, index) => (
                                    <div className='list-group list-group-checkable m-1' key={index} onClick={() => setCurrentIndex(index)} >
                                        <label className={currentIndex === index ? "list-group-item py-3 activeUser userHover" : " list-group-item py-3 userHover"} style={{ cursor: "pointer" }} onClick={() => editUser(item._id)}>
                                            <Row>
                                                <Col md={2}>
                                                    {/* {item.image &&
                                                    <img src={item.image} style={{ height: '40px', width: '40px', borderRadius: '50%', overflow: 'hidden', backgroundColor: 'white' }} />
                                                } */}
                                                    {
                                                        // !item.image &&
                                                        <LettersProfile name={item.name} />
                                                    }
                                                </Col>
                                                <Col className='px-3'>
                                                    {item.name}
                                                    <span className="d-block small opacity-50" >{item.role}</span>
                                                </Col>
                                            </Row>
                                        </label>
                                    </div>
                                ))}

                            </div>

                        </Col>
                    </Row>
                </Col>
                <Col md={9} className='py-3 column '  >
                    <Row className='p-3'>
                        {currentIndex === null &&
                            <Col >
                                <h4>Create Account</h4>
                                <span className="d-block small opacity-50" >Fill in the details below to create new account and its roles</span>
                            </Col>
                        }
                        {currentIndex != null &&
                            <>
                                <Col md={4} >
                                    <h4>{userData?.name}</h4>
                                    <span className="d-block small opacity-50" >Update user</span>
                                </Col>
                                <Col md={8} >
                                    {/* <MdPowerSettingsNew onClick={() => setPaswdModal(true)} color='#fd7e14' size='27px' style={{ cursor: 'pointer' }} /> */}
                                    <ChangePassword modal={paswdModal} setModal={(oytput) => setPaswdModal(oytput)} id={updateUserId} currentPassword={currentPassword} />
                                    <div style={{ float: 'right' }}>
                                        <Dropdown isOpen={dropdownOpen} toggle={toggle1}>
                                            <DropdownToggle nav>
                                                <img height="20px" /> <MoreVertical size='20px' fontWeight={"bold"} />
                                            </DropdownToggle>
                                            <DropdownMenu >
                                                <DropdownItem onClick={() => setPaswdModal(true)}>Change Password</DropdownItem>
                                                <DropdownItem onClick={() => setModal(true)}>Delete</DropdownItem>
                                            </DropdownMenu>
                                        </Dropdown>
                                        {/* <Button color='' block className="mt-3 primary" onClick={() => setPaswdModal(true)} >Change Password</Button> */}
                                    </div>
                                </Col>
                            </>
                        }
                    </Row>
                    <Row className='pt-3'>
                        <Col md={8}  >
                            <>
                                <Row className='p-3' >
                                    <Col md={6}  >
                                        <Label>Name</Label>
                                        <Input
                                            onChange={(e) => onInputChange(checkString(e, "name"), e)}
                                            value={name}
                                            name='name'
                                        />
                                        {/* <Input name='name' type="String" value={name} onChange={e => onInputChange(e)} /> */}
                                    </Col>
                                    <Col md={6}>
                                        <Label>Role</Label>
                                        <Select options={roles} value={role} onChange={e => setRole(e)} styles={customStyles} />
                                    </Col>
                                </Row>
                                <Row className='p-3'>
                                    <Col md={6}>
                                        <Label>Email</Label>
                                        <Input name='email' type="String" value={email} onChange={e => onInputChange(toLowerCase(e), e)} />
                                        <span className="d-block danger small opacity-50" >{errorMessage}</span>
                                    </Col>
                                    {currentIndex === null &&
                                        <Col md={6}>
                                            <Label>Password</Label>
                                            <Input name='password' type="String" value={password} onChange={e => onInputChange(e, e)} />
                                        </Col>
                                    }
                                    {currentIndex != null &&
                                        <Col md={6}>
                                            <Label>Status</Label>
                                            <Select options={optionForStatus} value={status} onChange={e => setStatus(e)} styles={customStyles} />
                                        </Col>}
                                </Row>
                                <Row className='p-3'>
                                    <Col md={12}>
                                        <Label>Store</Label>
                                        <Select
                                            options={stores}
                                            isMulti={true}
                                            value={storeToAssign}
                                            onChange={e => onSelectChange(e)}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                        />
                                    </Col>
                                </Row>
                                {currentIndex === null &&
                                    <Row className='p-3'>
                                        <Col md={6}>
                                            <Label>Status</Label>
                                            <Select options={optionForStatus} value={status} onChange={e => setStatus(e)} styles={customStyles} />
                                        </Col>
                                    </Row>}
                            </>
                        </Col>
                    </Row>
                    <Row className='p-3'>
                        <DeleteModal modal={modal} setModal={(output) => setModal(output)} delete={() => deleteUser()} />
                        <Col md={3} >
                            <Button color="" block className="mt-3 btn btn-primary" onClick={formSubmit} disabled={disabled || (user.role === "admin" && currentUser?.role === 'superAdmin')}>{currentIndex === null ? 'Add' : 'Save'}</Button>

                        </Col>
                        {user.role === "admin" && currentUser?.role === 'superAdmin' && <span className="d-block small opacity-50">You don't have authority</span>}

                    </Row>
                </Col>
            </Row >
        </Fragment >
    )
}
export default Users;
