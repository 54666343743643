import React, { Fragment, useEffect, useState } from 'react';
import ReactECharts from 'echarts-for-react';
import echarts from 'echarts/lib/echarts';
import 'echarts/lib/chart/bar';
import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/legend';

const getOption = ({ legends, labels, data }) => {

    return {
        title: {
            text: ''
        },
        tooltip: {
            trigger: 'axis',
            confine: true

        },
        toolbox: {
            show: true,
            feature: {
                saveAsImage: { show: true }
            }
        },
        legend: {
            data: data.map((item) => { return item.name })
        },
        grid: {
            left: '1%',
            right: '2%',
            bottom: '3%',
            containLabel: true
        },
        xAxis: {
            type: 'category',
            boundaryGap: true,
            data: labels,
            axisLine: { onZero: false},
            axisTick: { alignWithLabel: true , interval : 'auto'},
            
            // axisLabel : {
            //     margin : -5
            // }
        },
        yAxis: {
            type: 'value',
            axisLabel: {
                margin: 60,
            }
        },
        series: data


    };
}
const LineCore = (props) => {


    const [options, setOptions] = useState(null);
    const onChartReady = (echarts) => {
        echarts.hideLoading();
    }

    useEffect(() => {
        setOptions(getOption({ legends: props.legend, labels: props.labels, data: props.data }));
    }, [props])

    return (
        <Fragment>
            {options != undefined && options !== null && options &&
                <ReactECharts
                    echarts={echarts}
                    option={options}
                    style={{ height: 300 }}
                    onChartReady={onChartReady}
                    notMerge={true}

                />
            }
        </Fragment>
    )
}

export default LineCore;