import React, { useEffect, useRef, useState } from 'react';
import { ChevronDown } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Row, Col, Button, UncontrolledDropdown } from 'reactstrap';
import { enums } from '../helpers/common';
import { baseUrl, colors } from '../helpers/meta';
import { Link } from 'react-router-dom';
import { setLogout } from '../redux/actions/auth';
import Profile from '../pages/Profile';
import ChangePassword from './modals/ChangePassword';
import useTabActive from './Hooks/useTabActive';
import { userStatRequest } from '../helpers/requests';

const ProfileMenu = (props) => {
  const user = useSelector(state => state.auth.user);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef()

  const active = useTabActive();
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false)
  const [state, setState] = useState(false)
  const toggle = () => {
    setDropdownOpen(!dropdownOpen);
  }

  const logout = () => {
    dispatch(setLogout(false))
    let obj = {
      user_id: user._id,
      client_id: user.access[0].client_id,
      type: 2
    }
    userStatRequest(obj)
  }

  useEffect(() => {
    if (baseUrl === "https://api.adlytic.ai/") {
      if (user !== undefined && user && "access" in user && user.access.length) {
        if (active) {

          let obj = {
            user_id: user._id,
            client_id: user.access[0].client_id,
            type: 1
          }
          userStatRequest(obj)
        }
        else {
          let obj = {
            user_id: user._id,
            client_id: user.access[0].client_id,
            type: 2
          }
          userStatRequest(obj)
        }
      }

    }
  }, [active])

  useEffect(() => {
    const handleClickOutside = event => {
      const isDropdownClick =
        dropdownRef.current && dropdownRef.current.contains(event.target);
      //   const isButtonClick =
      //     buttonRef.current && buttonRef.current.contains(event.target);

      if (isDropdownClick) {
        // If the ref is not defined or the user clicked on the menu, we don’t do anything.
        return;
      }
      // Otherwise we close the menu.
      console.log('isDropdownClick', isDropdownClick);
      setDropdownOpen(false)

    };

    document.addEventListener("mousedown", handleClickOutside); // handle desktops
    document.addEventListener("touchstart", handleClickOutside); // handle touch devices

    // Event cleanup
    return () => {
      document.removeEventListener("mousedown", handleClickOutside); // handle desktops
      document.removeEventListener("touchstart", handleClickOutside); // handle touch devices
    };
  }, [dropdownRef]);

  console.log('userrrr', user);




  return (
    <>
      <Row  style={{marginRight : "0.5rem", marginLeft : "0.2rem" ,marginBottom : "0.5rem"}}  >
        <Col md={6}>
          {
            !props.logo && !props.logo.length > 0 &&
            <span style={{ color: colors.dark, whiteSpace: 'nowrap' }}>{props.title}</span>
          }
          {
            props.logo && props.logo.length > 0 &&
            <img src={props.logo} height="20px" />
          }
        </Col>

      </Row>




      <Dropdown
        id="profile-menu"
        isOpen={dropdownOpen}
        style={{ border: `1px solid var(--silver)`, borderRadius: "20px", backgroundColor: '', whiteSpace: 'nowrap' }}
      >
        <div ref={dropdownRef}>
          <DropdownToggle
            onClick={toggle}
            nav
          >
            <>
              <span style={{ color: colors.dark, whiteSpace: 'nowrap' }} >{user.name}</span>
              <ChevronDown color={colors.primary} fontWeight={"bold"} />
            </>
          </DropdownToggle>


          <DropdownMenu>
            {(user.role == enums.ROLE_ADMIN || user.role == enums.ROLE_SUPER_ADMIN) &&
              <>
                <Link className='d-inline' to="/users" style={{ textDecoration: 'none' }}><DropdownItem>Create Account</DropdownItem></Link>
                <Link className='d-link' to="/users-activity" style={{ textDecoration: 'none' }}><DropdownItem>Users Activity</DropdownItem></Link>
              </>

            }
            {user !== undefined && user && user.client_id.length > 0 && user.client_id[0]._id !== "6421306bf8cea5eb7545f986" && <Link className='d-link' to="/store-activity" style={{ textDecoration: 'none' }}><DropdownItem>Store Activity</DropdownItem></Link>}
            <Link className='d-link' to="/slot-setting" style={{ textDecoration: 'none' }}><DropdownItem>Setting</DropdownItem></Link>
            <Link className='d-inline' to="/branches" style={{ textDecoration: 'none' }}><DropdownItem>Branches</DropdownItem></Link>
            {(user.role !== enums.ROLE_ADMIN && user.role !== enums.ROLE_SUPER_ADMIN) && <DropdownItem onClick={() => setModal(true)}>Change Password</DropdownItem>}
            <DropdownItem onClick={() => logout()}>Logout</DropdownItem>
          </DropdownMenu>
        </div>


      </Dropdown>


      <ChangePassword modal={modal} setModal={(oytput) => setModal(oytput)} id={user._id} />
    </>
  );
}

export default ProfileMenu;