import React, { Fragment, useEffect, useState } from 'react';
import { Card, CardBody, Col, ListGroup, ListGroupItem, Row } from 'reactstrap';
import ConversionGraph from '../../pages/conversions/ConversionGraph';


/***** Before using this bargraph pass this component four props including sortFlag , if sortFlag is true it will sort current feature like total, male etc in barGraph *****/  

const BarGraph = ({ dataOptions, title, payload ,sortFlag,properties}) => {
    console.log(dataOptions ,"dataOptions  ");
    console.log(title,"title");
    console.log(payload,"payload");
    console.log(properties,"properties");

    

    const [data, setData] = useState([]);
    console.log('dataInConv',data);

    const dataPoints = {};
    dataOptions.forEach(val => {
        dataPoints[val.value === 'unis' ? 'units' : val.value] = val.label;
        // dataPoints[val.value] = val.label;
    });


    const [sortBy, setSortBy] = useState(Object.keys(dataPoints)[0]);
    const selectedArray = [sortBy]
    useEffect(() => {
        if (payload.length > 0 && sortFlag) {
            let _payload = payload.slice().sort((a, b) => a[sortBy] - b[sortBy])
            setData(_payload)
        }
        else if(payload.length > 0) {
            setData(payload)
        } 
        
    }, [sortBy,payload])


    return (
        <Fragment>
            <Card style={{ minHeight: '6.75rem', maxheight: "20rem" }}>
                <CardBody>
                    <Row>
                        <Col sm={2} md={2} className='border-right-1'>
                            <h4 className='filter-heading'>View By</h4>
                            <ListGroup flush>
                                {
                                    Object.keys(dataPoints).map((key, index) => {
                                        return (
                                            <ListGroupItem tag="li" onClick={() => setSortBy(key)} key={"selected-data-point-" + index} className={'w-100 data-point-card pointer ' + (sortBy == key ? 'active' : '')}>
                                                {dataPoints[key]}
                                            </ListGroupItem>
                                        )
                                    })
                                }
                            </ListGroup>
                        </Col>
                        <Col md={10} sm={12}>
                            <Row>
                                {
                                    data.length > 0 &&
                                    <ConversionGraph title={title} payload={data} properties={selectedArray} />
                                }
                            </Row>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </Fragment>
    )
}

export default BarGraph;