import { baseUrl } from "./meta";
import axios from "axios";
import { setError, setLoading, setLocalLoader, setSuccess } from "../redux/actions/highlights";
import { setForgotEmailStatus, setLogin } from "../redux/actions/auth";
import { setHeatmap, setHeatmapLoader, setHeatmapSummary } from '../redux/actions/heatmapActions';
import { setFootfall, setFootfallImpute, setMarketAvgValue, setFootfallGraph, setNewFootfallGraph, setFootfallLoader, setFootfallGraph2 } from '../redux/actions/footfallActions';
import { setCategory, setCategoryGraph, setCategoryLoader, setCategoryEntry } from '../redux/actions/categoryActions';
import { setComparison, setComparisonLoader, setGroupComparison } from '../redux/actions/comparisonActions';
import { enums, storage } from "./common";
import { setChatgptTable, setEmployeeSummary, setStoresActivity, setSummary } from "../redux/actions/summary";
import { createFilter, deleteFilter, listFilter } from "../redux/actions/savedFilters";
import { setClientUsers, setUserActivity } from "../redux/actions/user";
import { setNewUser, setSpace, setSpaces } from "../redux/actions/spaces";
import { setLogoUrl } from "../redux/actions/logo";
import { getSystemFeatures } from "../redux/actions/systemfeatures";
import { setEmploye, setFloor, setFloors } from "../redux/actions/floorAction";
import { setConversionDailyData, setConversionData, setConversionLoader, setConversionTotal } from "../redux/actions/conversionActions";
import { getGraphOfEmployeActivity, getSectionsOfEmploye, setGraphLoader, setTimelineSection, setCustomerTimeline, setTimelineLoader } from "../redux/actions/employeActivity";
import { setEmployeeSaleUnit, setJourney, setSaleItem } from "../redux/actions/categoryAction";
import { setBasicInsights, setBasicTrends, setInsightProgress, setInsights, setPrimaryQuestion, setCustomQuestion, setShowFilters, setInsightPerformance } from "../redux/actions/insights";
import moment from 'moment';
import { NewlineText } from "./utils";



const defaultUser = { login: false, token: "" };
export var user = storage.getParsed(enums.USER, defaultUser);
console.log('var======', user)
const setHighlights = (payload, dispatch, success, error) => {
    if (payload.status == true) {
        dispatch(success(payload));
    } else {
        dispatch(setError(payload.message));
        error(payload.data);
    }
};

export const loginRequest = async (requestData) => {
    var dispatch = requestData.dispatch;
    dispatch(setLoading(true));
    var payload;
    await axios({
        method: 'post',
        url: baseUrl + 'users/authenticate',
        data: requestData.params
    }).then((response) => {
        payload = response.data;
        dispatch(setLoading(false));
        console.log('login payload is before', payload);
        if (payload.status) {
            user = payload.data
            dispatch(setLogin(payload.data));
            dispatch(setError(''));
        }
        else {
            dispatch(setError("email or password incorrect"));
        }
    })

        .catch(function (error) {

            dispatch(setLoading(false));
            if (error.response) {
                payload = error.response.data;
                dispatch(setError("email or password incorrect"));
                console.log(error?.response?.data);
                console.log(error.response.status);
                console.log(error.response.headers);
            } else if (error.request) {
                console.log(error.request);
            } else {
                dispatch(setError('email or password incorrect'));
            }
            console.log(error.config);
        });
    console.log('login payload is', payload);
    return payload;
}

export const updateToken = async (requestData) => {
    requestData.dispatch(setLoading(true));
    var payload;
    await axios({
        method: 'post',
        url: baseUrl + 'users/updateToken',
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + user.token,
        }
    }).then((response) => {
        payload = response.data;
        requestData.dispatch(setLoading(false));
        if (payload.status) {
            user = payload.data
            requestData.dispatch(setLogin(payload.data));
            requestData.dispatch(setError(''));
        }
        else {
            requestData.dispatch(setError(payload.message));
        }
    })
    return payload;
}


export const storesActivityRequest = async (requestData) => {
    var dispatch = requestData.dispatch;
    var response = await axios({
        method: 'POST',
        url: baseUrl + 'spaces/activity',
        data: requestData,
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + user.token,
        }
    });
    let data = response.data;

    dispatch(setStoresActivity(data));
    return response.data;
}



export const storesActivityRequestDev = async (requestData) => {
    var dispatch = requestData.dispatch;
    var response = await axios({
        method: 'POST',
        url: "https://devapi.adlytic.ai/spaces/activity",
        data: requestData,
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + user.token,
        }
    });
    let data = response.data;

    dispatch(setStoresActivity(data));
    return response.data;
}

export const newSummaryRequest = async (requestData) => {
    var dispatch = requestData.dispatch;
    dispatch(setLoading(true));
    var response = await axios({
        method: 'post',
        // for develop url
        // url: baseUrl + 'footfall/newtotalSummaryImpute',
        // for production url
        url: baseUrl + 'footfall/getNewTotalSummarySale',
        data: requestData.params,
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + user.token,
        }
    });
    dispatch(setLoading(false));
    let data = response.data.data;
    data["user"] = user;
    data['stores'] = requestData.params.sid;
    dispatch(setSummary(data));
    return response.data;
}



export const summaryRequest = async (requestData) => {
    var dispatch = requestData.dispatch;
    dispatch(setLoading(true));
    var response = await axios({
        method: 'post',
        // for develop url
        // url: baseUrl + 'footfall/newtotalSummary',
        // for production url
        url: baseUrl + 'footfall/getNewTotalSummarySale',
        data: requestData.params,
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + user.token,
        }
    });
    dispatch(setLoading(false));
    let data = response.data.data;
    data["user"] = user;
    data['stores'] = requestData.params.sid;
    dispatch(setSummary(data));
    return response.data;
}


export const employeeSummaryRequest = async (requestData) => {
    var dispatch = requestData.dispatch;
    dispatch(setLoading(true));
    var response = await axios({
        method: 'post',
        // for develop url
        // url: baseUrl + 'footfall/newtotalSummary',
        // for production url
        url: baseUrl + 'footfall/getEmployeeSummary',
        data: { ...requestData.params, EMP: '1' },
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + user.token,
        }
    });
    dispatch(setLoading(false));
    let data = response.data.data;
    data["user"] = user;
    data['stores'] = requestData.params.sid;
    dispatch(setEmployeeSummary(data));
    return response.data;
}

export const listFilterRequest = async (requestData) => {
    var dispatch = requestData.dispatch;
    dispatch(setLoading(true));
    var response = await axios({
        method: 'get',
        url: baseUrl + 'filter',
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + user.token,
        }
    }).catch(function (error) {
        dispatch(setError(error.message));
    });
    dispatch(setLoading(false));
    dispatch(listFilter(response.data.data));
    return response.data;
}

export const spacesRequest = (requestData) => {

    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', "Authorization": "Bearer " + user.token }
    };

    fetch(`${baseUrl}spaces?client_id=${requestData.client_id}&limit=1000`, requestOptions).then(res => {
        return res.json();
    }).then(data => {
        requestData.dispatch(setSpaces(data.data.docs))
    }).catch(err => {
    });
}

export const logoUrlRequest = (requestData) => {
    const requestOptions = {
        method: 'POST',
        body: requestData.params,
        headers: { "Authorization": "Bearer " + user.token },
    };

    fetch(`${baseUrl}media/client`, requestOptions).then(res => {
        return res.json();
    }).then(data => {
        requestData.dispatch(setLogoUrl(data.data.url))
    }).catch(err => {
        // console.log(err)
    })
}
export const usersRequest = (requestData) => {
    var dispatch = requestData.dispatch;
    dispatch(setLoading(true));
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', "Authorization": "Bearer " + user.token },
    };
    fetch(`${baseUrl}users?page=1&limit=100&client_id=${requestData.client_id}`, requestOptions).then(res => {
        return res.json();
    }).then(data => {
        if (data.status == true) {
            requestData.dispatch(setClientUsers(data.data.docs))
            dispatch(setLoading(false));
        }
    }).catch(err => {
        dispatch(setError(true))
        // console.log(err)
    })
}



export const addUserRequest = (requestData) => {
    var dispatch = requestData.dispatch;
    dispatch(setLoading(true));

    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(requestData),
        headers: { 'Content-Type': 'application/json', "Authorization": "Bearer " + user.token }
    };

    fetch(`${baseUrl}users/register`, requestOptions).then(res => {
        return res.json()
    })
        .then(data => {
            dispatch(setLoading(false));

            dispatch(setSuccess(data.message));
            if (data.status == true) {
                usersRequest(requestData);
            }
        }).catch(err => {
            dispatch(setLoading(false));

            dispatch(setError(true))
            // console.log(err)
        })
}

export const userUpdateRequest = (requestData) => {
    var dispatch = requestData.dispatch;
    dispatch(setLoading(true));

    const requestOptions = {
        method: 'PUT',
        body: JSON.stringify(requestData),
        headers: { 'Content-Type': 'application/json', "Authorization": "Bearer " + user.token },
    };

    fetch(`${baseUrl}users/${requestData._id}`, requestOptions).then(res => {
        return res.json();
    }).then(data => {
        dispatch(setLoading(false));

        dispatch(setSuccess(data.message));
        if (data.status == true) {
            requestData.dispatch(setNewUser(data.data))
        }
    }).catch(err => {
        dispatch(setLoading(false));

        dispatch(setError(true))
        // console.log(err)
    })
};

export const userDeleterequest = (requestData) => {
    var dispatch = requestData.dispatch;
    const requestOptions = {
        method: 'DELETE',
        body: JSON.stringify(requestData),
        headers: { 'Content-Type': 'application/json', "Authorization": "Bearer " + user.token },
    };

    fetch(`${baseUrl}users/${requestData._id}`, requestOptions).then(res => {
        return res.json();
    }).then(data => {
        dispatch(setSuccess(data.message))
        if (data.status === true) {
            usersRequest(requestData);
        }
    }).catch(err => {
        dispatch(setError(err.message))
        // console.log(err)
    })
};


export const changeClientPassword = (requestData) => {
    var dispatch = requestData.dispatch;
    dispatch(setLoading(true));

    const requestOptions = {
        method: 'PUT',
        body: JSON.stringify(requestData),
        headers: { 'Content-Type': 'application/json', "Authorization": "Bearer " + user.token },
    };

    fetch(baseUrl + 'users/adminResetPassword', requestOptions).then(res => {
        return res.json();
    }).then(data => {
        dispatch(setSuccess(data.message))
        dispatch(setLoading(false));
        if (data.status) {

            dispatch(setSuccess(data.message))
        }
    }).catch(err => {
        dispatch(setLoading(false));

        dispatch(setError(err.message))
        // console.log(err)
    })
}

export const updateUserpassword = (requestData) => {
    var dispatch = requestData.dispatch;
    dispatch(setLoading(true));

    const requestOptions = {
        method: 'PUT',
        body: JSON.stringify(requestData),
        headers: { 'Content-Type': 'application/json', "Authorization": "Bearer " + user.token },
    };

    fetch(baseUrl + 'users/updatePassword', requestOptions).then(res => {
        return res.json();
    }).then(data => {
        dispatch(setSuccess(data.message))
        dispatch(setLoading(false));
        if (data.status) {

            dispatch(setSuccess(data.message))
        }
    }).catch(err => {
        dispatch(setLoading(false));

        dispatch(setError(err.message))
        // console.log(err)
    })
}

export const updateFilterRequest = async (requestData) => {
    var dispatch = requestData.dispatch;
    dispatch(setLoading(true));
    var response = await axios({
        method: 'PUT',
        url: baseUrl + `filter/${requestData.params._id}`,
        data: requestData.params,
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + user.token,
        }
    }).catch(function (error) {
        console.log(error.message);
        dispatch(setError(error.message));
    });
    dispatch(setLoading(false));

    listFilterRequest({ dispatch: requestData.dispatch })
    return response.data;
};


export const createFilterRequest = async (requestData) => {
    var dispatch = requestData.dispatch;
    dispatch(setLoading(true));
    var response = await axios({
        method: 'post',
        url: baseUrl + 'filter',
        data: requestData.params,
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + user.token,
        }
    }).catch(function (error) {
        console.log(error.message);
        dispatch(setError(error.message));
    });
    dispatch(setLoading(false));
    dispatch(createFilter(response.data));
    return response.data;
};

export const deleteFilterRequest = async (requestData) => {
    var dispatch = requestData.dispatch;
    dispatch(setLoading(true));
    var response = await axios({
        method: 'delete',
        url: baseUrl + 'filter/' + requestData.params.id,
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + user.token,
        }
    });
    dispatch(setLoading(false));
    return response.data;
};

export const systemFeaturesRequest = (requestData) => {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', "Authorization": "Bearer " + user.token },
    };

    fetch(`${baseUrl}feature/?page=1&limit=1000`, requestOptions).then(res => {
        return res.json()
    }).then(data => {
        if (data.status) {
            requestData.dispatch(getSystemFeatures(data.data.docs))
        }
    })
}

export const passwordForgetRequest = (requestData) => {
    var dispatch = requestData.dispatch;
    dispatch(setLoading(true));
    const requestOptions = {
        method: 'POSt',
        body: JSON.stringify({ email: requestData.email }),
        headers: { 'Content-Type': 'application/json', "Authorization": "Bearer " + user.token },
    };

    fetch(`${baseUrl}users/forgot`, requestOptions).then((res) => {
        if (res.status === 200) {
            dispatch(setLoading(false));
            requestData.dispatch(setForgotEmailStatus(res));
        }
        return res.json()
    }).then(data => {
        dispatch(setLoading(false));
        if (!data.status) {
            requestData.dispatch(setForgotEmailStatus(data));
        }
    }).catch(err => {
        // console.log(err);
    })
}

export const resetPasswordRequest = (requestData) => {
    var dispatch = requestData.dispatch;
    dispatch(setLoading(true));
    var dispatch = requestData.dispatch;
    const requestOptions = {
        method: 'PUT',
        body: JSON.stringify(requestData),
        headers: { 'Content-Type': 'application/json', "Authorization": "Bearer " + requestData.token },
    };

    fetch(baseUrl + 'users/resetPassword', requestOptions).then(res => {
        return res.json();

    }).then(data => {
        dispatch(setLoading(false));
        if (data.status) {
            dispatch(setSuccess(data.message))
        }
    }).catch(err => {
        dispatch(setError(err.message))
        // console.log(err)
    })
}

export const heatmapSummaryRequest = async (requestData) => {
    // dispatch(setLoading(true));
    requestData.dispatch(setLoading(true));

    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(requestData),
        headers: { 'Content-Type': 'application/json', "Authorization": "Bearer " + user.token },
    };


    fetch(baseUrl + "heatsummary/getBySid  ", requestOptions).then(async res => {
        return res.json();
    }).then(data => {

        requestData.dispatch(setLoading(false));
        requestData.dispatch(setHeatmapSummary(data));

    })
        .catch(err => {
            requestData.dispatch(setLoading(false));
            console.warn(err);
        });
};

export const heatmapRequest = async (requestData) => {
    // dispatch(setLoading(true));
    requestData.dispatch(setLoading(true));

    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(requestData.params),
        headers: { 'Content-Type': 'application/json', "Authorization": "Bearer " + user.token },
    };


    fetch(baseUrl + "heatmap/getHeatmap", requestOptions).then(async res => {
        const reader = res.body.getReader();
        const contentLength = +res.headers.get('Content-Length');
        let receivedLength = 0;
        let chunks = [];
        while (true) {
            const { done, value } = await reader.read();

            if (done) {
                break;
            }

            chunks.push(value);
            receivedLength += value.length;
        }
        let chunksAll = new Uint8Array(receivedLength);
        let position = 0;
        for (let chunk of chunks) {
            chunksAll.set(chunk, position); // (4.2)
            position += chunk.length;
        }
        let result = new TextDecoder("utf-8").decode(chunksAll);
        let commits = JSON.parse(result);
        requestData.dispatch(setLoading(false));
        if (!commits.data.length) {
            requestData.dispatch(setSuccess('No data available'))
        }

        return commits;
    }).then(data => {
        // requestData.dispatch(setLoading(false));
        if (data.status == true) {

            requestData.dispatch(setHeatmap(data.data));
        }

    })
        .catch(err => {
            requestData.dispatch(setLoading(false));
            console.warn(err);
        });
};



export const marketAvgRequest = (requestData) => {
    requestData.dispatch(setLoading(true));
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(requestData),
        headers: { 'Content-Type': 'application/json', "Authorization": "Bearer " + user.token },
    };

    fetch(baseUrl + "marketAvg/getMarketAvg", requestOptions).then(res => {
        return res.json();
    }).then(data => {
        console.log('datadatadata', data);
        if (data.status == true) {
            let obj = {
                user: user,
                data: data.data,
                requestData: requestData
            }

            requestData.dispatch(setMarketAvgValue(obj));
        }
        requestData.dispatch(setLoading(false));

    }).catch(err => {
        console.warn(err);
        requestData.dispatch(setLoading(false));
    });
};


export const imputeFootfallRequest = (requestData) => {
    requestData.dispatch(setLoading(true));
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(requestData),
        headers: { 'Content-Type': 'application/json', "Authorization": "Bearer " + user.token },
    };

    fetch(baseUrl + "footfall/getCountImpute", requestOptions).then(res => {
        return res.json();
    }).then(data => {
        if (data.status == true) {
            requestData.dispatch(setLoading(false));
            requestData.dispatch(setFootfallImpute(data.data));
        }
        requestData.dispatch(setLoading(false));

        requestData.dispatch(setFootfallLoader(false));
    }).catch(err => {
        console.warn(err);
        requestData.dispatch(setFootfallLoader(false));
        requestData.dispatch(setLoading(false));
    });
};

export const footfallRequest = (requestData) => {
    requestData.dispatch(setLoading(true));
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(requestData),
        headers: { 'Content-Type': 'application/json', "Authorization": "Bearer " + user.token },
    };

    fetch(baseUrl + "footfall/getCount", requestOptions).then(res => {
        return res.json();
    }).then(data => {
        if (data.status == true) {
            requestData.dispatch(setLoading(false));
            requestData.dispatch(setFootfall(data.data));
        }
        requestData.dispatch(setFootfallLoader(false));
    }).catch(err => {
        console.warn(err);
        requestData.dispatch(setFootfallLoader(false));
        requestData.dispatch(setLoading(true));
    });
};

export const footfallGraphRequest = (requestData) => {
    console.log('requestData==', requestData);

    requestData.dispatch(setTimelineLoader(true));


    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(requestData),
        headers: { 'Content-Type': 'application/json', "Authorization": "Bearer " + user.token },
    };

    fetch(baseUrl + "footfall/lineGraph", requestOptions).then(res => {
        return res.json();
    }).then(data => {

        if (data.status === true) {
            let obj = {
                user: user,
                data: data.data,
                requestData: requestData
            }
            requestData.dispatch(setFootfallGraph(obj));
        }
        requestData.dispatch(setTimelineLoader(false));

    }).catch(err => {
        requestData.dispatch(setTimelineLoader(false));

        console.warn(err);
    });
};
export const footfallGraphRequest2 = (requestData) => {
    console.log('requestData==', requestData);

    requestData.dispatch(setTimelineLoader(true));


    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(requestData),
        headers: { 'Content-Type': 'application/json', "Authorization": "Bearer " + user.token },
    };

    fetch(baseUrl + "footfall/lineGraph", requestOptions).then(res => {
        return res.json();
    }).then(data => {

        if (data.status === true) {
            let obj = {
                user: user,
                data: data.data,
                requestData: requestData,
            }
            requestData.dispatch(setFootfallGraph2(obj));
        }
        requestData.dispatch(setTimelineLoader(false));

    }).catch(err => {
        requestData.dispatch(setTimelineLoader(false));

        console.warn(err);
    });
};
export const imputeFootfallGraphRequest = (requestData) => {
    requestData.dispatch(setTimelineLoader(true));
    requestData.dispatch(setLoading(true))
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(requestData),
        headers: { 'Content-Type': 'application/json', "Authorization": "Bearer " + user.token },
    };

    fetch(baseUrl + "footfall/lineGraphImpute", requestOptions).then(res => {
        return res.json();
    }).then(data => {
        if (data.status === true) {
            let obj = {
                user: user,
                data: data.data,
                requestData: requestData
            }
            requestData.dispatch(setLoading(false))

            requestData.dispatch(setNewFootfallGraph(obj));
        }
        requestData.dispatch(setTimelineLoader(false));

    }).catch(err => {
        requestData.dispatch(setTimelineLoader(false));

        requestData.dispatch(setLoading(false))

        console.warn(err);
    });
};


export const customerJourneyRequest = (requestData) => {
    var dispatch = requestData.dispatch;
    dispatch(setLoading(true));

    const requestOptions = {
        method: "POST",
        body: JSON.stringify(requestData),
        headers: { 'Content-Type': 'application/json', "Authorization": "Bearer " + user.token },
    };

    fetch(baseUrl + "dwelltime/customerJourney", requestOptions).then(res => {
        return res.json();
    }).then((response) => {
        dispatch(setLoading(false));
        dispatch(setJourney(response.data))
    }).catch(err => {
        dispatch(setLoading(false));
        console.log("err", err);
    })
}

export const categoryRequest = (requestData) => {
    // delete requestData?.sec_id;
    var dispatch = requestData.dispatch;
    dispatch(setLoading(true));

    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(requestData),
        headers: { 'Content-Type': 'application/json', "Authorization": "Bearer " + user.token },
    };

    // fetch(baseUrl + "dwelltime/getCount", requestOptions).then(res => {
    fetch(baseUrl + "dwelltime/getCount", requestOptions).then(res => {
        return res.json();
    }).then(data => {

        let _data = {
            requestData: requestData,
            data: data.data
        }
        dispatch(setLoading(false));
        // if (data.status == true) {
        if (requestData.section === 'entry') {
            console.log('setCategoryEntry is running');
            requestData.dispatch(setCategoryEntry(_data));

        } else {
            requestData.dispatch(setCategory(_data));

        }
        // }
        requestData.dispatch(setCategoryLoader(false));
    }).catch(err => {
        dispatch(setLoading(false));
        console.warn(err);
        requestData.dispatch(setCategoryLoader(false));
    });
};

export const categoryRequestTwo = (requestData) => {
    // delete requestData?.sec_id;
    var dispatch = requestData.dispatch;
    dispatch(setLoading(true));

    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(requestData),
        headers: { 'Content-Type': 'application/json', "Authorization": "Bearer " + user.token },
    };

    // fetch(baseUrl + "dwelltime/getCount", requestOptions).then(res => {
    fetch(baseUrl + "dtcache/getCount", requestOptions).then(res => {
        return res.json();
    }).then(data => {

        let _data = {
            requestData: requestData,
            data: data.data
        }
        dispatch(setLoading(false));
        // if (data.status == true) {
        requestData.dispatch(setCategory(_data));
        // }
        requestData.dispatch(setCategoryLoader(false));
    }).catch(err => {
        dispatch(setLoading(false));
        console.warn(err);
        requestData.dispatch(setCategoryLoader(false));
    });
};

export const categoryGraphRequest = (requestData) => {
    var dispatch = requestData.dispatch;

    dispatch(setLoading(true));

    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(requestData),
        headers: { 'Content-Type': 'application/json', "Authorization": "Bearer " + user.token },
    };

    fetch(baseUrl + "dwelltime/lineGraph", requestOptions).then(res => {
        return res.json();
    }).then(data => {
        dispatch(setLoading(false));
        if (data.status == true) {
            requestData.dispatch(setCategoryGraph(data.data));
        }
    }).catch(err => {
        dispatch(setLoading(false));
        console.warn(err);
    });
};


export const comparisonRequest = (requestData) => {
    var dispatch = requestData.dispatch;
    dispatch(setLoading(true));

    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(requestData.params),
        headers: { 'Content-Type': 'application/json', "Authorization": "Bearer " + user.token },
    };

    fetch(baseUrl + "footfall/compare", requestOptions).then(res => {
        return res.json();
    }).then(data => {
        if (data.status === true) {
            requestData.dispatch(setGroupComparison(data.data));
            dispatch(setLoading(false));

            // if (requestData.params.type === "single") {
            //     // requestData.dispatch(setComparison(data.data));
            //     requestData.dispatch(setGroupComparison(data.data));

            // } else {
            //     requestData.dispatch(setGroupComparison(data.data));
            // }
        } else {
            dispatch(setLoading(false));

            requestData.dispatch(setComparisonLoader(false));
        }
    }).catch(err => {
        dispatch(setLoading(false));
        requestData.dispatch(setComparisonLoader(false));
        console.warn(err);
    });
};


export const getAllPrimaryQuestionRequest = (requestData) => {

    var dispatch = requestData.dispatch;
    dispatch(setLoading(true));

    const requestOptions = {
        method: "POST",
        body: JSON.stringify(requestData),
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + user.token,
        },
    };

    fetch(baseUrl + `ianswer/getAnswers`, requestOptions)
        .then((res) => {
            return res.json();
        })
        .then((data) => {
            dispatch(setLoading(false));
            if (data.status) {
                requestData.dispatch(setPrimaryQuestion(data.data));
            }
        })
        .catch((err) => {
            dispatch(setLoading(false));

            console.warn(err);
        });
};

export const addCustomQuestionRequest = (requestData) => {

    var dispatch = requestData.dispatch;
    dispatch(setLoading(true));

    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(requestData),
        headers: { 'Content-Type': 'application/json', "Authorization": "Bearer " + user.token },
    };

    fetch(`${baseUrl}iquestion`, requestOptions).then(res => {
        return res.json();
    }).then((data) => {
        if (data.status === true) {
            dispatch(setLoading(false));

            let obj = {
                user_id: requestData.user_id,
                dispatch: requestData.dispatch
            }
            getUserCustomQuestion(obj)
        }

    }).catch(err => {
        dispatch(setLoading(false));

        console.log(err);
    })
}


export const deleteCustomQuestionRequest = (requestData) => {

    var dispatch = requestData.dispatch;
    dispatch(setLoading(true));

    const requestOptions = {
        method: "DELETE",
        body: JSON.stringify(requestData),
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + user.token,
        },
    };

    fetch(baseUrl + `iquestion/${requestData._id}`, requestOptions)
        .then((res) => {
            return res.json();
        })
        .then((data) => {
            dispatch(setLoading(false));

            if (data.status) {
                getUserCustomQuestion({ dispatch: requestData.dispatch, user_id: user._id })

                console.log('');

                // requestData.dispatch(setCustomQuestion(data.data));
            }
        })
        .catch((err) => {
            dispatch(setLoading(false));

            console.warn(err);
        });
};



export const getUserCustomQuestion = (requestData) => {

    var dispatch = requestData.dispatch;
    dispatch(setLoading(true));

    const requestOptions = {
        method: "POST",
        body: JSON.stringify(requestData),
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + user.token,
        },
    };

    fetch(baseUrl + `ianswer/getCustomAnswers`, requestOptions)
        .then((res) => {
            return res.json();
        })
        .then((data) => {
            dispatch(setLoading(false));
            if (data.status) {
                requestData.dispatch(setCustomQuestion(data.data));
            }
        })
        .catch((err) => {
            dispatch(setLoading(false));

            console.warn(err);
        });
};


export const insightRequest = (requestData) => {
    var dispatch = requestData.dispatch;

    dispatch(setLoading(true));
    const requestOptions = {
        method: "POST",
        body: JSON.stringify(requestData.params),
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + user.token,
        },
    };

    fetch(baseUrl + "footfall/insights", requestOptions)
        .then((res) => {
            return res.json();
        })
        .then((data) => {
            requestData.dispatch(setInsightProgress(false));
            dispatch(setLoading(false));

            if (data.status === true) {
                requestData.dispatch(setInsights(data.data));
            }
        })
        .catch((err) => {
            dispatch(setLoading(false));

            requestData.dispatch(setInsightProgress(false));
            console.warn(err);
        });
};


export const authenticationRequest = (requestData) => {
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(),
        headers: { 'Content-Type': 'application/json', "Authorization": "Bearer " + user.token },
    };

    fetch(baseUrl + "users/updateToken", requestOptions).then(res => {
        return res.json()
    }).then(data => {
        if (data.status === true) {
            user = data.data
            requestData.dispatch(setLogin(data.data));
        }
    }).catch(err => {
        // console.log(err);
    })


}

export const singleFloorFetch = (requestData) => {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', "Authorization": "Bearer " + user.token }
    };

    fetch(`${baseUrl}floors/${requestData._id}`, requestOptions).then(res => {
        return res.json();
    }).then(data => {
        requestData.dispatch(setFloor(data.data))

    }).catch(err => {
        // console.log(err);
    })
}
export const floorRequest = (requestData) => {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', "Authorization": "Bearer " + user.token },
    };

    fetch(`${baseUrl}floors?space_id=${requestData._id}`, requestOptions).then(res => {
        return res.json();
    }).then(data => {
        requestData.dispatch(setFloors(data.data.docs))
    }).catch(err => {
        console.log(err)
    })
}

export const conversionDailyRequest = (requestData) => {

    requestData.dispatch(setLoading(true));
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(requestData),
        headers: { 'Content-Type': 'application/json', "Authorization": "Bearer " + user.token },
    };

    fetch(baseUrl + "sales/dailySales", requestOptions).then(res => {
        return res.json();
    }).then(data => {
        if (data.status === true) {
            requestData.dispatch(setConversionDailyData(data.data));
            var response = axios(data.data);
            return response
        }
        requestData.dispatch(setLoading(false));
    }).catch(err => {
        console.warn(err);
        requestData.dispatch(setLoading(false));
    });
};

export const dailySales = async (requestData) => {
    const requestOptions = {
        method: "POST",
        url: baseUrl + "sales/dailySales",
        data: requestData,
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + user.token,
        },
    };
    const response = await axios(requestOptions);
    return response;
};

export const conversionTotalRequest = (requestData) => {
    requestData.dispatch(setConversionLoader(true));
    const requestOptions = {
        method: "POST",
        body: JSON.stringify(requestData.params),
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + user.token,
        },
    };

    fetch(baseUrl + "sales/getTotalCount", requestOptions)
        .then((res) => {
            return res.json();
        })
        .then((data) => {
            console.log(data);
            if (data.status === true) {
                requestData.dispatch(setConversionTotal(data.data));
            }
            requestData.dispatch(setConversionLoader(false));
        })
        .catch((err) => {
            console.warn(err);
            requestData.dispatch(setConversionLoader(false));
        });
};

export const singleSpaceFetch = (requestData) => {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', "Authorization": "Bearer " + user.token },
    };

    fetch(`${baseUrl}spaces/${requestData._id}`, requestOptions).then(res => {
        return res.json();
    }).then(data => {
        if (data.status = true)
            console.log('hi em ruynning');
        requestData.dispatch(setSpace(data.data));
    }).catch(err => {
        console.warn(err);
    });
}

export const getEmployeRequest = (requestData) => {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', "Authorization": "Bearer " + user.token },
    };

    fetch(`${baseUrl}employee?sid=${requestData._id}&limit=100&page=1`, requestOptions).then(res => {
        return res.json();
    }).then(data => {
        if (data.status = true)
            requestData.dispatch(setEmploye(data.data.docs));
    }).catch(err => {
    });

}

export const getSectionofEmpoyeRequest = (requestData) => {
    requestData.dispatch(setLoading(true));


    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(requestData),
        headers: { 'Content-Type': 'application/json', "Authorization": "Bearer " + user.token },
    };

    fetch(`${baseUrl}employee_sale/employeeSaleData`, requestOptions).then(res => {
        return res.json();
    }).then(data => {
        requestData.dispatch(setLoading(false));
        if (data.status = true)
            requestData.dispatch(getSectionsOfEmploye(data.data));
    }).catch(err => {
        requestData.dispatch(setLoading(false));
        console.warn(err);
    });

}


export const getGraphOfEmployeeActivityRequest = (requestData) => {
    requestData.dispatch(setLoading(true));
    requestData.dispatch(setGraphLoader(false));
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(requestData),
        headers: { 'Content-Type': 'application/json', "Authorization": "Bearer " + user.token },
    };

    fetch(`${baseUrl}dwelltime/employeeDwellData`, requestOptions).then(res => {
        return res.json();
    }).then(data => {
        requestData.dispatch(setLoading(false));
        requestData.dispatch(setGraphLoader(true));
        if (data.status = true)
            requestData.dispatch(getGraphOfEmployeActivity(data.data));
    }).catch(err => {
        requestData.dispatch(setLoading(false));
        requestData.dispatch(setGraphLoader(true));
        console.warn(err);
    });

}



export const getCustomerTimelineRequest = (requestData) => {
    console.log('requestData', requestData);
    requestData.dispatch(setLoading(true));
    requestData.dispatch(setGraphLoader(false));
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(requestData),
        headers: { 'Content-Type': 'application/json', "Authorization": "Bearer " + user.token },
    };

    fetch(`${baseUrl}dwelltime/getCustomerTimeline`, requestOptions).then(res => {
        return res.json();
    }).then(data => {
        let obj = {
            data: data.data,
            requestData: requestData
        }
        console.log('objInCustomer', obj);
        requestData.dispatch(setCustomerTimeline(obj))
        // requestData.dispatch(setLoading(false));
        // requestData.dispatch(setGraphLoader(true));
        if (data.status = true) {

        }
        // requestData.dispatch(getGraphOfEmployeActivity(data.data));
    }).catch(err => {
        requestData.dispatch(setLoading(false));
        requestData.dispatch(setGraphLoader(true));
        console.warn(err);
    });

}

export const getSectionTimelineRequest = (requestData) => {
    requestData.dispatch(setLoading(true));
    requestData.dispatch(setTimelineLoader(true));
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(requestData),
        headers: { 'Content-Type': 'application/json', "Authorization": "Bearer " + user.token },
    };

    fetch(`${baseUrl}dwelltime/getSectionTimeline`, requestOptions).then(res => {
        return res.json();
    }).then(data => {
        console.log('datadata', data);
        let obj = {
            data: data.data,
            requestData: requestData
        }
        requestData.dispatch(setTimelineSection(obj))
        // requestData.dispatch(setLoading(false));
        requestData.dispatch(setTimelineLoader(false));
        if (data.status = true) {

        }
        requestData.dispatch(setLoading(false));

        // requestData.dispatch(getGraphOfEmployeActivity(data.data));
    }).catch(err => {
        requestData.dispatch(setLoading(false));
        requestData.dispatch(setTimelineLoader(false));
        console.warn(err);
    });

}
export const getSaleItemRequest = (requestData) => {
    requestData.dispatch(setLoading(true));
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(requestData),
        headers: { 'Content-Type': 'application/json', "Authorization": "Bearer " + user.token },
    };

    fetch(`${baseUrl}items/getSaleItems`, requestOptions).then(res => {
        return res.json();
    }).then(data => {
        requestData.dispatch(setLoading(false));
        requestData.dispatch(setSaleItem(data))
    }).catch(err => {
        requestData.dispatch(setLoading(false));
        console.warn(err);
    });
}


export const employeSalesUnitRequest = (requestData) => {
    requestData.dispatch(setLoading(true));
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(requestData),
        headers: { 'Content-Type': 'application/json', "Authorization": "Bearer " + user.token },
    };

    fetch(`${baseUrl}employee_sale/SaleUnits`, requestOptions).then(res => {
        return res.json();
    }).then(data => {
        requestData.dispatch(setLoading(false));
        requestData.dispatch(setEmployeeSaleUnit(data.data))
    }).catch(err => {
        requestData.dispatch(setLoading(false));
        console.warn(err);
    });
}

export const getBasicInsightsRequest = (requestData) => {
    var dispatch = requestData.dispatch;
    dispatch(setLoading(true));
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(requestData),
        headers: { 'Content-Type': 'application/json', "Authorization": "Bearer " + user.token },
    };

    fetch(`${baseUrl}cache/getBasicInsights`, requestOptions).then(res => {
        return res.json();
    }).then(data => {
        requestData.dispatch(setShowFilters(!requestData.showFilterByRedux))
        requestData.dispatch(setBasicInsights(data))
        dispatch(setLoading(false));
    }).catch(err => {
        dispatch(setLoading(false));
        console.log(err);
        requestData.dispatch(setShowFilters(false))
    })

}

export const insightsBreakDownRequest = (requestData) => {
    requestData.dispatch(setGraphLoader(false));

    var dispatch = requestData.dispatch;
    dispatch(setLoading(true));
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(requestData),
        headers: { 'Content-Type': 'application/json', "Authorization": "Bearer " + user.token },
    };

    fetch(`${baseUrl}cache/getBasicInsightsBreakdown`, requestOptions).then(res => {
        return res.json();
    }).then(data => {
        requestData.dispatch(setGraphLoader(true));

        requestData.dispatch(setBasicInsights(data))
        dispatch(setLoading(false));

    }).catch(err => {
        requestData.dispatch(setGraphLoader(true));

        dispatch(setLoading(false));
        console.log(err);
    })
}


export const salesTrafficPerformance = (requestData) => {

    var dispatch = requestData.dispatch;
    requestData.dispatch(setGraphLoader(false));

    dispatch(setLoading(true));
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(requestData),
        headers: { 'Content-Type': 'application/json', "Authorization": "Bearer " + user.token },
    };

    fetch(`${baseUrl}sales/getSalesInsightsBreakdown`, requestOptions).then(res => {
        return res.json();
    }).then(data => {
        requestData.dispatch(setGraphLoader(true));

        requestData.dispatch(setInsightPerformance(data.data))
        dispatch(setLoading(false));

    }).catch(err => {
        requestData.dispatch(setGraphLoader(true));

        dispatch(setLoading(false));
        console.log(err);
    })
}

export const getInsightTrendsRequest = (requestData) => {
    var dispatch = requestData.dispatch;

    dispatch(setLoading(true));
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(requestData),
        headers: { 'Content-Type': 'application/json', "Authorization": "Bearer " + user.token },
    };

    fetch(`${baseUrl}cache/getInsightsTrendsBreakdown`, requestOptions).then(res => {
        return res.json();
    }).then(data => {
        let rawdata = data.sort((a, b) => {
            return moment(a._id) - moment(b._id);
        });
        dispatch(setBasicTrends(rawdata))
        dispatch(setLoading(false));

    }).catch(err => {
        dispatch(setLoading(false));
        console.log(err);
    })
}

export const userStatRequest = (requestData) => {
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(requestData),
        headers: { 'Content-Type': 'application/json', "Authorization": "Bearer " + user.token },
    };

    fetch(`${baseUrl}history`, requestOptions).then(res => {
        return res.json();
    }).then(data => {


    }).catch(err => {
        console.log(err);
    })
}

export const getUserActivityRequest = (requestData) => {
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(requestData),
        headers: { 'Content-Type': 'application/json', "Authorization": "Bearer " + user.token },
    };

    fetch(`${baseUrl}history/getUserActivity`, requestOptions).then(res => {
        return res.json();
    }).then(data => {
        requestData.dispatch(setUserActivity(data))


    }).catch(err => {
        console.log(err);
    })
}

export const updateSectionLabelRequest = async (requestData) => {
    var dispatch = requestData.dispatch;
    dispatch(setLoading(true));

    var response = await axios({
        method: 'PUT',
        url: baseUrl + `floors/updateRegion/${requestData.id}`,
        data: requestData.data,
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + user.token,
        }
    });
    dispatch(setLoading(false));
    if (response.data.status) {
        dispatch(setSuccess('Updated'))
    } else {
        dispatch(setError('Not Updated'))

    }

    // dispatch(setStoresActivity(data));
    return response;
}



export const getStoresActivityRequest = async (requestData) => {
    var dispatch = requestData.dispatch;
    dispatch(setLoading(true));
    var response = await axios({
        method: 'POST',
        url: baseUrl + 'socket/getActivity ',
        data: requestData,
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + user.token,
        }
    });
    dispatch(setLoading(false));
    // let data = response.data;

    // dispatch(setStoresActivity(data));
    return response.data;
}


export const getStoresHistoryRequest = async (requestData) => {
    var dispatch = requestData.dispatch;

    dispatch(setLoading(true));
    var response = await axios({
        method: 'POST',
        url: baseUrl + 'socket/AllStoresActivity',
        data: requestData,
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + user.token,
        }
    });
    dispatch(setLoading(false));

    // let data = response.data;

    // dispatch(setStoresActivity(data));
    return response.data;
}

export const chatgptTableRequest = async (requestData) => {
    var dispatch = requestData.dispatch;

    dispatch(setLoading(true));
    var response = await axios({
        method: 'POST',
        url: baseUrl + 'insights',
        data: requestData,
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + user.token,
        }
    });
    dispatch(setLoading(false));
    if (response.data.status) {
        let _data = NewlineText(response.data.data);
        console.log('_data_data', _data);
        console.log('response.data.data', response.data.data);
        dispatch(setChatgptTable(NewlineText(response.data.data)))
    }
    else {
        dispatch(setChatgptTable(''))

    }

    return response.data;
}

export const getSlotRequest = async (requestData) => {
    var dispatch = requestData.dispatch;

    dispatch(setLoading(true));
    var response = await axios({
        method: 'GET',
        url: baseUrl + `slots?client_id=${requestData.client_id}`,
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + user.token,
        }
    });
    dispatch(setLoading(false));

    console.log('responseOfSlots', response);
    if (response.data.status) {

    }
    else {

    }

    return response.data.data.docs;
}

export const addSlotRequest = async (requestData) => {
    var dispatch = requestData.dispatch;

    dispatch(setLoading(true));
    var response = await axios({
        method: 'POST',
        url: baseUrl + `slots`,
        data: requestData,
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + user.token,
        }
    });
    dispatch(setLoading(false));

    console.log('responseOfSlots', response);
    if (response.data.status) {

    }
    else {

    }

    return response.data.data.docs;
}

export const updateSlotRequest = async (requestData) => {
    var dispatch = requestData.dispatch;

    dispatch(setLoading(true));
    var response = await axios({
        method: 'PUT',
        url: baseUrl + `slots/${requestData._id}`,
        data: requestData.data,
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + user.token,
        }
    });
    dispatch(setLoading(false));

    console.log('responseOfSlots', response);
    if (response.data.status) {

    }
    else {

    }

    return response.data;
}

export const getSlotTrafficRequest = async (requestData) => {
    var dispatch = requestData.dispatch;

    dispatch(setLoading(true));
    var response = await axios({
        method: 'POST',
        url: baseUrl + `dwelltime/getSlotsCount`,
        data: requestData,
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + user.token,
        }
    });
    dispatch(setLoading(false));

    console.log('responseOfSlotsTraffic', response);
    // if(response.data.status){

    // }
    // else {

    // }

    return response.data;
}

export const getSlotTrafficNewRequest = async (requestData) => {
    var dispatch = requestData.dispatch;
    let limit = requestData?.params?.limit ? requestData.params.limit : 10;
    let page = requestData?.params?.page ? requestData.params.page : 1
    dispatch(setLoading(true));
    var response = await axios({
        method: 'POST',
        url: baseUrl + `dwelltime/getBySection?page=${page}&limit=${limit}`,
        data: requestData.body,
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + user.token,
        }
    });
    dispatch(setLoading(false));

    console.log('responseOfSlotsTraffic', response);
    // if(response.data.status){

    // }
    // else {

    // }

    return response.data;
}



export const dwelltimeMergeRequest = async (requestData) => {
    var dispatch = requestData.dispatch;

    dispatch(setLoading(true));
    var response = await axios({
        method: 'POST',
        url: baseUrl + `dwelltime/merge`,
        data: requestData.data,
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + user.token,
        }
    });
    dispatch(setLoading(false));

    console.log('responseOfSlotsTraffic', response);
    // if(response.data.status){

    // }
    // else {

    // }

    return response.data;
}



export const saveMergeDTRequest = async (requestData) => {
    var dispatch = requestData.dispatch;

    dispatch(setLoading(true));
    var response = await axios({
        method: 'POST',
        url: baseUrl + `dwelltime/updateSlotsCount`,
        data: requestData.data,
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + user.token,
        }
    });
    dispatch(setLoading(false));

    console.log('responseOfSlotsTraffic', response);
    // if(response.data.status){

    // }
    // else {

    // }

    return response.data;
}



export const deleteDTReqeust = async (requestData) => {
    var dispatch = requestData.dispatch;

    dispatch(setLoading(true));
    var response = await axios({
        method: 'POST',
        url: baseUrl + `dwelltime/deleteMultiple`,
        data: requestData.data,
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + user.token,
        }
    });
    dispatch(setLoading(false));

    console.log('responseOfSlotsTraffic', response);

    return response.data;
}

export const galleryRequest = async (requestData) => {

    const dispatch = requestData.dispatch;
    dispatch(setLoading(true));
    console.log('requestData is', requestData);


    let limit = requestData?.params?.limit ? requestData.params.limit : 9;
    let page = requestData?.params?.page ? requestData.params.page : 1;
    let gender = requestData.params.gender;
    let employee = requestData.params.employee;

    let url = `https://devapi.adlytic.ai/media/getAll?page=${page}&limit=${limit}`;

    if (gender && gender !== 'all') {
        url += `&G=${gender}`;
    }

    if (employee && employee !== 'all') {
        url += `&EMP=${employee}`;
    }

    console.log('url is our', url);


    var response = await axios({
        method: 'POST',
        url: url,
        // url: `http://localhost:3000/media/getAll?page=${page}&limit=${limit}`,
        data: requestData.body,
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + user.token,
        }
    }).catch(function (error) {
    });

    dispatch(setLoading(false));


    return response.data;
}



export const addDwellTimeRequest = async (requestData) => {

    const dispatch = requestData.dispatch;
    dispatch(setLoading(true));


    var response = await axios({
        method: 'POST',
        url: baseUrl + `dwelltime/rawData`,
        data: requestData.data,
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + user.token,
        }
    }).catch(function (error) {
    });

    dispatch(setLoading(false));


    return response;
}




export const galleryDeleteRequest = async (requestData) => {

    const dispatch = requestData.dispatch;
    dispatch(setLoading(true));


    var response = await axios({
        method: 'POST',
        url: 'https://devapi.adlytic.ai/media/softDelete',
        data: requestData.data,
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + user.token,
        }
    }).catch(function (error) {
    });

    dispatch(setLoading(false));


    return response;
}

export const getCategoriesProduct = async (requestData) => {

    const dispatch = requestData.dispatch;
    dispatch(setLoading(true));


    var response = await axios({
        method: 'GET',
        url: baseUrl + `items/getCategories/${requestData.sid}`,
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + user.token,
        }
    }).catch(function (error) {
    });

    dispatch(setLoading(false));


    return response;
}

export const updateSectionProducts = async (requestData) => {

    const dispatch = requestData.dispatch;
    dispatch(setLoading(true));


    var response = await axios({
        method: 'PUT',
        url: baseUrl + `floors/addCategoriesToSection/${requestData.data._id}`,
        data: requestData.data,
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + user.token,
        }
    }).catch(function (error) {
    });

    dispatch(setLoading(false));


    return response;
}


export const getCameraByLinkedSection = async (requestData) => {

    const dispatch = requestData.dispatch;
    dispatch(setLoading(true));


    var response = await axios({
        method: 'GET',
        url: baseUrl + `cameras/getCameraBySectionId/${requestData.linkedsectionid}`,
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + user.token,
        }
    }).catch(function (error) {
    });

    dispatch(setLoading(false));


    return response;
}

export const getCameraHeatmap = async (requestData) => {
    requestData.dispatch(setLoading(true));

    try {
        const requestOptions = {
            method: 'POST',
            body: JSON.stringify(requestData.params),
            headers: { 'Content-Type': 'application/json', "Authorization": "Bearer " + user.token },
        };

        const response = await fetch(baseUrl + "heatmap/getCameraHeatmap", requestOptions);

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const chunks = [];
        const reader = response.body.getReader();

        while (true) {
            const { done, value } = await reader.read();

            if (done) {
                break;
            }

            chunks.push(value);
        }

        const chunksAll = new Uint8Array(chunks.reduce((acc, chunk) => acc + chunk.length, 0));
        let position = 0;

        for (let chunk of chunks) {
            chunksAll.set(chunk, position);
            position += chunk.length;
        }

        const result = new TextDecoder("utf-8").decode(chunksAll);
        const data = JSON.parse(result);

        requestData.dispatch(setLoading(false));

        if (data.status) {
            return data.data;
        } else {
            requestData.dispatch(setSuccess('No data available'));
            return [];
        }
    } catch (error) {
        requestData.dispatch(setLoading(false));
        console.error(error);
        return [];
    }
};

export const getZoneSalesRequest = async (requestData) => {

    const dispatch = requestData.dispatch;
    dispatch(setLoading(true));


    var response = await axios({
        method: 'POST',
        url: baseUrl + "items/zoneSales",
        data: requestData.data,
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + user.token,
        }
    }).catch(function (error) {
    });

    dispatch(setLoading(false));


    return response;
}












