import React, { Fragment, useState, useEffect } from 'react';
import { ToastContainer, toast,} from 'react-toastify';
import { Spinner, ToastHeader } from 'reactstrap';
import 'react-toastify/dist/ReactToastify.min.css';

function ConnectionLost() {
    const [navigators, setNavigator] = useState(navigator.onLine)
    useEffect(() => {
        window.addEventListener("online", () => setNavigator(true))
        window.addEventListener("offline", () => setNavigator(false))
    }, [window.navigator.onLine])

    useEffect(() => {
        toast.dismiss();
        if(!navigators){
            toast(<div className="d-flex justify-content-between"><ToastHeader icon={<Spinner size="sm" color="warning"></Spinner>}>
           Connection lost... Retrying..!
        </ToastHeader></div>, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: false,
                closeButton: false,
                closeOnClick: false,
            });
        }
       
        if (navigators) {
            toast.dismiss()
        }
    }, [navigators])

  return (
    <Fragment>  
        <ToastContainer />
    </Fragment>
  )
}

export default ConnectionLost