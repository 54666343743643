import React, { Fragment, useEffect, useState, useRef } from 'react';
import { ArrowLeft, ArrowRight, Bookmark, Filter, Plus, Trash2, XCircle, ChevronLeft, ChevronsLeft, ChevronsRight, ChevronRight } from 'react-feather';

import { Dropdown, DropdownMenu, DropdownToggle, Row, Col, ListGroup, ListGroupItem, Button, Input, InputGroup, Label } from 'reactstrap';
import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import { Calendar } from 'react-modern-calendar-datepicker';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { colors } from '../../helpers/meta';
import { colourStyles } from '../../helpers/common';
import { createFilterRequest, deleteFilterRequest, listFilterRequest } from '../../helpers/requests';
import { setError } from '../../redux/actions/highlights';
import { deleteFilter } from '../../redux/actions/savedFilters';
import '../../App.css'
import { useLocalStorage } from '../Hooks/useLocalStorage';
import { capitalize, capitalizeFirstLetter, captialLetterOfEachWord, charCheck, isGreaterThanToday, isToday, timeFormat, toLowerCase } from '../../helpers/utils';
import Slider from '../Slider/Slider';
import './MainFilter.css'
import StoresByType from './components/StoresByType';
import { convertObjectIntoArray, getStoresObject } from './components/FilterCommon';
import SelectedFilter from './components/SelectedFilter';
import HijriCalendar from './components/HijriCalendar';
import FilterTwo from './components/FilterTwo';

const NewMainFiltrer = (props) => {

    const loaded = useSelector(state => state.savedFilters.loaded);
    const branchFilters = useSelector(state => state.savedFilters.branchFilters);
    const dateFilters = useSelector(state => state.savedFilters.dateFilters);
    const dispatch = useDispatch();
    const [showFilters, setShowFilters] = useState(false);
    const [dateLoader, setDateLoader] = useState(false);
    const [branchLabel, setBranchLabel] = useState("");
    const [branchLoader, setBranchLoader] = useState(false);
    const [branchFilter, setBranchFilter] = useState(false);
    const [selection, setSelection] = useLocalStorage("selectionIn", "all");
    const branchRef = useRef(null);
    const dateRef = useRef(null);
    const [customSelection, setCustomSelection] = useState(false)
    const [deleteFilters, setDeleteFilters] = useState(false)
    const user = useSelector(state => state.auth.user);
    const [dateLabel, setDateLabel] = useState("");
    const [dateSelection, setDateSelection] = useState("yesterday");
    const [dateFilter, setDateFilter] = useState(false);
    const [calendarType, setCalendarType] = useState('english')
    const [value, setValue] = useState(new Date());

    const toggle = () => {
        setShowFilters(!showFilters);
    }



    const defaultDate = {
        year: new Date().getFullYear(),
        month: new Date().getMonth() + 1,
        day: new Date().getDate() - 1
    }

    const [selectedDayRange, setSelectedDayRange] = useLocalStorage('selectedDayRange',
        {
            from: defaultDate,
            to: defaultDate
        }
    );


    const setSelectedDayRangeFromCalendar = (obj) => {
        setSelectedDayRange(obj);
    }





    const [classifyFlag, setClassifyFlag] = useLocalStorage('classifyFlag', 'city')
    let classifyByClasses = {};
    let classifyByCity = {};

    const options = user.access.map((val) => {
        let _city = val.city.toLowerCase();
        classifyByCity[_city] = val.city;
        let grade = charCheck(val.grade)
        if (grade !== undefined && grade) {
            classifyByClasses[grade] = grade;
        }

        return { ...val, value: val._id }
    });

    console.log('classifyByClasses', classifyByClasses,options);


    const [storesType, setStoresType] = useState(classifyByCity);
    let _option = options[0];
    const [option, setOption] = useLocalStorage("option", options);
    useEffect(() => {
        if (classifyFlag === 'city') {
            // props.setCategory(Object.keys(classifyByCity ? classifyByCity : {})[0])
            setStoresType(classifyByCity)
        }
        else if (classifyFlag === 'grade') {
            // props.setCategory(Object.keys(classifyByClasses ? classifyByClasses : {})[0])

            setStoresType(classifyByClasses)

        }

    }, [classifyFlag])


    const setOptionByDefault = (e) => {
        setOption(e);
    }

    const selectAll = () => {
        setOption(options);
        setSelection('all')
    }

    const selectByBranchFilter = (e) => {
        var locations = options.filter(store => e.filter.map(row => row.value).indexOf(store._id) >= 0);
        setOption(locations);
    }

    const selectByDateFilter = (e) => {
        setSelectedDayRange({ from: e.filter.from, to: e.filter.to });
    }




    const getStartDate = (date) => {
        if (date !== undefined && date) {
            let _start = moment(new Date(date.year, date.month - 1, date.day)).startOf("day");
            return _start;
        }

    }

    const getEndDate = (date) => {
        if (date !== undefined && date) {
            let _start = moment(new Date(date.year, date.month - 1, date.day)).endOf("day");
            return _start;
        }

        let _end = moment(new Date(selectedDayRange?.to?.year, selectedDayRange?.to?.month - 1, selectedDayRange?.to?.day)).endOf("day");
    }







    const createDateFilters = () => {
        var dateFilters = {};
        let today = moment(new Date());
        let yesterday = moment(new Date()).subtract(1, "days");
        let thisWeek = moment(new Date()).startOf("week");
        let lastWeek = moment(new Date()).subtract(1, "week").startOf("week");
        let thisMonth = moment(new Date()).startOf("month");
        let lastMonth = moment(new Date()).subtract(1, "month").startOf("month");
        let thisYear = moment(new Date()).startOf("year");
        let lastYear = moment(new Date()).subtract(1, "year").startOf("year");
        dateFilters["today"] = { type: "day", label: "Today", from: getDateObject(today), to: getDateObject(today) };
        dateFilters["yesterday"] = { type: "day", label: "Yesterday", from: getDateObject(yesterday), to: getDateObject(yesterday) };
        dateFilters["thisWeek"] = { type: "week", label: "Current Week", from: getDateObject(thisWeek), to: getDateObject(today) };
        dateFilters["lastWeek"] = { type: "week", label: "Previous Week", from: getDateObject(lastWeek), to: getDateObject(lastWeek.endOf("week")) };
        dateFilters["thisMonth"] = { type: "month", label: thisMonth.format("MMMM"), from: getDateObject(thisMonth), to: getDateObject(today) };
        dateFilters["lastMonth"] = { type: "month", label: lastMonth.format("MMMM"), from: getDateObject(lastMonth), to: getDateObject(lastMonth.endOf("month")) };
        dateFilters["thisYear"] = { type: "year", label: thisYear.format("YYYY"), from: getDateObject(thisYear), to: getDateObject(today) };
        dateFilters["lastYear"] = { type: "year", label: lastYear.format("YYYY"), from: getDateObject(lastYear), to: getDateObject(lastYear.endOf("year")) };
        return dateFilters;
    }

    const getDateObject = (momentObj) => {
        return {
            year: momentObj.year(),
            month: momentObj.month() + 1,
            day: momentObj.date()
        }
    }

    const getDateString = (dateObject, format = "YYYY-MM-DD HH:MM") => {
        return moment(new Date(dateObject?.year, dateObject?.month - 1, dateObject?.day)).startOf("day").format(format);
    }

    const getDifference = (from, to) => {
        if (from && to) {
            var start = moment(new Date(from.year, from.month - 1, from.day)).startOf("day");
            var end = moment(new Date(to.year, to.month - 1, to.day)).endOf("day");
            return Math.abs(end.diff(start, "days"));
        } else {
            return 0;
        }
    }

    const selectDateFilter = (key) => {
        let date = dates[key];
        setSelectedDayRange({ from: date.from, to: date.to });
    }






    const createDateFilter = async () => {
        if (!dateLabel || dateLabel.trim() == 0) {
            dispatch(setError("Label for date filter cannot be empty"));
            return;
        }
        setDateLoader(true);
        let requestData = {
            params: {
                filter: {
                    from: selectedDayRange.from,
                    to: selectedDayRange.to,
                    range: "single"
                },
                label: dateLabel,
                type: "date"
            },
            dispatch
        };
        await createFilterRequest(requestData);
        setDateLabel("");
        setDateFilter(false);
        setDateLoader(false);
    }


    const createBranchFilter = async () => {
        if (!branchLabel || branchLabel.trim() == 0) {
            dispatch(setError("Label for branch filter cannot be empty"));
            return;
        }
        setBranchLoader(true);
        let requestData = {
            params: {
                filter: option.map(row => { return { value: row.value, label: row.label, country: row.country, state: row.state, city: row.city } }),
                label: branchLabel,
                type: "branch"
            },
            dispatch
        };
        await createFilterRequest(requestData);
        setBranchLabel("");
        setBranchFilter(false);
        setBranchLoader(false);
    }

    const localDeleteFilter = async (id) => {
        let requestData = {
            params: {
                id: id
            },
            dispatch
        };
        await deleteFilterRequest(requestData);
        dispatch(deleteFilter(id));
    }


    useEffect(() => {
        if (!loaded) {
            let requestData = {
                dispatch: dispatch
            };
            listFilterRequest(requestData)
        }
    }, [loaded])






    useEffect(() => {
        let _currentDate = new Date();
        if (dateSelection === 'today') {
            if (_currentDate.getDate() != selectedDayRange.from.day || _currentDate.getMonth() + 1 != selectedDayRange.from.month || _currentDate.getFullYear() != selectedDayRange.from.year) {
                setDateSelection('')
            }
        }
    }, [selectedDayRange])

    const branchesMenuCustom = branchFilters.map((val) => {
        return (
            <ListGroupItem key={'dates-' + val._id} className={selection == val._id ? 'active' : ''} tag="a" href="#" onClick={(e) => { e.detail == 2 ? setCustomSelection(true) : selectByBranchFilter(val) }}>
                {val.label}
            </ListGroupItem>
        )
    })


    const dates = createDateFilters();
    const datesMenu = Object.keys(dates).map((key) => {
        if (props.type === 'silver') {
            if (getDifference(dates[key].from, dates[key].to) === 0) {
                return (
                    <ListGroupItem key={'dates-' + key} className={dateSelection == key ? 'active' : ''} tag="a" href="#" onClick={() => { selectDateFilter(key); setDateSelection(key) }}>
                        {dates[key].label}
                    </ListGroupItem>
                )
            }
        }
        else {
            return (
                <ListGroupItem key={'dates-' + key} className={dateSelection == key ? 'active' : ''} tag="a" href="#" onClick={() => { selectDateFilter(key); setDateSelection(key) }}>
                    {dates[key].label}
                </ListGroupItem>
            )
        }

    })

    const singleRangeDateFilters = dateFilters.filter((val) => {
        return (val.filter.range === "single")
    })

    /****multipleDateFilters coming from dateFilters by filter out whose range is equal to multiple in filter object ( range==="multiple") */

    const datesMenuCustom = singleRangeDateFilters.map((val) => {
        {
            return (
                <ListGroupItem key={'dates-' + val._id} className={dateSelection == val._id ? 'active' : ''} tag="a" href="#"
                    onClick={() => { selectByDateFilter(val); setDateSelection(val._id) }}
                >
                    {val.label}
                </ListGroupItem>
            )
        }
    })


    /***** Now we used variables and states which is used for Sids,fids ******/

    const [selectedStores, setSelectedStores] = useLocalStorage('selectedStores', options);
    const [selectedFloors, setSelectedFloors] = useLocalStorage('selectedFloors', []);
    const [selectedSids, setSelectedSids] = useLocalStorage('selectedSids', { [options[0]?._id]: options[0]?._id });

    console.log('selectedStores',selectedStores);
    console.log('selectedFloors',selectedFloors);
    console.log('selectedSids',selectedSids);

    let initialFids = {}
    if (options.length > 0 && typeof options[0].floors !== undefined && options[0].floors && options[0].floors.length > 0) {
        initialFids[options[0]?.floors[0]?._id] = options[0]?.floors[0]?._id

    }
    // options[0]?.floors?.forEach((item) => initialFids[item._id] = item._id)
    // const [selectedFids, setSelectedFids] = useLocalStorage('selectedFids', initialFids);
    const [selectedFids, setSelectedFids] = useState(initialFids);

    const isFirstRender = useRef(true);


    // const menu = <StoresByType
    //     options={options}
    //     selectedSids={selectedSids}
    //     setSelectedSids={(output) => setSelectedSids(output)}
    //     selectedFids={selectedFids}
    //     setSelectedFids={setSelectedFids}
    //     selection={selection}
    //     setSelection={(output) => setSelection(output)}
    //     storesType={storesType}
    //     classifyFlag={classifyFlag}
    //     multipleFloors={props.multipleFloors}
    //     multipleStores={props.multipleStores}
    //     showFloors={props.showFloors}
    //     showCities={props.showCities}
    //     setCustomSelection={(output) => setCustomSelection(output)}
    //     setSelectedStores={(output) => setSelectedStores(output)}
    //     selectedStores={selectedStores}
    // />

    const filterTwo = <FilterTwo

        options={options}
        selectedSids={selectedSids}
        setSelectedSids={(output) => setSelectedSids(output)}
        selectedFids={selectedFids}
        setSelectedFids={setSelectedFids}
        storesType={storesType}
        classifyFlag={classifyFlag}
        setSelectedStores={(output) => setSelectedStores(output)}
        setCategory={props.setCategory}
        category={props.category}




    />


    console.log("selectedFids",selectedFids);



    const updateFilter = async (options) => {


        let _option = []
        let sids = [];
        let fids = [];
        let ex_fid = [];

        if (!props.showFloors) {
            if (options.length) {

                let storeObject = await getStoresObject(options)

                sids = storeObject.sids;
                fids = storeObject.includedFids;
                ex_fid = storeObject.exludedFids;
            }
            else {


                let storeObject = await getStoresObject(selectedStores)
                sids = storeObject.sids;
                fids = storeObject.includedFids;
                ex_fid = storeObject.exludedFids;
            }

        }


        var start, end;
        start = await getStartDate(selectedDayRange?.from)
        end = await getEndDate(selectedDayRange?.to)

        let filter = {
            start: start,
            end: end,
            sid: convertObjectIntoArray(selectedSids),
            type: "d",
            fid: convertObjectIntoArray(selectedFids),
            ex_fid: ex_fid,
            excluded: [],
            included: [],
        };
        let _filter = {};
        Object.keys(props.payload ? props.payload : {}).forEach((key) => {
            if (key !== undefined && key && key in filter && filter[key] !== undefined && filter[key]) {
                _filter[key] = filter[key]

            }
        })
        _filter["selectedDayRange"] = selectedDayRange;
        _filter["option"] = selectedStores;
        _filter['classify'] = classifyFlag

        console.log('_filter',_filter);

        props.updateFilter(_filter)
        setShowFilters(false)
    }

    useEffect(() => {
        updateFilter(selectedStores)

    }, [])


    useEffect(async () => {
        console.log('selectedSidsselectedSids',selectedSids,selectedStores);
        if (typeof selectedSids != undefined && selectedSids) {
            let arraysOfSids = await convertObjectIntoArray(selectedSids);
            let _selectedStores = options.filter((store) => arraysOfSids.includes(store._id))
            console.log('_selectedStores',_selectedStores);
            setSelectedStores(_selectedStores);
        }

    }, [selectedSids])


    useEffect(() => {
        if (!showFilters) {
            if (isFirstRender.current) {
                isFirstRender.current = false;
                return; // 👈️ return early if first render
            }
            else {
                updateFilter([])
            }

        }

    }, [selectedDayRange])










    return (
        <Row className='mb-4 mt-1'>
            <Col sm={2} style={{ backgroundColor: '' }} className='p-2 mt-2' >
                <h4 className='m-0 p-0 fw-normal'> {props.pageTitle} </h4>
            </Col>
            <Col className='text-end' md={10}>
                <Row>
                    <Col className='p-2 mt-2 text-end' md={10} style={{ overflow: "hidden" }} >

                        {
                            <>
                                <div className='text-end'>
                                    <div className='' style={{ overflowX: 'scroll', width: '100%' }} id='style-horizontal'>

                                        <SelectedFilter
                                            storeName={props.selected.storeName}
                                            floorName={props.selected.floorName}
                                            selectedDayRange={selectedDayRange}
                                            option={selectedStores}
                                            currentFloors={props.selected.floorName ? selectedFids : {}}
                                            setSelectedDayRange={(output) => setSelectedDayRange(output)}

                                        />
                                    </div>

                                </div>
                            </>
                        }
                    </Col>
                    <Col md={2} className='p-2'>
                        <Dropdown id="filter" className='px-1 py-2' style={{ width: "fit-content", float: "right", whiteSpace: 'nowrap' }} isOpen={showFilters} toggle={toggle} direction='down'>
                            <DropdownToggle nav className='text-dark'>
                                <Filter size="16" /> Filters
                            </DropdownToggle>
                            {
                                <DropdownMenu id="main" style={{ minWidth: "500px", padding: "0px", maxWidth: "1100px", width: "1100px", maxHeight: "515px", marginTop: "5px" }}>
                                    <div style={{ paddingLeft: "0.75rem", paddingRight: "0.75rem" }}>
                                        <Row>
                                            {
                                                !customSelection &&
                                                <Fragment>
                                                    <Col md={4} className="pt-3 border-bottom border-1 " >
                                                        {
                                                            <>
                                                                <div className='d-flex' style={{ width: "100%" }}>
                                                                    <h4 className='filter-heading'>Stores</h4>
                                                                    <div className='' style={{ marginLeft: 'auto', paddingTop: '0.34rem' }}>
                                                                        {Object.keys(classifyByClasses ? classifyByClasses : {}).length > 0 && props.type !== "insights" && <Row className=''>
                                                                            <Col md={6}>
                                                                                <Label htmlFor='classes' className="form-check-label">Class</Label>
                                                                            </Col>
                                                                            <Col md={6} className='mt-1'>
                                                                                <Label className='switch'>
                                                                                    <input className='classInput' type='checkbox' id='classes' name='classes' defaultChecked={classifyFlag === 'grade' && true} onChange={classifyFlag === 'city' ? () => setClassifyFlag('grade') : () => setClassifyFlag('city')}></input>
                                                                                    <span className='sliderr round'></span>
                                                                                </Label>
                                                                            </Col>
                                                                        </Row>}
                                                                    </div>
                                                                </div>
                                                            </>
                                                        }

                                                        <Row >
                                                            <Col md={12} className='scrollbar' id="style-3" >
                                                                <ListGroup>
                                                                    {
                                                                        <>
                                                                            <ListGroupItem className={selection == 'custom' ? 'active' : ''} tag="a" href="#" onClick={() => setCustomSelection(true)}>
                                                                                Custom Selection
                                                                            </ListGroupItem>

                                                                            {props.multipleStores &&
                                                                                <ListGroupItem className={selection == 'all' ? 'active' : ''} tag="a" href="#" onClick={(e) => { e.detail == 2 ? setCustomSelection(true) : selectAll() }}>
                                                                                    All Stores
                                                                                </ListGroupItem>}
                                                                        </>
                                                                    }

                                                                    {
                                                                        // props.type === "default" &&
                                                                        <>
                                                                            {/* {storesPerFloor} */}
                                                                            {/* {menu} */}
                                                                            {filterTwo}
                                                                            {branchesMenuCustom}
                                                                        </>
                                                                    }
                                                                </ListGroup>
                                                            </Col>
                                                        </Row>
                                                    </Col>

                                                    {

                                                        <Col className="pt-3 border-start border-end border-bottom border-1"
                                                            md={4}
                                                        >
                                                            <Row>

                                                                <Col md={12}


                                                                    className=" border-start border-end  border-1  ">
                                                                    <h4 className='filter-heading'>{props.type === 'insights' ? "Features" : props.type === 'brown' ? "Time Range" : "Date Filters"}</h4>
                                                                    <div className='scrollbar ' id="style-3" style={{ width: "100%", overflowX: 'hidden' }}>
                                                                        <ListGroup flush >
                                                                            {
                                                                                datesMenu
                                                                            }
                                                                            {
                                                                                datesMenuCustom
                                                                            }

                                                                        </ListGroup>
                                                                    </div>
                                                                </Col>
                                                            </Row>

                                                        </Col>
                                                    }
                                                </Fragment>
                                            }

                                            {
                                                customSelection &&
                                                <Col md={8}
                                                    className="pt-3 border-bottom border-end border-1" style={{ maxHeight: '408px' }}>

                                                    {
                                                        !deleteFilters &&
                                                        <div>
                                                            <h4 className='filter-heading'> <ArrowLeft style={{ color: colors.primary, cursor: "pointer", marginLeft: "-5px" }} onClick={() => setCustomSelection(false)} /> Customize Selection</h4>
                                                            <Row className='mb-3' >
                                                                {
                                                                    branchFilter &&
                                                                    <Col>
                                                                        <InputGroup>
                                                                            <Input ref={branchRef} size="sm" type='text' onChange={(e) => setBranchLabel(e.target.value)} placeholder='Filter name. For example, Top Branches' />
                                                                            <Button size="sm" color="outline-danger" onClick={() => setBranchFilter(false)} disabled={branchLoader}><XCircle size={16} /></Button>
                                                                            <Button size="sm" color="outline-primary" onClick={() => createBranchFilter()} disabled={branchLoader}><Bookmark size={16} /> Save Filter</Button>
                                                                        </InputGroup>
                                                                    </Col>
                                                                }
                                                                {
                                                                    !branchFilter &&
                                                                    <Col className='text-end'>
                                                                        <Button size="sm" color="outline-primary" onClick={() => setBranchFilter(true)}><Plus size={16} /> Create Branch Filter</Button>
                                                                    </Col>
                                                                }
                                                            </Row>
                                                            <div className='mb-3'>
                                                                <Select options={options} value={option} onChange={setOptionByDefault} isMulti styles={colourStyles} />
                                                            </div>
                                                        </div>
                                                    }
                                                    {
                                                        deleteFilters &&
                                                        <div>
                                                            <h4 className='filter-heading'> <ArrowLeft style={{ color: colors.primary, cursor: "pointer", marginLeft: "-5px" }} onClick={() => { setDeleteFilters(false); props.type != "default" && setCustomSelection(false) }} /> Delete Filters</h4>
                                                            <Row className='mb-3 ' id='wrapper' >
                                                                <Col className="scrollbar" id="style-3">
                                                                    <ListGroup className="force-overflow">
                                                                        {
                                                                            branchFilters.length == 0 && dateFilters.length == 0 &&
                                                                            <ListGroupItem
                                                                                disabled
                                                                                href="#"
                                                                                tag="a"
                                                                            >
                                                                                No Custom Filters Available
                                                                            </ListGroupItem>
                                                                        }
                                                                        {
                                                                            branchFilters.length > 0 &&
                                                                            <ListGroupItem
                                                                                disabled
                                                                                href="#"
                                                                                tag="a"

                                                                            >
                                                                                Branch Filters ( {branchFilters.length} )
                                                                            </ListGroupItem>
                                                                        }
                                                                        {
                                                                            branchFilters.map((row, index) => {
                                                                                return (
                                                                                    <ListGroupItem key={"delete-branch-filter-" + index}>
                                                                                        {row.label} <Button size='sm' color='outline-danger' style={{ float: "right", padding: "2px 5px" }} onClick={() => { localDeleteFilter(row._id) }}><Trash2 size={14} /></Button>
                                                                                    </ListGroupItem>
                                                                                );
                                                                            })
                                                                        }

                                                                        {
                                                                            dateFilters.length > 0 &&
                                                                            <ListGroupItem
                                                                                disabled
                                                                                href="#"
                                                                                tag="a"

                                                                            >
                                                                                Date Filters ( {dateFilters.length} )
                                                                            </ListGroupItem>
                                                                        }
                                                                        {
                                                                            dateFilters.map((row, index) => {
                                                                                return (
                                                                                    <ListGroupItem key={"delete-date-filter-" + index}>
                                                                                        {row.label} <Button size='sm' color='outline-danger' style={{ float: "right", padding: "2px 5px" }} onClick={() => { localDeleteFilter(row._id) }}><Trash2 size={14} /></Button>
                                                                                    </ListGroupItem>
                                                                                );
                                                                            })
                                                                        }
                                                                    </ListGroup>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    }
                                                </Col>
                                            }

                                            {<Col md={4} className='border-bottom border-1'>
                                                <div className='' style={{ width: "", marginLeft: '13rem', position: 'absolute', zIndex: 100 }}>


                                                    <div className='' style={{ marginLeft: '', paddingTop: '0.34rem' }}>
                                                        <Row className=''>
                                                            <Col md={8}>
                                                                <Label htmlFor='islamic-calendar' className="form-check-label">Islamic</Label>
                                                            </Col>
                                                            <Col md={4} className='mt-1'>
                                                                <Label className='switch'>
                                                                    <input className='classInput' type='checkbox' id='islamic-calendar' name='islamic-calendar' defaultChecked={calendarType === 'islamic' && true} onChange={calendarType === 'islamic' ? () => setCalendarType('english') : () => setCalendarType('islamic')}></input>
                                                                    <span className='sliderr round'></span>
                                                                </Label>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </div>


                                                <Row style={{ padding: 0, margin: 0, marginTop: '5px' }}>
                                                    <Col md={12} style={{ padding: 0, margin: 0 }} >
                                                        {calendarType === "islamic" &&
                                                            <HijriCalendar
                                                                selectedDayRange={selectedDayRange}
                                                                setSelectedDayRange={(output) => setSelectedDayRange(output)}
                                                            />

                                                        }



                                                        {calendarType === "english" && <Calendar
                                                            value={selectedDayRange}
                                                            onChange={setSelectedDayRangeFromCalendar}
                                                            colorPrimary={colors.primary}
                                                            colorPrimaryLight={colors.highlight}
                                                            maximumDate={getDateObject(moment(new Date()))}
                                                        />}
                                                    </Col>
                                                </Row>
                                                <Row className='mb-2' >
                                                    {
                                                        dateFilter &&
                                                        <Col>
                                                            <InputGroup>
                                                                <Input ref={dateRef} size="sm" type='text' onChange={(e) => setDateLabel(e.target.value)} placeholder='Ex. Ramadan 2022' />
                                                                <Button size="sm" color="outline-danger" onClick={() => setDateFilter(false)} disabled={dateLoader}><XCircle size={16} /></Button>
                                                                {
                                                                    // (props.compareType === 'single' && props.type != "premium") &&
                                                                    <Button size="sm" color="outline-primary" onClick={() => createDateFilter()} disabled={dateLoader}><Bookmark size={16} /> Save </Button>
                                                                }
                                                                {/* {props.compareType === 'single' && <Button size="sm" color="outline-primary" onClick={() => createMultipleDateFilter()} disabled={dateLoader}><Bookmark size={16} /> Save multipleDate </Button>
                                                            } */}
                                                            </InputGroup>
                                                        </Col>
                                                    }
                                                    {
                                                        ((!dateFilter && props.type != "insights" && props.type !== "silver" && props.type !== "compareMultiple") || (props.type === "compareMultiple" && !deleteFilters && !dateFilter)) &&
                                                        //  props.type !== "brown" &&
                                                        <Col className='text-end'>
                                                            {(dateFilters.length > 0) && <Button size="sm" color="light mx-2" style={{ color: colors.primary }} onClick={() => { setDeleteFilters(true); setCustomSelection(true) }}>View Filters</Button>}
                                                            <Button size="sm" color="outline-primary" onClick={() => setDateFilter(true)}><Plus size={16} /> Create Date Filter</Button>
                                                        </Col>
                                                    }
                                                </Row>
                                            </Col>}
                                        </Row>
                                        <Row>
                                            <Col md={4} className='p-2 py-3'>
                                                {Object.keys(classifyByClasses ? classifyByClasses : {}).length > 0 && <span className='d-block small opacity-30 ' style={{}}>To view industry average please select any class.</span>}
                                            </Col>
                                            <Col md={6} className='text-end p-2 py-3'>
                                                {
                                                    <>
                                                        {!Object.keys(selectedSids ? selectedSids : {}).length && <span className='d-block small opacity-30' >Please select at least one store</span>}

                                                    </>}
                                            </Col>

                                            <Col md={2} className='text-end p-2'>
                                                {
                                                    // props.type === 'default' &&
                                                    <Button color="primary" className='w-100' title={"Please Select at least one store"} disabled={!Object.keys(selectedSids ? selectedSids : {}).length} onClick={() => updateFilter([])}>Search</Button>
                                                }

                                            </Col>
                                        </Row>
                                    </div>
                                </DropdownMenu>}
                        </Dropdown>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

export default NewMainFiltrer;