import React from 'react'
import "../MainFilter.css"
import "../../../App.css"
import { Button ,Row ,Col} from 'reactstrap'

export default function FilterFooter({warning, disabled, onClick, search}) {
    return (
        <div>
            <Row>
                <Col md={4}>
                </Col>
                <Col md={6} className='text-end p-2 py-3'>
                    {disabled && <span className='d-block small opacity-30'>{warning}</span>}
                </Col>
                <Col md={2} className='text-end p-2'>
                    <Button color="primary" className='w-100 btn-primary btn' title={"Please Select at least one store"} disabled={disabled} onClick={() => onClick([])}>{search}</Button>
                </Col>
            </Row>
        </div>
    )
}
