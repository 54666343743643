import React, { Fragment, useEffect, useState } from 'react'
import ReactECharts from 'echarts-for-react';
import * as echarts from 'echarts'

export default function AnuallyHeatmapCalendar({ seriesData, max, min, range ,heatmapView}) {
    const [options, setOption] = useState()


    


    useEffect(() => {
        let option = {
            title: {
                top: 30,
                left: 'center',
                text: 'Daily Step Count'
            },
            grid: {
                height: '70%',
                top: '10%'
            },
            tooltip: {
                position: 'top',
                formatter: function (p) {
                    var format = `<span style="color:${p.color}; margin-right:10px;">\u2B24</span>` + echarts.format.formatTime('yyyy-MM-dd', p.data[0]);
                    return  format + ' : ' + `<span style="font-weight:bold">${p.data[1]}</span>` ;
                }
            },
            // xAxis: {
            //     type: 'category',
            //     data: axis,
            //     splitArea: {
            //         show: true
            //     }
            // },
            visualMap: {
                min: min ,
                max: max > 8 ?   max + 10 - max % 10 : max ,
                calculable: true,
                type: 'piecewise',
                orient: heatmapView,
                left: 'center',
                top: 65
            },

            calendar: {
                orient: heatmapView,
                top: 120,
                left: 30,
                right: 30,
                cellSize: ['auto', 13],
                range: range,
                itemStyle: {
                    borderWidth: 0.5
                },
                yearLabel: { show: false }
            },
            series: {
                type: 'heatmap',
                coordinateSystem: 'calendar',
                data: seriesData
            }
        };
        setOption(option)

    }, [seriesData, max, min])

    return (
        <Fragment>
            {options != undefined &&
                <ReactECharts
                    option={options}
                    style={{ maxHeight: '15rem' }}
                />
            }
        </Fragment>
    )
}
