import { actionTypes } from "../constants/action-types"

const initialState = {
    spaces : [],
    space : null
}

export const spacesReducer=(state=initialState, {type, payload={}})=>{
    console.log('type is',type, 'and payload===',payload);
    switch(type){
        case actionTypes.GET_SPACES :
           return {
               ...state, spaces : payload
           }
           case actionTypes.SET_SPACE :
            console.log('reducer running',payload);
               return{
                  ...state, space : payload
               }
           default :
           return state;
    }
}

