import React, { useState, useEffect } from 'react';
import { ArrowDown, ArrowUp, ChevronDown, ChevronUp, Info } from 'react-feather';
import { Card, CardBody, Tooltip, UncontrolledTooltip } from 'reactstrap';
import { numberFormater } from '../../helpers/common';
import { adlyticFormatter, numberFixed, numberFixedToOne } from '../../helpers/utils';
import './SummaryCard.css'


const SummaryImputeCardCpmpact = (props) => {
    const tag = "CARD";
    const [percentage, setPercentage] = useState(0);
    const [isHovering, setIsHovering] = useState(false);
    const tooltipId = `navbar-${props.index}`;




    const toggle = () => {
        setIsHovering(!isHovering)
    }


    useEffect(() => {
        if (props.previous == null || props.previous == 0) {
            setPercentage(0);
        } else {
            setPercentage((((props.current - props.previous) / props.previous) * 100).toFixed(1))
        }
    }, [props])



    return (
        <Card className="w-100 summaryCard"
        // style={{display: "inline-block", float: "none", /*maxWidth: "270px",*/ marginRight: "10px", minWidth: "150px" }} 
        >
            {/* <Card className='mx-2 mb-3' style={{maxWidth: "250px", float: "left"}}> */}
            <CardBody className=''>
                
                <h6 className='m-0 pb-2 secondary fz-12' style={{ fontSize: "", fontWeight: "400", }}>
                    {props.title === 'DWELLTIME' ? props.title + ' (Min)' : props.title}
                    <span className={percentage < 0 ? "m-0 p-0 danger" : percentage == 0 ? "m-0 p-0 secondary" : "m-0 p-0 success"} style={{ fontSize: "12px", float: "right" }}>
                        {
                            percentage < 0 &&
                            <ChevronDown style={{ marginTop: "-3px" }} className="danger" size={16} />
                        }
                        {
                            percentage > 0 &&
                            <ChevronUp style={{ marginTop: "-3px" }} className="success" size={16} />
                        }
                        <span>{props.previous == 0 ? "0%" : Math.abs(percentage) + "%"}</span>
                    </span>
                </h6>

                <h3 className='m-0 p-0 mb-2 fz-20' style={{ fontSize: "24px", fontWeight: "500" }}>
                    {adlyticFormatter(props.current)}
                </h3>

                <p className='m-0 p-0 secondary fz-10' style={{ fontSize: "12px" }}>
                    Previous {`  `}
                    <span className='primary'>{adlyticFormatter(props.previous)}</span>

                    {
                        props.imputeFlag &&
                        <>
                            <span data-togg className='' style={{ float: 'right' }}
                                id={tooltipId}
                            >
                                <Info size={16} />
                            </span>
                            <div>
                                <Tooltip className="customTooltip" placement="top" isOpen={isHovering} target={tooltipId} toggle={toggle}  >
                                    {'Imputed  ' + numberFormater(numberFixedToOne(props.imputedValue)) + '%'}
                                </Tooltip>
                            </div>
                        </>
                    }
                </p>

            </CardBody>
        </Card>
    )
}

export default SummaryImputeCardCpmpact;