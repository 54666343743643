import {actionTypes} from '../constants/action-types'

export const setClientUsers = (payload) => {
    return {
        type: actionTypes.GET_USER,
        payload: payload
    }
}

export const setUserActivity = (payload) => {
    return {
        type: actionTypes.GET_USER_ACTIVITY,
        payload: payload
    }
}



