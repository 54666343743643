import React, { useState, useEffect, Fragment } from 'react'
import { spacesRequest } from '../helpers/requests'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Label } from 'reactstrap';
import { storage } from "../helpers/common";
import 'font-awesome/css/font-awesome.css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {} from '@fortawesome/fontawesome-svg-core';
import {faMarsDouble,faHourglass, faWalking, faMaskFace,faClock, faMap, faVenusMars,faShoePrints,faHourglassEmpty} from '@fortawesome/free-solid-svg-icons'

export default function Branches() {
    const spaces = useSelector((state)=> state.spaces.spaces);
    const [ currentIndex, setCurrentIndex ] = useState(0);
    const user = storage.getParsed('user');
    const dispatch = useDispatch();
    const icons = {
        gender: faMarsDouble, footfall: faWalking,
        mask: faMaskFace, dwell_time: faClock,
        heatmap: faMap, areawise_gender: faVenusMars,
        areawise_footfall:faShoePrints, age: faHourglassEmpty,
        gaze_time: faVenusMars, areawise_age : faHourglass
    }

    const reactIcons = {
        gender: "fa fa-mars-double", footfall: "fa fa-person-walking",
        mask: "fa fa-mask-face", dwell_time: "fa fa-clock-nine",
        heatmap: "fa fa-map", areawise_gender: "fa fa-venus-mars",
        areawise_footfall: "fa fa-shoe-prints", age: "fa fa-hourglass-empty",
        gaze_time: 'fa fa-shoe-prints'
    }

    useEffect(() => {
        spacesRequest({ dispatch, client_id: user?.client_id[0]?._id })
    }, [1])

    return (
        <Fragment>
            <Row>
                <Col md={3}>
                    <h4 className='px-3 '>Branches</h4>
                    <Row>
                        <Col className='px-3 pt-3'>
                            {spaces?.map((item, index) => (
                                <div className='list-group list-group-checkable m-1' key={index} onClick={() => setCurrentIndex(index)}>
                                    <label className={currentIndex === index ? "list-group-item py-3 activeUser userHover" : " list-group-item py-3 userHover"} style={{ cursor: "pointer" }} >
                                        <Row>
                                            <Col md={2}>
                                                {item.image &&
                                                    <img src={item.image} style={{ height: '40px', width: '40px', borderRadius: '50%', overflow: 'hidden', backgroundColor: 'white' }} />
                                                }
                                            </Col>
                                            <Col className='px-3'>
                                                {item.label}
                                                <span className="d-block small opacity-50" >{item.status === 1 ? 'Active' : 'Inactive'}</span>
                                            </Col>
                                        </Row>
                                    </label>
                                </div>
                            ))}
                        </Col>
                    </Row>
                </Col>
                <Col md={8} className='py-3 column '  >
                    <Row className='p-3'>
                        <Col md={7}>
                            {spaces.length >= 0 &&
                                <>
                                    <h4 className='px-3'>{spaces[currentIndex]?.label}</h4>
                                    <span className="d-block small opacity-50 px-3" >{spaces[currentIndex]?.address},{spaces[currentIndex]?.city},{spaces[currentIndex]?.country} </span>
                                </>
                            }
                        </Col>

                        <Col md={5}>
                            {spaces.length >= 0 &&
                                <Label className='px-5 py-4' style={{ backgroundColor: '' }}> <span className='px-2 ' style={{ backgroundColor: '#e88131', borderRadius: '4px', color: 'white' }}>{spaces[currentIndex]?.floors.length}</span> {' '}Floors</Label>}
                            {
                                spaces.length >= 0 &&
                                <Label className=' py-4'><span className='px-2  ' style={{ backgroundColor: '#e88131', borderRadius: '4px', color: 'white' }}>{spaces[currentIndex]?.cameras}</span>{' '}Cameras</Label>
                            }
                        </Col>
                    </Row>
                    <hr></hr>
                 
                    <Row className='px-3'>
                        <Col>
                            <h4 className='px-3 py-2'>Features </h4>
                            <div className='d-flex flex-wrap '>
                                {spaces.length >= 0 && spaces[currentIndex]?.features &&

                                    Object.keys(spaces[currentIndex]?.features)?.map((item, index) => (
                                        <div className='list-group list-group-checkable  m-1 px-2' style={{ width: '250px' }} key={index}>
                                            <label className={currentIndex === index ? "list-group-item py-3 px-1 userHover" : " list-group-item py-3 px-1  userHover"} style={{ cursor: "pointer" }} >
                                                <Row>
                                                    <Col md={4} className='px-3'>
                                                        <div className='justify-content-center mt-0 p-2'  style={{border : '1px solid #e88131', borderRadius : '5px',textAlign: 'center' }}>
                                                            <FontAwesomeIcon icon={icons[item]} color='#e88131' size ='2x' />
                                                        </div>
                                                    </Col>
                                                    <Col className='px-3'>
                                                        {item}
                                                        <span className="d-block small opacity-50" >{item.status === 1 ? 'Active' : 'InActive'}</span>
                                                    </Col>
                                                </Row>
                                            </label>
                                        </div>
                                    
                                    ))
                                }

                            </div>

                        </Col>
                    </Row>
                </Col>
            </Row >
        </Fragment >
    );
}
