import { actionTypes } from "../constants/action-types"



export const getSectionsOfEmploye = (payload) => {
    return {
        type: actionTypes.GET_SECTIONS_EMPLOYEE,
        payload: payload
    }
}

export const getGraphOfEmployeActivity = (payload) => {
    return {
        type: actionTypes.GET_GRAPH_OF_EMPLOYEE_ACTIVITY,
        payload: payload
    }
}

export const setGraphLoader = (payload) => {
    return {
        type: actionTypes.EMP_ACT_GRAPH_LOADER,
        payload: payload
    }
}

export const setTimelineLoader = (payload) => {
    return {
        type: actionTypes.TIMELINE_LOADER,
        payload: payload
    }
}

export const setTimelineSection = (payload) => {
    return {
        type: actionTypes.EMP_ACT_TIMELINE,
        payload: payload
    }
}

export const setCustomerTimeline = (payload) => {
    return {
        type: actionTypes.CUS_TIMELINE,
        payload: payload
    }
}






