import React, { useEffect, useState } from 'react'
import echarts from 'echarts/lib/echarts';
// import ReactEchartsCore from 'echarts-for-react/lib/core';
import ReactECharts from 'echarts-for-react';
import 'echarts/lib/chart/bar';
import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/legend';


export default function SimpleBarGraph(props) {


    const [option, setOption] = useState();

    const getOption = (data,labels) => {
        return  {
            xAxis: {
                type: 'category',
                data: labels
            },
            yAxis: {
                type: 'value'
            },
            tooltip : {
                confine : true
            },
            series: [
                {
                    data: data,
                    type: 'bar',
                    barMaxWidth : 20,
                    itemStyle : {
                        color : "#e89f31",
                        width : 2
                    }
                  
                    
                }
            ]
        };
    }





    useEffect(() => {

        if (props.seriesData.length > 0 && props.labels.length > 0) {
            setOption(getOption(props.seriesData, props.labels))

        }

    }, [props.seriesData, props.labels])


    return (
        <div>
            {typeof option !== undefined && option &&<ReactECharts
                echarts={echarts}
                option={option}
                style={{ minHeight: '8.75rem', height: '20rem' }}
            />}



        </div>
    )
}
