import React, { useContext, useState, Fragment, useEffect } from 'react';
import { Row, Col, Card, CardBody, CardFooter, Button, CardHeader } from 'reactstrap';
import echarts from 'echarts/lib/echarts';
import ReactECharts from 'echarts-for-react';
import { getPosition, getGrays, colors, rgbaColor, isIterableArray, capitalize, graphColors } from '../../helpers/utils';
import { BarChart2, Hash, Layout, PieChart, Star, TrendingUp, Delete } from "react-feather";
import 'echarts/lib/chart/bar';
import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/legend';
import { baseUrl } from '../../helpers/meta';
import DeleteModal from '../modals/DeleteModal';


const getNumbers = (percentage) => {
    return `<h6 className='m-0 pb-2 secondary' style={{ fontSize: "12px", fontWeight: "400" }}>
        <span className={${percentage} < 0 ? "m-0 p-0 danger" : ${percentage} == 0 ? "m-0 p-0 secondary" : "m-0 p-0 success"} style={{ fontSize: "12px", float: "right" }}>
            {
                ${percentage} < 0 &&
                <>
                    <ChevronDown style={{ marginTop: "-3px" }} className="danger" size={16} />
                </>
            }
            {
                ${percentage} > 0 &&
                <ChevronUp style={{ marginTop: "-3px" }} className="success" size={16} />
            }
            {/* <span>{props.previous == 0 ? "0%" : Math.abs(${percentage}) + "%"}</span> */}
        </span>
    </h6>`
}

const weatherIcons = {
    Sunny: baseUrl + '/data/asset/img/weather/sunny_128.png',
    Cloudy: baseUrl + '/data/asset/img/weather/cloudy_128.png',
    Showers: baseUrl + '/data/asset/img/weather/showers_128.png'
};


const getOption = ({ seriesData, graphType, showPercentage }) => {
    return {
        tooltip: {
            trigger: 'axis',
            padding: [10, 10],
            height: '100px',
            backgroundColor: "white",
            borderColor: "#d5d5d5",
            borderWidth: 1,
            textStyle: { color: colors.dark },
            transitionDuration: 0,
            position: 'top'
        },
        plugins: {
            datalabels: {
                display: ctx => {
                    return true;
                },
                formatter: (ctx, data) => {
                    return `${data.dataIndex}`;
                }
            }
        },
        xAxis: {
            type: 'category',
            data: seriesData.labels,
            boundaryGap: true,
            axisPointer: {
                lineStyle: {
                    color: "#d5d5d5",
                    type: 'dashed',
                    size: "14px"
                },

            },
            splitLine: { show: false },
            axisLine: {
                lineStyle: {
                    color: rgbaColor('#111', 0.01),
                    type: 'dashed'
                }
            },
            axisTick: { show: false },
            axisLabel: {
                color: "#111",
                formatter: function (value) {
                    return `${value}`;
                },
                margin: [6],
                rotate: 0,
            }
        },
        yAxis: {
            type: 'value',
            axisPointer: { show: false },
            splitLine: {
                lineStyle: {
                    color: "#d5d5d5",
                    type: 'dashed'
                }
            },
            boundaryGap: true,
            axisLabel: {
                show: true,
                color: "#000",
                margin: 8
            },
            axisTick: { show: false },
            axisLine: { show: false }
        },

        series: [
            {
                data: seriesData.data,
                type: graphType,
                label: {
                    padding: [0, 0, -10, 50],
                    position: 'top',
                    show: showPercentage && graphType === "bar" ? true : false,
                    rotate: 90,
                    margin: 50,
                    formatter: function (params) {
                        if (params.dataIndex > 0) {
                            return ((seriesData.data[params.dataIndex] - seriesData.data[params.dataIndex - 1]) / seriesData.data[params.dataIndex - 1] * 100).toFixed(0) + '%'
                        }
                        else {
                            return ''
                        }
                    }

                },
            },


        ],
        color: graphColors.primary,
        animationDuration: 1000,
        grid: { right: '28px', left: '60px', bottom: 40 + '%', top: '15%' }

    }
}

const InsightsLinegraph = (props) => {

    const [options, setOption] = useState(null);
    const [modal, setModal] = useState(false)

    const onChartReady = (echarts) => {
        echarts.hideLoading();
    }

    const [graphType, setGraphType] = useState("line");

    const summaryMenu = [
        ["line", <TrendingUp size={16} />],
        ["bar", <BarChart2 size={16} />],
    ];


    useEffect(() => {

        setOption(getOption({ seriesData: props.seriesData, graphType: graphType, showPercentage: props.showPercentage }));
    }, [props, graphType])



    return (
        <Fragment>
            <Card style={{ backgroundColor: '', overflow: '', border: 'none' }}>
                <CardHeader className="bg-white" style={{ borderBottom: "1px solid " }}>
                    {/* <Row>
                        <Col>
                        </Col>
                    </Row> */}
                    <div className='d-flex'>
                        {props.custom &&

                            <div className='p-3  m-0 pb-2 secondary' style={{ width: '10%', cursor: 'pointer', fontSize: "12px", fontWeight: "400" }}
                                onClick={() => setModal(true)} >
                                <Delete size={16} />
                            </div>
                        }
                        <div style={{ width: `${props.custom ? '80%' : "90%"}` }}>
                            <h6 className='m-2'>{props.title}</h6>
                        </div>
                        <div className='text-end d-flex m-2' style={{ width: '10%', textAlign: 'end' }}>
                            {summaryMenu.map((val, index) => {
                                return (
                                    <Button
                                        href={"#toggle-tab-" + val[0]}
                                        color={
                                            graphType == val[0]
                                                ? "outline-primary"
                                                : "outline-light"
                                        }
                                        className="section-tab"
                                        size="sm"
                                        onClick={() => setGraphType(val[0])}
                                        active={graphType == val[0] ? true : null}
                                        key={"compare-tab-" + index}
                                    >
                                        {val[1]}
                                    </Button>
                                );
                            })}
                        </div>

                    </div>

                </CardHeader>
                <CardBody style={{ backgroundColor: '', overflow: '' }}>
                    {options != undefined && <ReactECharts echarts={echarts} option={options}
                        style={{ minHeight: '', height: "16.75rem" }}
                    />}
                    {!props.seriesData.data?.length &&
                        <span className='className="d-block small opacity-50"'><i>There is no data on selected date</i></span>
                    }
                </CardBody>
                <DeleteModal modal={modal} setModal={(output) => setModal(output)} delete={() => props.deleteCustom(props.index_id)()} />

            </Card>
        </Fragment>
    )
}

export default InsightsLinegraph;

