import React, { Fragment, useState, useEffect } from 'react';
import { EyeOff, Eye } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { logo } from '../helpers/meta';
import { loginRequest } from '../helpers/requests';
import '../App.css'
import FindAccount from './Templates/FindAccount';
import useEnterKeyListener from './Templates/customHooks/useEnterKeyListener';
import ConnectionLost from '../components/tables/ConnectionLost';

const Login = () => {
    useEnterKeyListener({
        querySelectorToExecuteClick: "#submitButton"
    })
    const [passwordShown, setPasswordShown] = useState(false);
    const togglePasswordVisiblity = () => {
        setPasswordShown(passwordShown ? false : true);
    };
    const tag = "LOGIN";
    let navigate = useNavigate();
    let location = useLocation();
    const highlights = useSelector(state => state.highlights);
    const dispatch = useDispatch();
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [forgetPassword, setForgetFlag] = useState(false);
    const [navigators, setNavigator] = useState(navigator.onLine)
    let to = "/";
    const login = async () => {
        var requestData = {
            params: {
                email: email,
                password: password
            },
            dispatch
        }
        var res = await loginRequest(requestData);
        if (res.status) {
            navigate(to);
        }
    }
    useEffect(() => {
        window.addEventListener("online", () => setNavigator(true))
        window.addEventListener("offline", () => setNavigator(false))
    }, [window.navigator.onLine])

    return (
        <Fragment>
            {!forgetPassword && <div className='text-center login-container'>

                {/* <ToastContainer /> */}
                <ConnectionLost />
                <main className="form-signin">
                    <img className="mb-4" src={logo} alt="" width="250" height="auto" />
                    <h1 className="h3 mb-1 fw-normal">Footfall Skill
                    </h1>
                    <h1 className="h6 mb-3 fw-normal">Sign in</h1>

                    <div className="form-floating">
                        <input type="email" className="form-control" id="floatingInput" placeholder="name@example.com" onChange={(e) => setEmail(e.target.value)} />
                        <label>Email address</label>
                    </div>
                    <div className="form-floating mb-3 input-group">
                        <input type={passwordShown ? "text" : "password"} className="form-control" id="floatingPassword" placeholder="Password" onChange={(e) => setPassword(e.target.value)} />
                        <label >Password</label>
                        <i style={{ zIndex: '1', marginLeft: '-35px', marginRight: '10px', display: 'flex', placeItems: 'center', cursor: 'pointer' }}>
                            {passwordShown ? <EyeOff onClick={togglePasswordVisiblity} /> : <Eye onClick={togglePasswordVisiblity} />}

                        </i>
                    </div>
                    {
                        highlights.isError &&
                        <>
                            <div className="alert alert-danger" role="alert" >
                                {highlights.error}
                            </div>
                            <span className="d-block small opacity-50 text-right" style={{ cursor: 'pointer' }} onClick={() => setForgetFlag(true)} >Forgotten password?</span>

                        </>
                    }
                    {
                        highlights.isSuccess &&
                        <div className="alert alert-success" role="alert">
                            {highlights.success}
                        </div>
                    }
                    <button className="w-100 btn btn-lg btn-warning my-2"
                        id='submitButton'
                        onClick={() => login()} disabled={!navigators}
                    >
                        {highlights.loading ? "Wait..." : "Signin"}
                    </button>
                    <p className="mt-5 mb-3 text-muted">&copy; 2020 - {new Date().getFullYear()}</p>
                </main>

            </div>}
            {
                forgetPassword &&

                <FindAccount setForgetFlag={(output) => setForgetFlag(output)} />

            }
        </Fragment>
    )
}

export default Login;