import React, { useState } from 'react'
import { Dropdown, DropdownToggle, ListGroupItem, DropdownMenu, ListGroup } from 'reactstrap'
import "../filters/MainFilter.css"
import { BsCircleFill } from 'react-icons/bs';

export default function FilterDropDown({ firstTitle = '',
    option = [],
    selection = '',
    selectAll,
    locationList = [],
    customList = [],
    filterHeader,
    allLocations = false,
    header = false,
    selectAllStoreFlaf = false,
    selectAllStores,
    inputTypeAll = false

}) {

    const [isOpen, setIsOpen] = useState(false);
    console.log("selectAllStoreFlaf", inputTypeAll);
    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };



    return (

        <div className='dropdown-container' >
            <Dropdown isOpen={isOpen} toggle={toggleDropdown} direction='down'>
                <DropdownToggle className="dropdown-button" >
                    <div className="select_Mbl_div">
                        <BsCircleFill size={12} color={option.length ? "#e86531" : '#e89f31'} />
                        <div className="about_filter">
                            {firstTitle}
                        </div>
                    </div>
                </DropdownToggle>
                <DropdownMenu className='mt-2' style={{ width: "81%", marginLeft: "10px" }}>
                    {header && filterHeader}
                    <ListGroup>
                        {
                            inputTypeAll &&
                            <ListGroupItem
                                className={selectAllStoreFlaf ? 'storeItemActive p-1' : 'storeItem p-1 '} style={{ cursor: 'pointer' }}
                                defaultChecked={selectAllStoreFlaf ? false : true} onClick={(event) => selectAllStores(event)}
                            >
                                <input type="checkbox" id='check' className='m-2' defaultChecked={selectAllStoreFlaf} />
                                <lable className='m-1' >Select All</lable>
                            </ListGroupItem>
                        }


                        {allLocations && <ListGroupItem className={selection == 'all' ? 'active' : ''} tag="a" href="#" onClick={(e) => { selectAll() }}>
                            All Stores
                        </ListGroupItem>}
                        <div className='scrollbar ' id="style-3" style={{ width: "100%", overflowX: 'hidden' }}>

                            {locationList}
                            {customList}
                        </div>

                    </ListGroup>

                </DropdownMenu>
            </Dropdown>


        </div>










    )
}
