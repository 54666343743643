import { Row, Col, Card, CardBody, ButtonGroup, Button, Progress, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { CustomInput, Form, FormGroup, Input, Label, } from 'reactstrap';
import React, { useState, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner, Toast, ToastBody, ToastHeader } from 'reactstrap';
import { changeClientPassword, updateUserpassword, user } from '../../helpers/requests';
import { ImCross } from 'react-icons/im'




export default function ChangePassword(props) {

    const [modal, setModal] = useState(false);
    const [error, setError] = useState('')
    const highlights = useSelector(state => state.highlights);
    const clientsUser = useSelector((state) => state.clientsUser.users);
    const [currentUser, setCurrentUser] = useState();

    const succesMessage = useSelector((state) => state.highlights.success);
    const dispatch = useDispatch();
    const [currentPassword, setCurrentPassword] = useState();
    const [newPassword, setNewPassword] = useState();
    const [confirmPassword, setConfirmPassword] = useState();
    const toggle = () => {
        setModal(!modal)
        props.setModal(!modal)
    }

    useEffect(() => {
        setModal(props.modal);
    }, [props])


    useEffect(() => {
        if (succesMessage) {
            if (succesMessage === "updated") {
                setError('');
                setCurrentPassword('');
                setNewPassword('');
                setConfirmPassword('')

            }
        }


    }, [succesMessage])

    useEffect(() => {
        if(clientsUser !== undefined && clientsUser && props.id !== undefined && props.id){
            let _currentUser = clientsUser.filter((item) => {
                return item._id === props.id
            })
            setCurrentUser(_currentUser[0])
        }


    }, [props.id, clientsUser])

    const updatePassword = () => {

        if (newPassword === confirmPassword) {
            setError('')
            updateUserpassword({ dispatch, id: props.id, oldPassword: currentPassword, newPassword: newPassword })
        }
        else {
            setError('new password and confirm password is not matched')
        }
    }

    const changePassordByAdmin = () => {
        if (newPassword === confirmPassword) {
            setError('')
            changeClientPassword({ dispatch, id: props.id, password: newPassword })
        }
        else {
            setError('new password and confirm password is not matched')
        }
    }




    return <div>
        <Modal isOpen={modal} toggle={toggle} centered >
            {/* <ModalHeader >Password Reset</ModalHeader> */}
            <ModalBody className=''>
                <Row>
                    <Col >
                        <div style={{ float: 'right', padding: '10px', cursor: 'pointer' }}>
                            <ImCross color=' #00000030' onClick={toggle} />
                        </div>
                        <div className='p-5' disabled={true}>
                            <div>
                                <h4>Reset Password</h4>
                                <span className="d-block small opacity-50">Fill in the details below to reset your password</span>

                            </div>
                            {
                                user._id === props.id &&
                                <div className='py-4'>
                                    <Label>Current Password </Label>
                                    <Input name='password' type="String" value={currentPassword} onChange={(event) => setCurrentPassword(event.target.value)} />
                                </div>
                            }
                            <div className='py-1'>
                                <Label>New Password </Label>
                                <Input name='password' type="String" value={newPassword} onChange={(event) => setNewPassword(event.target.value)} />
                            </div>
                            <div className='py-4'>
                                <Label>Confirm New Password </Label>
                                <Input name='password' type="String" value={confirmPassword} onChange={(event) => setConfirmPassword(event.target.value)} />
                            </div>
                            <span className="d-block danger small opacity-50" >{error}</span>
                            <span>{succesMessage === "updated" ? "Password Updated" : ''}</span>
                            <div >
                               
                                <Button disabled={user.role === "admin" && currentUser?.role === 'superAdmin'} color="" block className='btn btn-primary my-3' onClick={((user.role === 'admin' || user.role === "superAdmin") && user._id !== props._id) ? changePassordByAdmin : updatePassword} >{highlights.loading ? '...Updating' : 'Save'}</Button>
                                { user.role === "admin" && currentUser?.role === 'superAdmin' &&<span className="d-block small opacity-50">You don't have authority to change user passowrd</span>}

                            </div>
                        </div>
                    </Col>

                </Row>

                {/* <span style={{ paddingLeft: '40px' }}>Current Password </span>
                <input name='password' placeholder='current passowrd' type="String" className='mx-4' onChange={(event) => setCurrentPassword(event.target.value)} />
                <br></br>
                <span style={{ paddingLeft: '65px' }}>New Password </span>
                <input name='password' placeholder='new passowrd' type='string' className='mx-4 my-4' onChange={(event) => setNewPassword(event.target.value)} />
                <br></br>
                <span style={{ paddingLeft: '' }}>New confirm Password</span> <input name='password' placeholder='confirm new passowrd' type='string' className='mx-4' onChange={(event) => setConfirmPassword(event.target.value)} /><br></br>
                <div style={{ float: 'right', paddingRight: '60px', }}>
                    <Button color="" className='btn btn-primary m-3 ' onClick={updatePassword} >Save</Button>
                    <Button color='#c1c1c1' className='btn btn-secondary m-3 pr-5' onClick={toggle}>Cancel</Button>
                </div> */}

            </ModalBody>
        </Modal>
    </div>;
}
