import { actionTypes } from "../constants/action-types";
import moment from 'moment';

const initialState = {
    data: [],
    heatmapSummary : [],
    male: [],
    female: [],
    staff: [],
    filter: {
        type: "today",
        store: null,
        stores: [],
        floor: null,
        floors: [],
        start: moment(new Date()).startOf("day"),
        end: moment(new Date()).endOf("day")
    },
    loader: false
}

export const heatmapReducer = (state = initialState, {type, payload={}}) => {
    switch(type){
        case actionTypes.SET_HEATMAP:
            var staff = [];
            var male = [];
            var female = [];
            return { ...state, data: payload, staff: staff, female: female, male: male};
        case actionTypes.SET_HEATMAP_SUMMARY:
            return { ...state, heatmapSummary : payload}
        case actionTypes.SET_HEATMAP_FILTER:
                return { ...state, filter: payload };
        case actionTypes.SET_HEATMAP_LOADER:
                return { ...state, loader: payload };
        default:
            return state;
    }
}