import {actionTypes} from '../constants/action-types'

export const setFootfall = (payload) => {
    return {
        type: actionTypes.SET_FOOTFALL,
        payload: payload
    }
}
export const setFootfallImpute = (payload) => {
    return {
        type: actionTypes.SET_FOOTFALL_IMPUTE,
        payload: payload
    }
}




export const setMarketAvgValue = (payload) => {
    return {
        type: actionTypes.SET_MARKET_AVG_VALUE,
        payload: payload
    }
}


export const setFootfallGraph = (payload) => {
    return {
        type: actionTypes.SET_FOOTFALL_GRAPH,
        payload: payload
    }
}

export const setFootfallGraph2 = (payload) => {
    return {
        type: actionTypes.SET_FOOTFALL_GRAPH2,
        payload: payload
    }
}

export const setNewFootfallGraph = (payload) => {
    return {
        type: actionTypes.SET_FOOTFALL_GRAPH_IMPUTE,
        payload: payload
    }
}



export const setFootfallFilter = (payload) => {
    return {
        type: actionTypes.SET_FOOTFALL_FILTER,
        payload: payload
    }
}

export const setFootfallLoader = (payload) => {
    return {
        type: actionTypes.SET_FOOTFALL_LOADER,
        payload: payload
    }
}
export const setGrapghData = (payload) => {
    return {
        type: actionTypes.SET_GRAPGH_DATA,
        payload: payload
    }
}

export const setFootfallGraphType = (payload) => {
    return {
        type: actionTypes.SET_FOOTFALL_GRAPH_TYPE,
        payload: payload
    }
}



