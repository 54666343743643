import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardBody, Row, Col, Progress, Table } from "reactstrap";
import MainFilter from '../../components/filters/MainFilter';
import { getUserActivityRequest, user } from '../../helpers/requests';
import { convertMsToHM, getTimeDifference } from '../../helpers/utils';
import { useParams, Link } from "react-router-dom";
import { colors } from '../../helpers/meta';
import ColorDot from '../../components/ColorDot';



export default function UsersActivity() {


    const updateFilter = (filter) => {
        if (user !== undefined && user) {
            getUserActivityRequest({ ...filter, client_id: user.access[0].client_id, dispatch })

        }
    }

    const dispatch = useDispatch();
    const [activeUser, setActiveUser] = useState();
    let userActivities = useSelector((state) => state.clientsUser.userActivities);


    return (
        <div>
            <MainFilter
                pageTitle="Users Activity"
                updateFilter={(e) => updateFilter(e)}
                type="calendar"
                currentView={null}
            ></MainFilter>
            <Row className="mb-3">
                <Col>
                    <Table
                        bordered
                        responsive 
                        style={{ fontSize: "", textAlign: "center" }}
                    >
                        <thead>
                            <tr style={{ topBorder: '' }}>
                                <th>Username</th>
                                <th>Overall Duration</th>
                                <th>Current Session</th>
                                <th>LastSeen</th>
                                <th>Login</th>
                                <th>Logout</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {userActivities !== undefined && userActivities.length > 0 && userActivities.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td><Link to={"/user-history/" + item.user_id} >{item.user_name}</Link></td>
                                        <td>{convertMsToHM( "total" in item ?  item.total : 0)}</td>
                                        <td> {convertMsToHM(item.duration)}</td>
                                        <td>  {item.status === "Logged In" ? "Online" : getTimeDifference(item.logout).response}</td>
                                        <td> {moment(new Date(item.login)).format("MMM DD,yyyy hh:mm a")}</td>
                                        <td> {item.status === "Logged In" ? "-" : moment(new Date(item.logout)).format("MMM DD,yyyy hh:mm a")}</td>
                                        <td>
                                            <ColorDot position='relative' marginBottom={'-0.125rem'} color={item.status === "Logged In" ? colors.success : colors.silver} display={'inline-block'} /><span style={{ marginLeft: "20px" }}>{item.status}</span>
                                        </td>
                                    </tr>
                                )
                            })}

                        </tbody>

                    </Table>
                </Col>
            </Row>

        </div>
    )
}
