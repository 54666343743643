import React, { useState, useEffect } from 'react';
import ReactECharts from 'echarts-for-react';
import { useSelector } from 'react-redux';
import { graphColors } from '../../helpers/meta';
import { Card, CardBody } from 'reactstrap';

const getSpiderOptions = (lengendsData, maxIndicators, seriesData) => {
  return {
    title: {
      text: ''
    },
    tooltip: {
      // position : 'absolute',
      // padding: [10, 10],
      // height: '100px',
      // borderColor: "#d5d5d5",
      confine: true

    },
    legend: {
      data: lengendsData
    },
    color: graphColors,
    radar: {
      // shape: 'circle',
      indicator: maxIndicators
    },
    series: [{
      name: 'Comparison',
      type: 'radar',
      // areaStyle: {normal: {}},
      data: seriesData,
    }]
  };
}

const Spider = ({ dataOptions, summary }) => {
  const dataPoints = {};
  dataOptions.forEach(val => {
    dataPoints[val.value] = val.label;
  });

  const access = useSelector(state => state.auth.access);
  const [option, setOption] = useState(null);
  const loadingOption = {
    text: 'Loading...',
    color: '#4413c2',
    textColor: '#270240',
    maskColor: 'rgba(255, 255, 255, 0.3)',
    zlevel: 0
  };

  const onChartReady = (echarts) => {
    echarts.hideLoading();
  }

  useEffect(() => {
    let current = summary.current;
    let lengendsData = current.map((store => store.label));
    let maxIndicators = [];
    let seriesData = [];
    let maxValues = {};
    Object.keys(dataPoints).forEach((key) => {
      maxValues[key] = 0;
    });

    if (current.length > 0) {
      current.map((row, index) => {
        var _seriesData = {
          name: row.label, value: []};
        Object.keys(dataPoints).forEach((key) => {
          if (typeof row[key] === "string") {

          }
          if (maxValues[key] < row[key]) {
            maxValues[key] = row[key]
          }
          _seriesData.value.push(row[key]);
        });
        seriesData.push(_seriesData);
      });

      Object.keys(maxValues).forEach(key => {
        maxIndicators.push({ name: dataPoints[key], max: maxValues[key] });
      })
      let _option = getSpiderOptions(lengendsData, maxIndicators, seriesData);
      if (JSON.stringify(_option) != JSON.stringify(option)) {
        setOption(getSpiderOptions(lengendsData, maxIndicators, seriesData));
      }
    }

  }, [summary, dataPoints])

  return (
    option !== null &&
    <Card>
      <CardBody>
        <ReactECharts
          option={option}
          style={{ height: 500 }}
          onChartReady={onChartReady}
        />
      </CardBody>
    </Card>
  )
}
export default Spider;