import React from 'react'
import { Fragment } from 'react'
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Carousel, Pagination } from 'antd';
import { Card, CardBody, Col, Row } from 'reactstrap';
import MainFilter from '../components/filters/MainFilter';
import { galleryDeleteRequest, galleryRequest } from '../helpers/requests';
import PictureView from '../components/filters/components/PictureView';
import { humanReadAbleDate } from '../helpers/utils';
import { Crosshair, Delete } from 'react-feather';
import DeleteModal from '../components/modals/DeleteModal';



export default function Gallery() {

    const [filters, setFilters] = useState();
    const dispatch = useDispatch();
    const [gallery, setGallery] = useState([])
    console.log("gallery==", gallery);
    const [currentPicture, setCurrentPicture] = useState();
    const [showImage, setShowImage] = useState(false)
    const [pgObject, setPGObject] = useState({ page: 1, limit: 18, total: 0 })

    const [deleteModal, setDeleteModal] = useState(false);
    const [currentImage, setCurrentImage] = useState()


    const getGallery = async (requestData) => {
        console.log('requestData in body',requestData);

        let _params = JSON.parse(JSON.stringify( {gender :  requestData.body.G, employee : requestData.body.EMP }));
        requestData.body = {
            sid: requestData.body.sid,
            start: requestData.body.start,
            end: requestData.body.end,

        }
        requestData.params = {...requestData.params, ..._params}
     

        let response = await galleryRequest(requestData);
        console.log("response===", response);
        if (response && response.status) {
            console.log('response inside', response.data.docs);
            let _gallery = response.data.docs.sort((a, b) => {
                return new Date(a.T) - new Date(b.T);
            })
            setGallery(_gallery)
            setPGObject({ page: response.data.page, limit: response.data.limit, total: response.data.total })
        }


    }


    const updateFilter = async (e) => {
        let obj = { ...e, }
        console.log('object filter is',obj);
        setFilters(e);
        let _pgObject = { page: 1, limit: 18, total: 0 }
        setPGObject(pgObject)

        let requestData = {
            body: obj,
            dispatch,
            params: _pgObject
        };

        getGallery(requestData);






    };

    const onPageChange = (page) => {

        let requestData = {
            body: filters,
            dispatch,
            params: { ...pgObject, page: page }
        };

        getGallery(requestData)
    }

    const deleteImage = async (item) => {
        console.log("item===", item);

        let obj = {
            dispatch,
            data : item
        }

       let response = await galleryDeleteRequest(obj)
    }

    const _gallery = [
        { url: 'https://awtapi.adlytic.ai/uploads/intrusion/1693403961871I_64ef2fda103a74a3e0c124632023-08-30%2018:59:12.567812.jpg', label: 'one' },
        { url: 'https://awtapi.adlytic.ai/uploads/intrusion/1693403961871I_64ef2fda103a74a3e0c124632023-08-30%2018:59:12.567812.jpg', label: 'one' },
        { url: 'https://awtapi.adlytic.ai/uploads/intrusion/1693403961871I_64ef2fda103a74a3e0c124632023-08-30%2018:59:12.567812.jpg', label: 'one' },
        { url: 'https://awtapi.adlytic.ai/uploads/intrusion/1693403961871I_64ef2fda103a74a3e0c124632023-08-30%2018:59:12.567812.jpg', label: 'one' },
        { url: 'https://awtapi.adlytic.ai/uploads/intrusion/1693403961871I_64ef2fda103a74a3e0c124632023-08-30%2018:59:12.567812.jpg', label: 'one' },
        { url: 'https://awtapi.adlytic.ai/uploads/intrusion/1693403961871I_64ef2fda103a74a3e0c124632023-08-30%2018:59:12.567812.jpg', label: 'one' },
        { url: 'https://awtapi.adlytic.ai/uploads/intrusion/1693403961871I_64ef2fda103a74a3e0c124632023-08-30%2018:59:12.567812.jpg', label: 'one' },
        { url: 'https://awtapi.adlytic.ai/uploads/intrusion/1693403961871I_64ef2fda103a74a3e0c124632023-08-30%2018:59:12.567812.jpg', label: 'one' },
        { url: 'https://awtapi.adlytic.ai/uploads/intrusion/1693403961871I_64ef2fda103a74a3e0c124632023-08-30%2018:59:12.567812.jpg', label: 'one' },
        { url: 'https://awtapi.adlytic.ai/uploads/intrusion/1693403961871I_64ef2fda103a74a3e0c124632023-08-30%2018:59:12.567812.jpg', label: 'one' },

    ]






    return (
        <Fragment>
            <MainFilter
                pageTitle="Gallery"
                noRange={true}
                updateFilter={(e) => updateFilter(e)}
                type="gallery"
                noCalender={true}
                floorToShow={false}

            />

            <Row>
                {gallery &&
                    gallery.map((item, ind) => {
                        const picture = item.url;
                        console.log('picture===', picture);
                        return (
                            <Col sm={6} md={4} lg={4} key={"tab-" + ind} className="mb-5">
                                <Card className={"radius-8"}>
                                    <CardBody className='p-0'>
                                        <Row className='m-0'>
                                            <Col md={12} className='p-0'>
                                                <div
                                                    onClick={() => { setCurrentPicture(picture); setShowImage(true) }}
                                                    style={{
                                                        cursor: "pointer",
                                                        backgroundImage: "url(" + encodeURI(picture) + ")",
                                                        backgroundSize: "cover",
                                                        backgroundPosition: "center center",
                                                        backgroundRepeat: "no-repeat",
                                                        height: "400px",
                                                    }}
                                                ></div>
                                                <div
                                                    className="del-button"
                                                    onClick={(e) => {
                                                        e.stopPropagation();// Prevent the click from propagating to the parent div
                                                        setDeleteModal(true);
                                                        setCurrentImage(item)

                                                    }}
                                                >
                                                    <Delete size={16} />
                                                </div>


                                            </Col>
                                        </Row>
                                        <Row className="" >
                                            <Col style={{ display: "flex", justifyContent: "center" }}>
                                                <span className="badge bg-primary" style={{ fontWeight: "500" }}>{humanReadAbleDate(item.T)}</span>
                                                {/* <span className="badge bg-success" style={{ fontWeight: "500" }}>{item.label}</span> */}
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        );
                    })}




            </Row>

            {
                gallery.length > 0 &&
                <Pagination
                    current={pgObject.page}
                    pageSize={pgObject.limit}
                    total={pgObject.total}
                    onChange={onPageChange}
                    style={{ marginTop: '10px', textAlign: 'right' }}
                    showSizeChanger={false}
                />
            }

            {
                typeof currentPicture !== undefined && currentPicture && showImage &&
                <PictureView isOpen={showImage} toggle={() => setShowImage(!showImage)} image={currentPicture} />
            }

            {
                !gallery.length &&
                <span className='className="d-block small opacity-50"'>
                    <i>No data on selected date please select another date</i>
                </span>
            }

            {
                currentImage && deleteModal &&
                <DeleteModal modal={deleteModal} setModal={(output) => setDeleteModal(output)} delete={() => deleteImage(currentImage)} />
            }


        </Fragment>
    )
}
