import React, { useEffect, useRef, useState } from 'react'
import { Col, Row, Button, Table, Card, ButtonGroup, CardBody, CardHeader, } from 'reactstrap'
import Select from 'react-select'
import MainFilter from '../components/filters/MainFilter'
import { floorRequest, getEmployeRequest, getSectionofEmpoyeRequest, user, getGraphOfEmployeeActivityRequest, getSectionTimelineRequest } from '../helpers/requests'
import { useDispatch, useSelector } from 'react-redux'
import LettersProfile from '../components/Avatar';
import EmployeLineGraph from "../components/charts/EmployeLineGraph"
import { BarChart2 } from 'react-feather'
import LineGraph from '../components/charts/LineGraph'
import moment from 'moment'
import { adlyticFormatter, convertIntoHours, convertMinutesIntoHour, convertMinutesIntoHourInString } from '../helpers/utils'
import GraphCard from '../components/cards/GraphCard'




export default function EmployeActivity() {

    const [activeButton, setActiveButton] = useState({
        label: "Section",
        value: "section"
    })

    const [filter, setFilter] = useState();
    const [dataToShow, setDataToShow] = useState([])
    const [requestFlag, setRequestFlag] = useState(true)
    const dispatch = useDispatch();
    const floors = useSelector((state) => state.floorReducer.floors)
    const [sections, setSections] = useState([])
    const employees = useSelector((state) => state.floorReducer.employee);
    const graph = useSelector((state) => state.employeActivity.graphOfEmploye)
    const [currentEmploye, setCurrentEmploye] = useState()
    const [sectionId, setSectionId] = useState();
    const graphLoader = useSelector((state) => state.employeActivity.graphLoader);
    const timelineLoader = useSelector((state) => state.employeActivity.timelineLoader);

    const timelineData = useSelector((state) => state.employeActivity.timelineSection);
    const customerTimeline = useSelector((state) => state.employeActivity.customerTimeline)
    const timeSpent = useSelector((state) => state.employeActivity.timeSpent);
    const timeSpentChart = useSelector((state) => state.employeActivity.timeSpentChart)
    const employeFrequency = useSelector((state) => state.employeActivity.employeFrequency);
    const empFrqChart = useSelector((state) => state.employeActivity.empFrqChart);
    const tablereference = useRef();
    const [timelineSection, setTimelineSection] = useState();


    const sectionOfEmploye = useSelector((state) => state.employeActivity.sectionOfEmploye);
    const [seriesData, setSeriesData] = useState([]);
    const mainLoader = useSelector((state) => state.highlights.loading)
    const [totalMinutes, setTotalMinutes] = useState(0)
    const [type, setType] = useState('bar')






    const [employe, setEmploye] = useState([])
    const activity = [
        // {
        //     label: "Employee",
        //     value: "employee"
        // },
        {
            label: "Section",
            value: "section"
        }
    ]

    const summaryMenu = [
        // ["line", <TrendingUp size={16} />],
        ["bar", <BarChart2 size={16} />],
    ];


    const updateFilter = (e) => {
        setFilter(e)
        setRequestFlag(true)
    }
    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            background: state.isSelected ? "#fd7e14" : '',
            boxShadow: "0px 4px 37px 9px rgb(0 0 0 / 3%)",
            borderRadius: "0px",
        }),
        control: (provided) => ({
            ...provided,
            height: "40px",
            background: "#FFFFFF",
            boxShadow: "0px 4px 37px 9px rgb(0 0 0 / 3%)",
            borderRadius: "4px",
            border: "1px solid  #ced4da",
            width: 150,

        }),
        menu: (provided) => ({
            ...provided,
            zIndex: 10
        })
    }
    const customStylesTwo = {
        option: (provided, state) => ({
            ...provided,
            background: state.isSelected ? "#fd7e14" : '',
            boxShadow: "0px 4px 37px 9px rgb(0 0 0 / 3%)",
            borderRadius: "0px",
        }),
        control: (provided) => ({
            ...provided,
            height: "40px",
            background: "#FFFFFF",
            boxShadow: "0px 4px 37px 9px rgb(0 0 0 / 3%)",
            borderRadius: "4px",
            border: "1px solid  #ced4da",
            width: 230,
            margin: "0 25px"

        }),
        menu: (provided) => ({
            ...provided,
            zIndex: 10
        })
    }

    const search = async () => {
        if (typeof filter !== 'undefined' && filter) {

            if (activeButton.value === 'employee') {


                let params = {
                    start: filter.start,
                    end: filter.end,
                    type: "emp",
                    emp_id: currentEmploye?.value,
                    sid: filter.sid,
                    dispatch
                }
                getGraphOfEmployeeActivityRequest(params);

                // getSectionTimelineRequest(params);
                getSectionofEmpoyeRequest(params)
            }
            if (activeButton.value === "section") {
                let params = {
                    start: filter.start,
                    end: filter.end,
                    type: "sec",
                    sid: filter.sid,
                    sec_id: sectionId?.value,
                    dispatch
                }
                await getGraphOfEmployeeActivityRequest(params);
                await getSectionofEmpoyeRequest(params);
                await getSectionTimelineRequest(params);

            }
        }
    }


    useEffect(() => {
        if (typeof filter !== undefined && filter) {
            if (activeButton.value === "section") {
                search();
            }
            else if (activeButton.value === "employee") {
                if (typeof currentEmploye !== undefined && currentEmploye) {
                    search();
                }
            }
        }

    }, [sectionId, currentEmploye])


    useEffect(() => {
        if (typeof timelineData !== undefined && timelineData) {
            if ('data' in timelineData) {

                setTimelineSection(timelineData)
            }
        }

        else {
            setTimelineSection({ data: [], labels: [] })

        }

    }, [timelineData])



    useEffect(() => {
        if (employees) {
            setCurrentEmploye({ label: employees[0]?.name, value: employees[0]?._id })
        }
    }, [employees])


    useEffect(() => {
        if (employees) {
            let _employe = [];
            employees.map((item) => {
                _employe.push({
                    value: item._id,
                    label: item.name
                })
            })
            setEmploye(_employe);
        }

    }, [employees])

    useEffect(() => {
        if (floors) {
            let sections = [];
            floors.map((item) => {
                item.sections.map((section) => {
                    sections.push({
                        value: section._id,
                        label: section.label

                    })
                })
            })
            setSections(sections);
            setSectionId(sections?.length && sections[0])
        }
    }, [floors])

    useEffect(() => {
        if (filter) {
            let obj = {
                _id: filter.sid,
                dispatch
            }
            floorRequest(obj)
            getEmployeRequest({ _id: filter?.sid, dispatch })

        }


    }, [filter?.sid])

    useEffect(() => {
        if (filter?.start !== undefined && filter?.end !== undefined) {
            search()
        }
    }, [filter?.start, filter?.end])

    useEffect(() => {
        if (sections?.length && dataToShow?.length) {

            let sec = sections.map((item) => {
                return item.value
            })

            let totalMinutes = 0;
            let total_time_spent_by_employee = dataToShow?.forEach((item) => {
                totalMinutes = totalMinutes + item.minutes

            }

            )
            setTotalMinutes(totalMinutes.toFixed());
            let valueNotExisted = dataToShow?.filter((data) => !sec.includes(data.sec_id))
        }

    }, [sections, dataToShow])





    useEffect(() => {
        if (requestFlag) {
            search();
            setRequestFlag(false)
        }

    }, [currentEmploye, requestFlag])


    useEffect(() => {

        let _dataToShow = sectionOfEmploye?.filter((employee) => {
            return employee.sec_id != "0"
        })

        setDataToShow(_dataToShow)

    }, [sectionOfEmploye])


    useEffect(() => {
        setDataToShow([]);
        setSeriesData([]);
        if (typeof filter !== undefined && filter) {
            search()
        }

    }, [activeButton])

    useEffect(() => {

        setSeriesData(graph)

    }, [graph])


    useEffect(() => {
        if (graph) {
            var seriesData = [];
            graph.map((item) => {
                if (activeButton.value === 'section') {
                    let obj = {
                        stack: false,
                        name: item?.employee?.name,
                        data: item?.array,
                        type: type
                    }
                    seriesData.push(obj);
                }
                else if (activeButton.value === "employee") {
                    let section = sections.filter((sec) => {
                        return sec.value === item.sec_id
                    })[0]
                    let obj = {
                        stack: false,
                        name: section?.label,
                        data: item?.array,
                        type: type
                    }
                    seriesData.push(obj);
                }
            })
            setSeriesData(seriesData);
        }
    }, [type])







    return (
        <div>
            <MainFilter pageTitle="Employee Activity" updateFilter={(e) => updateFilter(e)} type='silver' singleDate = {true} />
            <Row>
                <Col className='d-flex flex-row' >
                    <Select
                        value={activeButton}
                        options={activity}
                        styles={customStyles}
                        onChange={(event) => setActiveButton(event)}
                    />


                    {activeButton.value === 'employee' && employe &&
                        <Select
                            options={employe}
                            value={currentEmploye}
                            styles={customStylesTwo}
                            placeholder="Employee"
                            onChange={(e) => setCurrentEmploye(e)}
                        />
                    }

                    {
                        activeButton.value === "section" && sections &&
                        <Select
                            value={sectionId}
                            options={sections}
                            styles={customStylesTwo}
                            placeholder="Section"
                            onChange={(value) => setSectionId(value)}
                        />

                    }

                    {/* {(activeButton.value === "employee" || activeButton.value === "section") && <Button className='' color='outline-primary' disabled={!activeButton} onClick={search}>Search</Button>} */}
                    {(activeButton.value === "employee") && <Button className='' color='outline-primary' disabled={!activeButton} onClick={search}>Search</Button>}

                </Col>

            </Row>
            {activeButton.value === "section" &&
                <>


                    <Card style={{ padding: 0, margin: 0, marginTop: '0.8rem' }} className=''>
                        <CardHeader>
                            <h4 className="m-0" style={{ fontSize: '14px' }}>Customer/Employee Ratio</h4>

                        </CardHeader>

                        <CardBody style={{ height: 'auto', width: '100%', maxHeight: '20rem', padding: 0, margin: 0, minHeight: "150px" }}>
                            {
                                typeof timelineSection !== undefined && timelineSection && 'data' in timelineSection && !timelineLoader && "labels" in timelineSection && timelineSection.labels.length > 0 &&
                                <LineGraph
                                    seriesData={timelineSection}
                                    hideControls={true}
                                    title={''}
                                    space={40}
                                    rotation={45}
                                    height={"24rem"}
                                    dataZoomFlag={true}
                                    noHeader={true}


                                />
                            }

                            {!timelineLoader && !mainLoader && <span className='className="d-block small opacity-50"'>
                                <i>no data on selected date please select another date for linegraph</i>
                            </span>}
                            <div className="container h-100">
                                <div className="row align-items-center h-100">
                                    <div className="col-6 mx-auto">
                                        <div className="jumbotron">
                                            {timelineLoader && <span className="d-block small opacity-30 text-center align-middle "><i>Data Fetching...</i></span>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </CardBody>
                    </Card>

                    
                    {/* <Row style={{ padding: 0, margin: 0, marginTop: '2rem' }}>
                        <Col md={3} className="p-0 m-0" key={"traffic-card-"}>
                            <div className='justify-center' style={{ width: "100%", overflow: 'auto', overflowX: "" }}
                            >
                                {
                                    <GraphCard
                                        parent={{ label: "Time spent " }}
                                        childrenLabel={(typeof timeSpentChart !== undefined && timeSpentChart && "childLabel" in timeSpentChart) ? timeSpentChart.childLabel : []}
                                        allData={(typeof timeSpentChart !== undefined && timeSpentChart && "allData" in timeSpentChart) ? timeSpentChart.allData : {}}
                                        overFlowFlag={false}
                                        time={true}
                                    />}
                            </div>
                        </Col>
                        <Col md={9} key={"traffic-card-"}>

                            <Card style={{ padding: 0, margin: 0, }} >
                                <CardHeader>
                                    <h4 className="m-0" style={{ fontSize: '14px' }}>Time spent</h4>

                                </CardHeader>
                                <CardBody className='py-2 px-3'>
                                    <Table>
                                        <thead>
                                            <tr>
                                                <th>Employee</th>
                                                <th>Customer</th>
                                                <th>Missed</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{convertMinutesIntoHourInString((typeof timeSpent !== undefined && timeSpent && "employee" in timeSpent) ? timeSpent.employee : 0)}</td>
                                                <td>{convertMinutesIntoHourInString((typeof timeSpent !== undefined && timeSpent && "customer" in timeSpent) ? timeSpent.customer : 0)}</td>
                                                <td>{convertMinutesIntoHourInString((typeof timeSpent !== undefined && timeSpent && "missed" in timeSpent) ? timeSpent.missed : 0)}</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </CardBody>
                            </Card>

                        </Col>
                    </Row> */}

                    {/* <Row style={{ padding: 0, margin: 0, marginTop: '2rem' }}>
                        <Col md={3} className="p-0 m-0" key={"traffic-card-"}>
                            <div className='justify-center' style={{ width: "100%", overflow: 'auto', overflowX: "" }}
                            >
                                {
                                    <GraphCard
                                        parent={{ label: "Employee" }}
                                        childrenLabel={(typeof empFrqChart !== undefined && empFrqChart && "childLabel" in empFrqChart) ? empFrqChart.childLabel : []}
                                        allData={(typeof empFrqChart !== undefined && empFrqChart && "allData" in empFrqChart) ? empFrqChart.allData : {}}
                                        overFlowFlag={true}
                                        time={true}
                                    />}
                            </div>
                        </Col>
                        <Col md={9}>
                            <Card style={{ padding: 0, margin: 0, }} >
                                <CardHeader>
                                    <h4 className="m-0" style={{ fontSize: '14px' }}>Employee</h4>
                                </CardHeader>
                                <CardBody style={{ height: 'auto', width: '100%', maxHeight: '20rem' }} className='py-2 px-3'>
                                    <Table style={{ fontSize: "12px", textAlign: "" }}>
                                        <thead>
                                            <tr>
                                                <th>No of employees</th>
                                                <th>Time spent</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Object.keys(employeFrequency ? employeFrequency : {}).map((item) => {
                                                return (
                                                    <tr>
                                                        <td>{item}</td>
                                                        <td>{convertMinutesIntoHourInString(employeFrequency[item])}</td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </Table>
                                </CardBody>
                            </Card>

                        </Col>
                    </Row> */}

                </>}

                
                {/*********  Section bar Graph ******** */}
             {/* <Card className="" style={{ backgroundColor: '', overflow: '', marginTop: '2rem' }}>
                {

                    <CardHeader>
                        <Row>
                            <Col md={4}>
                                <h4 className="m-0 p-2" style={{ fontSize: '14px' }}>{activeButton.value === "employee" ? "Employee" : "Section"}  {type} Graph</h4>
                            </Col>
                            <Col classNmdame={8} className="text-end">
                                <ButtonGroup>
                                    {
                                        summaryMenu.map((val, index) => {
                                            return (
                                                <Button
                                                    href={"#toggle-tab-" + val[0]}
                                                    color={type == val[0] ? "outline-primary" : "outline-light"}
                                                    className='section-tab'
                                                    size='sm'
                                                    onClick={() => setType(val[0])}
                                                    active={type == val[0] ? true : null}
                                                    key={"compare-tab-" + index}
                                                >
                                                    {val[1]}
                                                </Button>
                                            );
                                        })
                                    }
                                </ButtonGroup>
                            </Col>
                        </Row>
                    </CardHeader>
                }
                <CardBody
                    style={{ minHeight: '6.75rem', height: 'auto', }}
                >
                    {seriesData !== undefined && seriesData && seriesData?.length > 0 && graphLoader &&
                        <EmployeLineGraph legends={''} seriesData={seriesData} filter={filter} />
                    }
                    {graphLoader && !mainLoader && !seriesData?.length && <span className='className="d-block small opacity-50"'><i>no data on selected date please select another date for linegraph</i></span>}
                    <div className="container h-100">
                        <div className="row align-items-center h-100">
                            <div className="col-6 mx-auto">
                                <div className="jumbotron">
                                    {!graphLoader && <span className="d-block small opacity-30 text-center align-middle "><i>Data Fetching...</i></span>}
                                </div>
                            </div>
                        </div>
                    </div>

                </CardBody>
            </Card>  */}

            {/********* Employee sales **********/}

            {/* <Card style={{ padding: 0, marginTop: '2rem', }}>
                <CardHeader className='' style={{ marginBottom: 0, }}>
                    Employee sales
                </CardHeader>
                {typeof dataToShow !== undefined && dataToShow && !dataToShow.length &&
                    <CardBody >
                        {graphLoader && !mainLoader && typeof dataToShow !== undefined && dataToShow && !dataToShow?.length && <span className='className="d-block small opacity-50"'><i>no data on selected date please select another date for linegraph</i></span>}
                        <div className="container h-100">
                            <div className="row align-items-center h-100">
                                <div className="col-6 mx-auto">
                                    <div className="jumbotron">
                                        {!graphLoader && <span className="d-block small opacity-30 text-center align-middle "><i>Data Fetching...</i></span>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </CardBody>
                }

                {typeof dataToShow !== undefined && dataToShow && dataToShow?.length > 0 &&
                    <CardBody className='scrollbar ' id="style-3" style={{ width: "100%", border: "none", top: 0, padding: 0, margin: 0, height: 'auto', maxHeight: '500px' }}>
                        <Table bordered style={{ fontSize: "12px", textAlign: "center" }}>
                            <thead >
                                <tr >
                                    <th>{activeButton.value === "employee" ? "Section" : "Employee"}</th>
                                    <th>Minutes</th>
                                    <th>Percentage</th>
                                    {activeButton.value === "employee" && <th>Monitored Percentage</th>}
                                    <th>Units</th>
                                    <th>Transaction</th>
                                    <th>Sales</th>
                                    <th>Conversion</th>
                                    <th>IPT</th>
                                    <th>ASP</th>
                                    <th>ATV</th>
                                    <th>Traffic</th>
                                </tr>
                            </thead>
                            <tbody>
                                {console.log('dataToShow', dataToShow)}

                                {typeof dataToShow !== undefined && dataToShow && dataToShow.length && dataToShow?.map((item, index) => (
                                    <tr key={index}>
                                        {
                                            activeButton.value === "" &&
                                            <>
                                                <td>

                                                    {
                                                        sections?.map((section, index1) => {
                                                            if (section.value === item.sec_id) {
                                                                return section.label
                                                            }
                                                            else {
                                                                if (user.client_id[0]._id !== "60c9ac8562a70e11372a524b" || user.client_id[0]._id !== "6234c413115aa9163039ac20") {
                                                                    if (index1 === 0 && index === 0) {
                                                                        return "Kids"
                                                                    }
                                                                    if (index1 === 1 && index === 1) {
                                                                        return "Unstitched"
                                                                    }
                                                                    if (index1 === 2 && index === 2) {
                                                                        return "Stitched"
                                                                    }
                                                                    if (index1 === 3 && index === 3) {
                                                                        return "Top Right Corner"
                                                                    }
                                                                    if (index1 === 4 && index === 4) {
                                                                        return "Left Corner"
                                                                    }



                                                                }


                                                            }
                                                        })

                                                    }

                                                </td>
                                                <td>{item.minutes?.toFixed()}</td>
                                                <td>{((item?.minutes / 600) * 100).toFixed(2)}%</td>
                                                <td>{((item.minutes?.toFixed() / totalMinutes) * 100).toFixed(2)}%</td>
                                                <td>{adlyticFormatter(item.units)}</td>
                                                <td>{adlyticFormatter(item.transaction)}</td>
                                                <td>{adlyticFormatter(item?.sale)}</td>
                                                <td>{adlyticFormatter(item?.conversion)}</td>
                                                <td>{adlyticFormatter(item.ipt)}</td>
                                                <td>{adlyticFormatter(item.asp)}</td>
                                                <td>{adlyticFormatter(item.atv)}</td>
                                                <td>{adlyticFormatter(item.traffic)}</td>
                                            </>

                                        }
                                        {
                                            activeButton.value === "section" &&

                                            <>
                                                <td>

                                                    {employees.map((emp) => {
                                                        if (emp.id === item.emp_id) {
                                                            return <Row>
                                                                <Col md={2}>

                                                                    {<div className='mx-2' style={{ borderRadius: '50%', overflow: 'hidden', width: '40px', height: '40px' }}><LettersProfile name={emp.name} /></div>}
                                                                </Col>
                                                                <Col md={9} className='py-2 mx-2' style={{ textAlign: "left" }}>
                                                                    {emp.name}

                                                                </Col>
                                                            </Row>

                                                        }
                                                    })}
                                                </td>
                                                {

                                                    <>
                                                        <td>{item?.minutes?.toFixed(0)}</td>
                                                        <td>{((item?.minutes / 600) * 100).toFixed(0)}%</td>
                                                        <td>{item.units.toFixed(0)}</td>
                                                        <td>{adlyticFormatter(item.transaction)}</td>
                                                        <td>{adlyticFormatter(item?.sale)}</td>
                                                        <td>{adlyticFormatter(item?.conversion)}</td>
                                                        <td>{adlyticFormatter(item.ipt)}</td>
                                                        <td>{adlyticFormatter(item.asp)}</td>
                                                        <td>{adlyticFormatter(item.atv)}</td>
                                                        <td>{adlyticFormatter(item.traffic)}</td>
                                                    </>

                                                }
                                            </>
                                        }
                                    </tr>
                                ))}

                            </tbody>

                        </Table>
                    </CardBody>}

            </Card> */}




        </div>
    )
}
