import React, { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, ButtonGroup, Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import GraphCard from "../components/cards/GraphCard";
import LineCore from "../components/charts/LineCore";
import MainFilter from "../components/filters/MainFilter";
import moment from "moment";
import { footfallRequest, footfallGraphRequest, user } from "../helpers/requests";
import { setCategoryFilter } from "../redux/actions/categoryAction";
import { setFootfallFilter, setFootfallGraph, setFootfallGraphType, setGrapghData } from "../redux/actions/footfallActions";
import { BarChart2, TrendingUp, ArrowLeftCircle, ArrowRightCircle, ArrowRight, ArrowLeft, ChevronLeft, ChevronsLeft, ChevronsRight, ChevronRight } from "react-feather";
import { capitalize, capitalizeFirstLetter, getDifference, getLabelsOfHours, padTo2Digits, tabStyle, toLowerCase } from "../helpers/utils";
import NewMainFiltrer from "../components/filters/newMainFilter";
import GraphCardTwo from "../components/cards/GraphCardTwo";


const Visitors = () => {
  const [type, setType] = useState({ total: "line" })
  const [apiFlag, setApiFlag] = useState(false)
  const [currentView, setCurrentView] = useState('footfall')
  let enabledFeatures = user?.client_id[0]?.feature;
  const [labels, setLabels] = useState([]);
  const footFall = enabledFeatures?.footfall;
  const [salesFeature, setSalesFeature] = useState([])
  const [allFeatures, setAllFeatures] = useState([]);
  const [title, setTitle] = useState();
  const [filter, setFilter] = useState();
  const [store, setStore] = useState(user.access[0].label);

  const linegraphLoader = useSelector((state) => state.employeActivity.timelineLoader);


  const dispatch = useDispatch();
  const all_data = useSelector((state) => state?.footfallReducer?.all_data);
  const grapghRawData = useSelector((state) => state.footfallReducer.grapGhRawData)
  const graphRequestData = useSelector((state) => state.footfallReducer.graphRequestData);
  console.log("");
  const [category, setCategory] = useState('')

  console.log('grapghRawData', grapghRawData, labels);

  let t = "2023-03-29T00:00:00.000Z";
  console.log('dfkdhfjshdf', new Date(moment(new Date(t)).add(13, 'hours').toLocaleString()).getHours());





  const updateFilter = (e) => {

    console.log('eeeeeeeeeeeeeee',e);


    let object = {
      end: e.end,
      start: e.start,
      fid: e.fid,
      sid: e.sid,
      type: 'd',
      graphType: type,
      stores: e.option,
      dispatch,
      impute : false,
      EMP : [...e.EMP, '-1']

    }

    let timing = { start: "00", end: "23" }

    if (typeof user !== undefined && user && "client_id" in user && user.client_id.length > 0 && user.client_id[0].timing.length > 0) {
      let dayOfweek = moment(object.start).weekday();
      let dayTiming = user.client_id[0].timing.filter((item) => {
        return item.key === dayOfweek
      })
      if (dayTiming.length > 0) {
        timing = { start: padTo2Digits(dayTiming[0].start), end: padTo2Digits(+dayTiming[0].end) }
      }
    }

    setFilter(object);
    console.log('timing',timing);
    let _labels = getLabelsOfHours(timing.start, timing.end, object.start)
    let multipleDaysLabels = getLabels(object.start, object.end);

    console.log("e.singleDay",e.singleDay);
    if(e.singleDay === 0){

      console.log("labelsss",_labels);
      setLabels(_labels)
    }
    else {
      setLabels(multipleDaysLabels)
      console.log("labelsss",_labels);

    }
    // setLabels(_labels)
    // setLabels(getLabels(object.start, object.end))
    dispatch(setFootfallFilter({ start: e.start, end: e.end }));
    e["dispatch"] = dispatch;
    let _filter = {
      _startDate: e.start,
      _endDate: e.end,
    }

    dispatch(setCategoryFilter(_filter));
    footfallRequest(object);
    footfallGraphRequest(object);
  };


  const getLabels = (startDate, endDate) => {
    var labels = [];
    var start = moment(startDate);
    var end = moment(endDate);
    if (end.diff(start, "day") < 1) {
      while (end.diff(start, "hour") >= 0) {
        labels.push(start.format("hh:mm a"));
        start = start.add(1, "hour");
      }
    } else {
      end = end.startOf("day");
      while (end.diff(start, "day") >= 0) {
        labels.push(start.format("D MMM  YYYY"));
        start = start.add(1, "day");
      }
    }
    // labels.pop()
    return labels;

  };


  useEffect(() => {
    if (typeof enabledFeatures !== undefined && enabledFeatures) {

      let _allFeatures = [];
      enabledFeatures.footfall.forEach((item) => {
        item.children.forEach((children) => {
          let obj = { ...children, feature: "footfall", percentage: children.key === "total" ? false : true }
          _allFeatures.push(obj)
        })
      })
      Object.keys(enabledFeatures.sales ? enabledFeatures.sales : {}).forEach((saleFeature) => {
        let obj = {
          label: enabledFeatures.sales[saleFeature],
          key: saleFeature,
          feature: "sale",
          percentage: false
        }
        _allFeatures.push(obj)
      })


      setAllFeatures(_allFeatures);
    }

  }, [])

  const salesOrder = { sale: 1, units: 2, conversion: 3, transaction: 4, atv: 5, ipt: 6, asp: 7, rpv: 8 }




  useEffect(() => {
    if (typeof allFeatures !== undefined && allFeatures && allFeatures.length > 0) {

      setTitle(allFeatures[0].key);
    }
  }, [allFeatures])



  useEffect(() => {

    setApiFlag(true);
    if (apiFlag) {
      let requestData = { ...filter, graphType: type };


      let obj = {
        user: user,
        data: graphRequestData,
        requestData: requestData,
        impute : false,
      }
      dispatch(setFootfallGraph(obj));
    }
  }, [type])

  useEffect(() => {
    if (apiFlag) {
      let requestData = { ...filter, graphType: type }
      let obj = {
        user: user,
        data: graphRequestData,
        requestData: requestData,
        impute : false,

      }
      dispatch(setFootfallGraph(obj));
    }

  }, [apiFlag])

  useEffect(() => {
    if (typeof title !== undefined && title && typeof grapghRawData !== undefined && grapghRawData) {
      let current = grapghRawData[title];
      let _type = {}
      Object.keys(current ? current : {}).forEach((item) => _type[item] = "line");
      setType(_type);
    }

  }, [title])




  const summaryMenu = [
    ["line", <TrendingUp size={16} />],
    ["bar", <BarChart2 size={16} />],
  ];

  const payload = {
    end: '',
    start: '',
    fid: [],
    sid: [],
    type: 'd',
    graphType: '',


  }

  const elementRef = useRef(null);
  const [arrowDisable, setArrowDisable] = useState(true);


  const handleHorizantalScroll = (element, speed, distance, step) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      element.scrollLeft += step;
      scrollAmount += Math.abs(step);
      if (scrollAmount >= distance) {
        clearInterval(slideTimer);
      }
      if (element.scrollLeft === 0) {
        setArrowDisable(true);
      } else {
        setArrowDisable(false);
      }
    }, speed);
  };




  return (
    <Fragment>
      <MainFilter
        pageTitle="Traffic"
        Title={store}
        updateStoreNameURL={store}
        updateFilter={(e) => updateFilter(e)}
        type='visitor'>
      </MainFilter>


      {/* <NewMainFiltrer
        pageTitle="Trends"
        Title={store}
        updateFilter={(e) => updateFilter(e)}
        selected={{ storeName: true, floorName: true }}
        payload={payload}
        type='brown'
        setCategory={(output) => setCategory(output)}
        category={category}

      /> */}

      {!all_data && <span className="d-block small opacity-50 m-2"><i>No data on selected date. Please select another date</i></span>}

      <Row className="mb-4">
        <Col>
          {
            allFeatures.length > 0 &&
            <>
              <div className="button-contianer p-0 m-0"
                style={{ textAlign: "center" }}
              >

                {/* <button style={{ textAlign: 'center', borderRadius: "50%", backgroundColor: "#fff", border: "1px solid #fff" }}> */}
                <ChevronLeft size={27}
                  onClick={() => {
                    handleHorizantalScroll(elementRef.current, 25, 100, -10);
                  }}
                  style={{ marginTop: '0.8rem', cursor: "pointer" }}
                  color={'rgba(170, 170, 170)'}
                  disabled={arrowDisable}
                />
                <div className="img-container" ref={elementRef}>
                  {
                    allFeatures.map((feature, index) => {

                      let value = 0;
                      let total = 0;
                      let _featurePercent = 0;

                      if (typeof all_data !== undefined && all_data) {
                        value = (feature.key in all_data) ? all_data[feature.key] : 0;
                        if (feature.percentage) {
                          total = ("total" in all_data) ? all_data.total : 0;
                          _featurePercent = value > 0 ? (value / total) * 100 : 0;
                        }
                      }


                      return (
                        <div onClick={() => setTitle(feature.key)}>
                          <GraphCardTwo
                            feature={feature}
                            value={value}
                            percentage={_featurePercent}
                            index={index}
                            active={title === feature.key ? true : false}
                            showPercentage={feature.percentage}
                          />
                        </div>
                      )
                    }
                    )}
                </div>

                <ChevronRight size={27}
                  onClick={() => {
                    handleHorizantalScroll(elementRef.current, 25, 100, 10);
                  }}
                  color={'rgba(170, 170, 170)'}
                  style={{ marginTop: '0.8rem', cursor: "pointer" }}
                />
              </div>
            </>

          }
        </Col>
      </Row>
      <Row>
        <Col>
          {
            Object.keys(grapghRawData[title] ? grapghRawData[title] : {}).map((feature, index) => {
              let currentFeature = grapghRawData[title][feature];
              console.log('currentFeature', currentFeature, feature);
              let label = user._id === "642bf14872f5372fe2a58cad" ? feature === "exit" ? "Entry" : feature === "entry" ? "Exit" : feature : feature;
              console.log('labellabel', label, feature);
              return (
                <Card className="mt-2" key={index}>
                  <CardHeader style={{ backgroundColor: 'none' }}>
                    <Row style={{ position: 'relative' }}>
                      <Col style={{ backgroundColor: '', position: "absolute", top: "50%", margin: 0, transform: "translateY(-50%)" }}>
                        {


                          <h4 className="m-0" style={{ fontSize: '14px' }}>{(label.toLocaleUpperCase())}</h4>
                        }
                      </Col>
                      <Col className='text-end'>
                        {typeof type !== undefined && type && <ButtonGroup>
                          {
                            summaryMenu.map((val, index) => {
                              return (
                                <Button
                                  href={"#toggle-tab-" + val[0]}
                                  color={(typeof type !== undefined && type && feature in type && type[feature] === val[0]) ? "outline-primary" : "outline-light"}
                                  className='section-tab'
                                  size='sm'
                                  onClick={() => setType({ ...type, [feature]: val[0] })}
                                  active={!Object.keys(type ? type : {}).length ? index === 0 && true : (typeof type !== undefined && type && feature in type && type[feature] === val[0]) ? true : false}
                                  key={"compare-tab-" + index}
                                >
                                  {val[1]}
                                </Button>
                              );
                            })
                          }
                        </ButtonGroup>}
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    {currentFeature.data !== undefined && currentFeature.data !== null && currentFeature.data && labels &&
                      <>
                        {
                          !linegraphLoader &&
                          <LineCore legends={''} labels={labels} data={currentFeature.data} />
                        }


                        {linegraphLoader && <div className="container" style={{ height: "17.75rem" }}>
                          <div className="row align-items-center h-100">
                            <div className="col-6 mx-auto">
                              <div className="jumbotron">
                                {linegraphLoader && <span className="d-block small opacity-30 text-center align-middle "><i>Data Fetching...</i></span>}
                              </div>
                            </div>
                          </div>
                        </div>}
                      </>
                    }
                  </CardBody>
                </Card>
              )

            })
          }
          {
            !footFall &&
            <span className='className="d-block small opacity-50"'><i>You dont have any enabled features to plot on graph </i></span>
          }
        </Col>
      </Row>
    </Fragment>
  );
};

export default Visitors;
