import React, { useEffect, useState } from 'react'
import { getCameraByLinkedSection, getCameraHeatmap } from '../../helpers/requests';
import { Row, Col, Card, CardBody, ButtonGroup, Button, Progress, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { ImCross } from 'react-icons/im';
import { useDispatch } from 'react-redux';
import Heatmap from '../../pages/Templates/Heatmap';
import Heatmap2 from '../../pages/Templates/Heatmap2';

export default function CameraHeatmap(props) {
    const [modal, setModal] = useState(false);
    const dispatch = useDispatch();
    const [heatmap, setHeatmap] = useState([])
    console.log('props.heatmap', props.heatmap, "and heatmap of yahoo", heatmap);

    const toggle = () => {
        setHeatmap([])

        props.setCameraHeatmap([])

        setTimeout(() => {
            setModal(!modal)
            props.setModal(!modal)
        }, 1000)



    }



    useEffect(() => {
        setModal(props.modal);
    }, [props])

    useEffect(() => {
        console.log('props.heatmap', props.heatmap);
        if (props.heatmap) {
            setHeatmap(props.heatmap)
        } else {

        }

    }, [props.heatmap])

    useEffect(() => {
        console.log('current heatmap on short is', heatmap);

    }, [heatmap])

    return (
        <Modal isOpen={modal} centered size='lg' backdrop="static">
            <ModalHeader className="d-flex justify-content-between align-items-center">
                <div className="modal-header-title">
                    {props.section.label}
                </div>
                <div className="modal-header-close-button" role="button" onClick={toggle}>
                    <ImCross color="#00000030" aria-label="Close modal" />
                </div>
            </ModalHeader>
            <ModalBody className='' style={{ height: "" }}>

                {
                    typeof props.currentCam !== undefined && props.currentCam &&
                    <Heatmap2
                        dye={props.currentCam.screenshot_dye ? props.currentCam.screenshot_dye : ""}
                        map={props.currentCam.screenshot ? props.currentCam.screenshot : ""}
                        heatmapData={heatmap.length > 0 ? heatmap : []}
                        key={"my_map"}
                        sections={[]}
                        setSection={(section) => (section)}
                        section={props.section ? props.section : ""}
                        dispatch={dispatch}
                        setModal={(e) => setModal(e)}

                    />}




            </ModalBody>
        </Modal>
    )
}
