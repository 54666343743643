import React, { Component, useEffect, useState, Fragment, useRef } from "react";
import { Button, Col, Input, Label, Popover, PopoverBody, PopoverHeader, Row } from "reactstrap";
import h337 from 'heatmap.js';
import * as ReactDOM from 'react-dom';
import { usePrevious } from "../../helpers/utils";
import { Edit, Edit2, Menu, Save , } from "react-feather";
import { getCategoriesProduct, updateSectionLabelRequest } from "../../helpers/requests";
import { useDispatch } from "react-redux";
import Product from "../../components/modals/Product";

export default function SectionWithScale(props) {

    console.log('props.dye', props.dye,"props.map", props.map);



    const [isImageLoaded, setisImageLoaded] = useState(false);
    const [scale, setScale] = useState(1);
    const [heatmapInstance, setHeatmapInstance] = useState();
    // const [popoverOpen, setPopoverOpen] = useState([]);
    const [editFlag, setEditFlag] = useState(false);
    const [label, setLabel] = useState()
    const [selectedArea, setSelectedArea] = useState((typeof props.section !== undefined && props.section) ? props.section._id : props.sections[0]?._id);
    const [imageData, setImageData] = useState({ width: 0, height: 0 });
    const picRef = useRef();
    const heatmapRef = useRef();
    const [popoverOpen, setPopoverOpen] = useState([]);
    const sectionWIdth = 25;
    const sectionHeight = 25;
    const dispatch = useDispatch();
    const [section, setSection] = useState({})
    const [productFlag, setProductFlag] = useState(false);
    const [products, setProducts] = useState([])
    console.log('currentFloor', products, props.currentFloor);



    const toggle = (index, status = -1) => {
        setEditFlag(false)
        var _popoverOpen = popoverOpen.slice();
        if (status == -1) {
            _popoverOpen[index] = !_popoverOpen[index];
            setPopoverOpen(_popoverOpen);
        } else {

            let pop = _popoverOpen.map((item, ind) => {
                if (ind === index) {
                    if (status === 1) {

                        return item = true
                    }
                    else return item = false
                }
                else {
                    return item = false
                }
            })
            setPopoverOpen(pop);
        }
    }

    const updateSection = (section) => {
        props.setSection(section); // this handles onclick updated request
        setSection(section)
    }

    const clickArea = (section) => {
        props.setSection(section); // this handles onclick updated request
        setSelectedArea(section._id)
        setSection(section)
        props.selectedSection(section);
    };


    const saveLabel = async (item) => {
        let obj = {
            id: item._id,
            data: {
                label: label
            },
            dispatch

        }
        console.log('objobj', obj);
        let res = await updateSectionLabelRequest(obj);

        console.log('resres', res);

    }

    useEffect(() => {

        setSelectedArea(props.section._id)
    }, [props.section])


    const [yoHeatmap, setYoHeatmap] = useState();

    let preHeatmap = usePrevious(yoHeatmap)


    useEffect(() => {
        if (props && props.map && props.map) {
            let img = new Image();
            img.onload = () => {
                var drawingWidth = document.getElementById('live-heatmap').offsetWidth;
                let _scale;
                if (props.heatmap) {
                    _scale = 1;
                }
                else {
                    _scale = drawingWidth / img.width
                }
                setScale(_scale)
                setImageData({ width: img.width * _scale, height: img.height * _scale });
                setisImageLoaded(true)
            };
            img.src = props.map;
        }

        if (props.sections) {
            let array = new Array(props.sections.length).fill(false);
            setPopoverOpen(array);
        }

    }, [props])

    let heat;
    useEffect(() => {
        if (isImageLoaded) {
            setTimeout(() => {
                let widthhhh = document.getElementById('heatmap').offsetWidth;
                heat = h337.create({
                    container: document.getElementById('heatmap'),
                    radius: 11,
                    opacity: .7,
                    visible: true,
                    gradient: {
                        0.05: '#9ec5ff',
                        '0.15': '#3785F9',
                        0.20: '#265BF9',
                        '0.30': '#59DBFB',
                        0.35: '#65FA9D',
                        '0.45': '#64F92C',
                        0.50: '#78FA26',
                        '0.60': '#D2FC2C',
                        0.65: '#F5FD2F',
                        '0.75': '#EC8923',
                        // 0.80: '#E7601F',
                        0.80: '#f3783d',
                        // '0.90': '#E43A1C',
                        '0.90': '#f35033',
                        0.95: '#E3231B',
                        '1.0': '#b80701', // highest red
                    }
                });
                setTimeout(() => {
                    let data = processHeatmapData(props.heatmapData, scale);
                    heat.setData(data);
                    setYoHeatmap(heat)
                    setisImageLoaded(true)
                }, 200);
            }, 200);

        }



    }, [props.heatmapData, isImageLoaded])


    useEffect(() => {
        async function fetchProducts() {
            try {
                const _products = await getCategoriesProduct({ sid: props.filters.sid, dispatch });
                if ( typeof _products !== undefined && _products && _products?.data?.status) {
                    setProducts(_products.data.data);
                    props.setProducts(_products.data.data)

                }
                else {
                    setProducts([]);
                    props.setProducts([])
                }
                console.log('_products fetched', _products);
            } catch (error) {
                console.error('Error fetching products:', error);
                // Handle the error, e.g., display an error message to the user
            }
        }

        fetchProducts();
    }, [props && props?.filters?.sid]);


console.log("sfaoif",props);






    const processHeatmapData = (heatmapData, _scale) => {
        let min = Number.MAX_SAFE_INTEGER - 1;
        let max = Number.MIN_SAFE_INTEGER - 1;
        var data = [];
        for (var i = 0; i < heatmapData.length; i++) {
            var val = heatmapData[i].v;
            var record = heatmapData[i];

            data.push({ x: record.x * _scale, y: record.y * _scale, value: record.v });
            if (val < min) {
                min = val;
            }
            if (val > max) {
                max = val;
            }
        }
        return { max: max, data: data };
    }


    const handleSectionForProducts = (section) => {
        setProductFlag(true)
        setSection(section)


    }






    return (
        <Fragment>
            {(
                <div>
                    <div
                        ref={picRef}
                        id="live-heatmap"
                        style={{
                            width: "100%",
                            height: imageData !== undefined && imageData && 'height' in imageData ? imageData.height : "100%",
                            overflow: "hidden",
                            margin: "0px",
                            padding: "0px",
                        }}
                    >

                        {
                            imageData.width > 0 && imageData.height > 0 && props.dye != null && props.dye.length &&
                            <>
                                {props.dye != null && props.dye.length > 0 && (
                                    <img
                                        width={imageData.width}
                                        height={imageData.height}
                                        src={props.dye}
                                        style={{ position: "absolute", zIndex: 2 }}
                                        alt={""}
                                    />
                                )}
                                <div ref={heatmapRef} id='heatmap' style={{ width: imageData.width, height: imageData.height, position: 'absolute' }}>

                                    <svg
                                        height={imageData.height}
                                        width={imageData.width}
                                        style={{ position: "absolute", zIndex: 2 }}
                                    >
                                        {props.sections.map((section, ind) => {
                                            var points = "";
                                            var xCords = [];
                                            var yCords = [];
                                            section.positions.forEach((point, index) => {
                                                if (index > 0) {
                                                    points +=
                                                        " " +
                                                        // point.x * this.state.scale +
                                                        point.x * scale +

                                                        "," +
                                                        point.y * scale;
                                                } else {
                                                    points +=
                                                        point.x * scale +
                                                        "," +
                                                        point.y * scale;
                                                }
                                                xCords.push(point.x);
                                                yCords.push(point.y);
                                            });
                                            var axisRatio = {
                                                x: Math.floor(
                                                    (Math.max(...xCords) + Math.min(...xCords)) / 2
                                                ),
                                                y: Math.floor(
                                                    (Math.max(...yCords) + Math.min(...yCords)) / 2
                                                ),
                                            };

                                            return (
                                                <Fragment key={"section_" + ind}>
                                                    {/* <text x={text.x} y={text.y} textAnchor="middle" fill="#0000008a" fontSize="16" fontWeight="900"></text> */}
                                                    <svg
                                                        width={sectionWIdth * scale}
                                                        height={sectionHeight * scale}
                                                        id={"Popover-" + section._id}
                                                        x={axisRatio.x * scale - 12}
                                                        y={axisRatio.y * scale - 12}
                                                        stroke="rgba(0,0,0,0.1)"
                                                        strokeWidth="1"
                                                        fill={
                                                            selectedArea === section._id
                                                                ? "rgba(232,129,49,1)"
                                                                : ""
                                                        }
                                                        viewBox="0 0 368 368"
                                                        onMouseEnter={() => toggle(ind, 1)}
                                                        onMouseLeave={() => toggle(ind, 0)}
                                                        style={{ zIndex: 100, position: "absolute", cursor: 'pointer' }}
                                                    >
                                                        <g>
                                                            <g>
                                                                <g>
                                                                    <path
                                                                        d="M184.333,0C102.01,0,35.036,66.974,35.036,149.297c0,33.969,11.132,65.96,32.193,92.515
                                                                        c27.27,34.383,106.572,116.021,109.934,119.479l7.169,7.375l7.17-7.374c3.364-3.46,82.69-85.116,109.964-119.51
                                                                        c21.042-26.534,32.164-58.514,32.164-92.485C333.63,66.974,266.656,0,184.333,0z M285.795,229.355
                                                                        c-21.956,27.687-80.92,89.278-101.462,110.581c-20.54-21.302-79.483-82.875-101.434-110.552
                                                                        c-18.228-22.984-27.863-50.677-27.863-80.087C55.036,78.002,113.038,20,184.333,20c71.294,0,129.297,58.002,129.296,129.297
                                                                        C313.629,178.709,304.004,206.393,285.795,229.355z"
                                                                    />
                                                                    <path
                                                                        d="M184.333,59.265c-48.73,0-88.374,39.644-88.374,88.374c0,48.73,39.645,88.374,88.374,88.374s88.374-39.645,88.374-88.374
                                                                        S233.063,59.265,184.333,59.265z M184.333,216.013c-37.702,0-68.374-30.673-68.374-68.374c0-37.702,30.673-68.374,68.374-68.374
                                                                        s68.373,30.673,68.374,68.374C252.707,185.341,222.035,216.013,184.333,216.013z"
                                                                    />
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </svg>
                                                    <polygon
                                                        onClick={() => clickArea(section)}
                                                        style={{
                                                            fill:
                                                                selectedArea === section._id
                                                                    ? props.heatmap ? "rgba(0,0,0,0.3)" : "rgba(0,0,0,0.1)"
                                                                    : popoverOpen[ind] === true ? "rgba(0,0,0,0.3)" : "rgba(0,0,0,0)",
                                                        }}
                                                        className={props.heatmap ? "itHeatmap" : "it"}
                                                        points={points}
                                                        onMouseEnter={() => toggle(ind, 1)}
                                                        // onMouseLeave={() => toggle(ind, 0)}
                                                        id={"Popover-" + section._id}
                                                    />
                                                    <Popover
                                                        placement="top"
                                                        isOpen={popoverOpen[ind]}
                                                        target={"Popover-" + section._id}
                                                        toggle={() => toggle(ind)}
                                                        key={"Popover-" + section._id}

                                                    >

                                                        <PopoverHeader>

                                                            {
                                                                props.sectionEditFlag &&
                                                                <>

                                                                    {
                                                                        !editFlag &&
                                                                        <Row>
                                                                            <Col md={9}>
                                                                                <Label className="mt-1" style={{ float: "", padding: "2px 5px" }}>{section.label}</Label>

                                                                            </Col>
                                                                            <Col md={3} style={{ backgroundColor: "" }} className="d-flex">
                                                                                <Button size='sm' title="Edit" onClick={() => { setEditFlag(true); setLabel(section.label) }} color=''>
                                                                                    <Edit2 size={14} className='editButton' />
                                                                                </Button>
                                                                                {
                                                                                    products.length > 0 &&
                                                                                    <Button style={{ float: "right", padding: "2px 5px", backgroundColor: "#f0f0f0", border: 'none', color: "black" }} size='sm' title="Manage product categories" onClick={() => handleSectionForProducts(section)}><Menu size={14} className='editButton' />  </Button>}
                                                                            </Col>
                                                                        </Row>
                                                                    }
                                                                    {editFlag &&
                                                                        <div className="d-flex">
                                                                            <div>
                                                                                <Input style={{ width: "" }} type='String' defaultValue={section.label} onChange={(e) => setLabel(e.target.value)} />

                                                                            </div>
                                                                            <div>
                                                                                <Button title="Save" size='sm' style={{ float: "right", padding: "2px 5px" }} className='mx-2' color=''>
                                                                                    <Save size={20} onClick={() => saveLabel(section)} />
                                                                                </Button>
                                                                            </div>
                                                                        </div>
                                                                    }

                                                                </>
                                                            }


                                                            {!props.sectionEditFlag && section.label}
                                                        </PopoverHeader>
                                                        {/* <PopoverBody>
                                                        Location: {section.slot}
                                                    </PopoverBody> */}
                                                    </Popover>
                                                </Fragment>
                                            );
                                        })}
                                    </svg>

                                    <img
                                        width={imageData.width}
                                        height={imageData.height}
                                        src={props.map}
                                        alt=""
                                    />
                                </div>





                            </>

                        }


                    </div>
                </div>
            )}

            {productFlag && section !== undefined && section && products.length > 0 &&
                <Product
                    products={products}
                    section={section}
                    modal={productFlag}
                    setModal={(e) => setProductFlag(e)}
                    updateSection={(e) => updateSection(e)}
                />
            }


        </Fragment>

    )
}














