import React from 'react'
import { ArrowLeft, ArrowRight, Bookmark, Filter, Plus, Trash2, XCircle, ChevronLeft, ChevronsLeft, ChevronsRight, ChevronRight } from 'react-feather';
import moment from 'moment';
import { getDateString, getDifference } from './FilterCommon';
import { useDispatch } from 'react-redux';
import { setError } from '../../../redux/actions/highlights';
import { isGreaterThanToday } from '../../../helpers/utils';
import { useEffect } from 'react';
import { useState } from 'react';


export default function SelectedFilter({ option, selectedDayRange, setSelectedDayRange, storeName = true, floorName = false, currentFloors }) {

    console.log('currentFloors', currentFloors);
    console.log('optionoption', option);

    const [floor, setFloor] = useState();
    console.log('floorfloorfloorfloor', floor);

    const dispatch = useDispatch();
    const increaseEndDate = () => {
        let date = (new Date(selectedDayRange.to.year, selectedDayRange.to.month - 1, selectedDayRange.to.day));
        date.setDate(date.getDate() + 1);
        if (!isGreaterThanToday(date)) {
            let newDate = {
                day: date.getDate(),
                month: date.getMonth() + 1,
                year: date.getFullYear(),
            }
            let _dateRange = {
                from: selectedDayRange.from,
                to: newDate
            }
            setSelectedDayRange(_dateRange)
        }
        else {
            dispatch(setError('You cannot select date greater than today'))
        }

    }

    const decreaseEndDate = () => {
        let date = (new Date(selectedDayRange.to.year, selectedDayRange.to.month - 1, selectedDayRange.to.day));
        date.setDate(date.getDate() - 1);
        if (!isGreaterThanToday(date)) {
            let newDate = {
                day: date.getDate(),
                month: date.getMonth() + 1,
                year: date.getFullYear(),
            }
            let _dateRange = {
                from: selectedDayRange.from,
                to: newDate
            }
            setSelectedDayRange(_dateRange)
        }
        else {
            dispatch(setError('You cannot select date greater than today'))
        }

    }

    const decreaseStartDate = () => {
        let date = (new Date(selectedDayRange.from.year, selectedDayRange.from.month - 1, selectedDayRange.from.day));
        date.setDate(date.getDate() - 1);
        if (!isGreaterThanToday(date)) {
            let newDate = {
                day: date.getDate(),
                month: date.getMonth() + 1,
                year: date.getFullYear(),
            }
            let _dateRange = {
                from: newDate,
                to: selectedDayRange.to
            }
            setSelectedDayRange(_dateRange)
        }
        else {
            dispatch(setError('You cannot select date greater than today'))
        }

    }

    const increaseStartDate = () => {
        let date = (new Date(selectedDayRange.from.year, selectedDayRange.from.month - 1, selectedDayRange.from.day));
        date.setDate(date.getDate() + 1);
        if (!isGreaterThanToday(date)) {
            let newDate = {
                day: date.getDate(),
                month: date.getMonth() + 1,
                year: date.getFullYear(),
            }
            let _dateRange = {
                from: newDate,
                to: selectedDayRange.to
            }
            setSelectedDayRange(_dateRange)
        }
        else {
            dispatch(setError('You cannot select date greater than today'))
        }

    }

    const increaseIntervelDate = () => {

        let date = (new Date(selectedDayRange.to.year, selectedDayRange.to.month - 1, selectedDayRange.to.day));
        date.setDate(date.getDate() + 1);
        if (!isGreaterThanToday(date)) {
            let newDate = {
                day: date.getDate(),
                month: date.getMonth() + 1,
                year: date.getFullYear(),
            }
            let _dateRange = {
                from: newDate,
                to: newDate
            }
            setSelectedDayRange(_dateRange)
        }
        else {
            dispatch(setError('You cannot select date greater than today'))
        }

    }

    const decreaseIntervelDate = () => {

        let date = (new Date(selectedDayRange.to.year, selectedDayRange.to.month - 1, selectedDayRange.to.day));
        date.setDate(date.getDate() - 1);
        let newDate = {
            day: date.getDate(),
            month: date.getMonth() + 1,
            year: date.getFullYear(),
        }
        let _dateRange = {
            from: newDate,
            to: newDate
        }
        setSelectedDayRange(_dateRange)

    }

    const increaseDateRange = () => {
        let from = (new Date(selectedDayRange.from.year, selectedDayRange.from.month - 1, selectedDayRange.from.day));
        let to = (new Date(selectedDayRange.to.year, selectedDayRange.to.month - 1, selectedDayRange.to.day));
        let diff = getDifference(selectedDayRange.from, selectedDayRange.to);

        from.setDate(from.getDate() + diff + 1);
        to.setDate(to.getDate() + diff + 1);


        if (!isGreaterThanToday(from) && !isGreaterThanToday(to)) {
            let fromDate = {
                day: from.getDate(),
                month: from.getMonth() + 1,
                year: from.getFullYear(),
            }
            let toDate = {
                day: to.getDate(),
                month: to.getMonth() + 1,
                year: to.getFullYear(),
            }
            let _dateRange = {
                from: fromDate,
                to: toDate
            }
            setSelectedDayRange(_dateRange)
        }
        else {
            dispatch(setError('You cannot select date greater than today'))
        }

    }

    const decreaseDateRange = () => {
        let from = (new Date(selectedDayRange.from.year, selectedDayRange.from.month - 1, selectedDayRange.from.day));
        let to = (new Date(selectedDayRange.to.year, selectedDayRange.to.month - 1, selectedDayRange.to.day));
        let diff = getDifference(selectedDayRange.from, selectedDayRange.to);
        from.setDate(from.getDate() - diff - 1);
        to.setDate(to.getDate() - diff - 1);


        if (!isGreaterThanToday(from) && !isGreaterThanToday(to)) {
            let fromDate = {
                day: from.getDate(),
                month: from.getMonth() + 1,
                year: from.getFullYear(),
            }
            let toDate = {
                day: to.getDate(),
                month: to.getMonth() + 1,
                year: to.getFullYear(),
            }
            let _dateRange = {
                from: fromDate,
                to: toDate
            }
            setSelectedDayRange(_dateRange)
        }
        else {
            dispatch(setError('You cannot select date greater than today'))
        }

    }

    useEffect(() => {
        if (floorName && typeof currentFloors !== undefined && currentFloors) {
            if (option.length === 1) {
                console.log('optionoption', option.length);
                let flag = ('floors' in option[0] && option[0].floors.length > 0) ? true : false;
                console.log('flagflag', flag);
                if (flag) {
                    console.log('optionoptionInside', option);


                    let _currrentFloor = option[0].floors.filter((item) => {
                        if (item._id in currentFloors)

                            return item
                    })
                    console.log('_currrentFloor', _currrentFloor);

                    if (typeof _currrentFloor !== undefined && _currrentFloor && _currrentFloor.length > 0) {
                        if (_currrentFloor.length === option[0]?.floors.length) {
                            setFloor('All Floor')
                        }
                        else if (_currrentFloor.length === 1) {
                            setFloor(_currrentFloor.length > 0 && _currrentFloor[0]?.floor_label)
                        }
                        else {
                            setFloor(_currrentFloor.length + " Floor")
                        }
                    }
                    else {
                        setFloor()
                    }

                }
            }
            if (option.length > 1) {
                setFloor()
            }
            if (!option.length) {
                setFloor()
            }
        }

    }, [currentFloors, floorName, option])
    console.log('{floorfloorfloor', floor, option);

    return (
        <div>



            {getDifference(selectedDayRange.from, selectedDayRange.to) == 0 ?
                <p className='filterHeader' style={{ padding: 0, margin: 0, lineHeight: "2rem", whiteSpace: 'nowrap' }}>You have selected


                    <strong className='mx-1'>{floorName && floor}</strong> {(floorName && floor) && "of"}


                    <strong className='mx-1'>{option.length > 1 ? option.length : option[0]?.label}</strong> {option.length > 1 ? ` branches to view data from` : `branch to view data from`}
                    <button title='Previous day' style={{ background: 'none', border: 'none', marginLeft: "", marginTop: "", position: '', width: '' }}>
                        <ChevronLeft className='chevronLeft' style={{ color: 'black', cursor: "pointer", marginLeft: "" }}
                            onClick={() => decreaseIntervelDate()}
                        />
                    </button>

                    <strong>{getDateString(selectedDayRange.from, "ll")}</strong>
                    <button title='Next day' style={{ background: 'none', border: 'none', marginLeft: "", marginTop: "", position: '' }} >
                        <ChevronRight className='chevronRight' style={{ color: 'black', cursor: "pointer", marginRight: "" }}
                            onClick={() => increaseIntervelDate()}
                        />

                    </button>


                </p>
                :
                <p className='filterHeader' style={{ padding: 0, margin: 0, lineHeight: "2rem", whiteSpace: 'nowrap' }}>You have selected

                    <strong className='mx-1'>{floorName && floor}</strong> {(floorName && floor) && "of"}



                    <strong className='mx-1'>{option.length > 1 ? option.length : option[0]?.label}</strong> {option.length > 1 ? `branches to view data from` : `branch to view data from`}

                    <button title='Previous interval' style={{ background: 'none', border: 'none', marginLeft: "", marginTop: "", position: '' }} >
                        <ChevronsLeft className='chevronsLeft' style={{ color: 'black', cursor: "pointer", marginRight: "" }}
                            onClick={() => decreaseDateRange()}
                        />

                    </button>
                    <button title='Previous day' style={{ background: 'none', border: 'none', marginLeft: "", marginTop: "", position: '', width: '' }}>
                        <ChevronLeft className='chevronLeft' style={{ color: 'black', cursor: "pointer", marginLeft: "" }}
                            onClick={() => decreaseStartDate()}
                        />
                    </button>
                    <strong>{getDateString(selectedDayRange.from, "ll")}</strong>
                    <button title='Next day' style={{ background: 'none', border: 'none', marginLeft: "", marginTop: "", position: '' }} >
                        <ChevronRight className='chevronRight' style={{ color: 'black', cursor: "pointer", marginRight: "" }}
                            onClick={() => increaseStartDate()}
                        />

                    </button>
                    to
                    <button title='Previous day' style={{ background: 'none', border: 'none', marginLeft: "", marginTop: "", position: '', width: '' }}>
                        <ChevronLeft className='chevronLeft' style={{ color: 'black', cursor: "pointer", marginLeft: "" }}
                            onClick={() => decreaseEndDate()}
                        />
                    </button>

                    <strong>{getDateString(selectedDayRange.to, "ll")}</strong>

                    <button title='Next day' style={{ background: 'none', border: 'none', marginLeft: "", marginTop: "", position: '' }} >
                        <ChevronRight className='chevronRight' style={{ color: 'black', cursor: "pointer", marginRight: "" }}
                            onClick={() => increaseEndDate()}
                        />

                    </button>
                    <button title='Next Interval' style={{ background: 'none', border: 'none', marginLeft: "", marginTop: "", position: '' }} >
                        <ChevronsRight className='chevronsRight' style={{ color: 'black', cursor: "pointer", marginRight: "" }}
                            onClick={() => increaseDateRange()}
                        />
                    </button>
                </p>
            }

        </div>
    )
}
