import React, { useEffect, useState } from 'react'
import { useParams, Link } from "react-router-dom";
import { getStoresHistoryRequest } from '../helpers/requests';
import { Button, ButtonGroup, Card, Col, Row } from 'reactstrap';
import './Storehistory.css'
import { ArrowLeft } from "react-feather";
import { useDispatch } from 'react-redux';
import { tabStyle } from '../helpers/utils';
import { TbMoodCry } from 'react-icons/tb'
const moment = require('moment-timezone');
const Storehistory = () => {
  const [machreverse, setMachReverse] = useState()
  const [interreverse, setInterReverse] = useState()
  const [currentView, setCurrentView] = useState();
  const [machine_arr, setMachine_arr] = useState([]);
  const [internet_arr, setInternet_arr] = useState([]);
  const [response, setResponse] = useState([]);
  const dispatch = useDispatch()
  const { id } = useParams()
  var newobj = id
  var json = JSON.parse(newobj !== undefined && newobj)
  const req_obj = {
    start: json.start,
    end: json.end,
    sid: [json.sid],
    dispatch,
  }
  const specific_store = async () => {
    const res = await getStoresHistoryRequest(req_obj)
    if (res.status) {
      setResponse(res.data)
      const local_res = res.data.length > 0 && res.data[0]._id
      setCurrentView(local_res)
    } else {
      alert(res.message)
    }
  }

  function convertToLocalTime(time, zone) {
    const localTime = moment.tz(time, zone).format('YYYY-MM-DD HH:mm:ss');
    return localTime;
  }



  //    const convertToLocalTime = (date) => {
  //     if (date !== undefined && date) {
  //         let dateOffset = new Date().getTimezoneOffset()/60
  //         console.log(dateOffset,"datttdattt")
  //        let _start='';
  //         if(dateOffset < 0){
  //              _start = moment(new Date(date.year, date.month - 1, date.day)).startOf("day").add( Math.abs( dateOffset),'hours');
  //         }else{
  //              _start = moment(new Date(date.year, date.month - 1, date.day)).startOf("day").subtract(Math.abs( dateOffset),'hours');
  //         }
  //         return _start;
  //     }
  // }

  const sortData = (array) => {
    const length = array.length;

    for (let i = 0; i < length - 1; i++) {
      let swapped = false;

      for (let j = 0; j < length - 1 - i; j++) {
        const datetimeA = array[j].datetime;
        const datetimeB = array[j + 1].datetime;

        const dateA = new Date(datetimeA);
        const dateB = new Date(datetimeB);

        if (dateA > dateB) {
          [array[j], array[j + 1]] = [array[j + 1], array[j]];
          swapped = true;
        } else if (dateA.getTime() === dateB.getTime()) {
          const timeA = datetimeA.substring(11, 19);
          const timeB = datetimeB.substring(11, 19);

          const amPM_A = datetimeA.substring(20);
          const amPM_B = datetimeB.substring(20);

          if (amPM_A === "AM" && amPM_B === "PM") {
            [array[j], array[j + 1]] = [array[j + 1], array[j]];
            swapped = true;
          } else if (
            amPM_A === amPM_B &&
            timeA.localeCompare(timeB, undefined, { numeric: true }) > 0
          ) {
            [array[j], array[j + 1]] = [array[j + 1], array[j]];
            swapped = true;
          }
        }
      }

      if (!swapped) {
        break;
      }
    }

    return array;
  };



  // const filterArray = (arr) => {
  //   const resultArray = [];
  //   if (arr.length > 0) {
  //     resultArray.push(arr[0]);
  //     for (let i = 1; i < arr.length; i++) {
  //       if (i === 1) {
  //         const secondObj = { ...arr[i], status: "true" };
  //         resultArray.push(secondObj);
  //       } else if (arr[i].status !== arr[i - 1].status) {
  //         resultArray.push(arr[i]);
  //       }
  //     }
  //   }
  //   console.log(resultArray);
  //   return resultArray;
  // };


  useEffect(() => {

    const local_res = response.length > 0 && response !== undefined && response.filter((item) => {
      return item._id === currentView
    })[0]
    let internet = local_res && local_res.internet;
    let machine = local_res.machine;
    const sort_internet = sortData(local_res && internet)
    const internetFirstindexStaus = sort_internet && sort_internet.length > 0 && sort_internet !== undefined && sort_internet[0].status
    setInterReverse(internetFirstindexStaus)
    const sort_machine = sortData(local_res && machine)
    const machineFirstindexStaus = sort_machine && sort_machine.length > 0 && sort_machine !== undefined && sort_machine[0].status
    setMachReverse(machineFirstindexStaus)
    // const filter_internet = filterArray(sort_internet);
    // const filter_machine = filterArray(sort_machine);

    let mergedMachine = [];
    for (let i = 0; i < sort_machine.length; i += 2) {
      const firstObject = sort_machine[i];
      const secondObject = i + 1 < sort_machine.length ? sort_machine[i + 1] : null;

      if (firstObject.status === "true") {
        const machine_connect = firstObject ? firstObject.datetime : null;
        const connectStatus = firstObject ? firstObject.status : null;
        const machine_disconnect = secondObject ? secondObject.datetime : null;
        const disconnectStatus = secondObject ? secondObject.status : null;

        mergedMachine.push({
          machine_connect,
          connectStatus,
          machine_disconnect,
          disconnectStatus,
        });
      }

      else if (firstObject.status === "false") {
        const machine_disconnect = firstObject ? firstObject.datetime : null;
        const disconnectStatus = firstObject ? firstObject.status : null;
        const machine_connect = secondObject ? secondObject.datetime : null;
        const connectStatus = secondObject ? secondObject.status : null;

        mergedMachine.push({
          machine_disconnect,
          disconnectStatus,
          machine_connect,
          connectStatus,
        });
      }

    }

    setMachine_arr(mergedMachine)



    const mergedInternet = [];

    for (let i = 0; i < sort_internet.length; i += 2) {
      const firstObject = sort_internet[i];
      const secondObject = i + 1 < sort_internet.length ? sort_internet[i + 1] : null;

      if (firstObject.status === "true") {
        const internet_connect = firstObject ? firstObject.datetime : null;
        const connectStatus = firstObject ? firstObject.status : null;
        const internet_disconnect = secondObject ? secondObject.datetime : null;
        const disconnectStatus = secondObject ? secondObject.status : null;

        mergedInternet.push({
          internet_connect,
          connectStatus,
          internet_disconnect,
          disconnectStatus,
        });
      }

      else if (firstObject.status === "false") {
        const internet_disconnect = firstObject ? firstObject.datetime : null;
        const disconnectStatus = firstObject ? firstObject.status : null;
        const internet_connect = secondObject ? secondObject.datetime : null;
        const connectStatus = secondObject ? secondObject.status : null;

        mergedInternet.push({
          internet_connect,
          connectStatus,
          internet_disconnect,
          disconnectStatus,
        });
      }

    }

    setInternet_arr(mergedInternet)
  }, [currentView])

  console.log('dofihasdiof', machine_arr);

  // useEffect(() => {
  //   const local_res = response.length > 0 && response !== undefined && response.filter((item) => {
  //     return item._id === currentView
  //   })[0]
  //   let internet = local_res && local_res.internet;
  //   console.log('sdfdadff', local_res);
  //   let machine = local_res.machine;
  //   const sort_internet = local_res && internet.sort((a, b) => new Date(a.internet) - new Date(b.internet))
  //   const sort_machine = local_res && machine.sort((a, b) => new Date(a.machine) - new Date(b.machine))
  //   const filter_internet = filterArray(sort_internet);
  //   const filter_machine = filterArray(sort_machine);
  //   const mergedMachine = [];
  //   for (let i = 0; i < filter_machine.length; i += 2) {
  //     if (i + 1 < filter_machine.length) {
  //       const first = filter_machine[i];
  //       const second = filter_machine[i + 1];
  //       const mergedObj = {};
  //       if (first.machineStatus === "false") {
  //         mergedObj.machine_disconnect = first.machine;
  //       } else if (first.machineStatus === "true") {
  //         mergedObj.machine_connect = first.machine;
  //       }
  //       if (second.machineStatus === "false") {
  //         mergedObj.machine_disconnect = second.machine;
  //       } else if (second.machineStatus === "true") {
  //         mergedObj.machine_connect = second.machine;
  //       }
  //       mergedMachine.push(mergedObj);
  //     } else {
  //       const last = filter_machine[i];
  //       const mergedObj = {};
  //       if (last.machineStatus === "false") {
  //         mergedObj.machine_disconnect = last.machine;
  //         mergedObj.machine_connect = null
  //       } else if (last.machineStatus === "true") {
  //         mergedObj.machine_connect = last.machine;
  //         mergedObj.machine_disconnect = null;
  //       }
  //       mergedMachine.push(mergedObj);
  //     }
  //   }
  //   setMachine_arr(mergedMachine)
  //   const mergedInternet = [];
  //   for (let i = 0; i < filter_internet.length; i += 2) {
  //     if (i + 1 < filter_internet.length) {
  //       const first = filter_internet[i];
  //       const second = filter_internet[i + 1];
  //       const mergedObj = {};
  //       if (first.internetStatus === "false") {
  //         mergedObj.internet_disconnect = first.internet;
  //       } else if (first.internetStatus === "true") {
  //         mergedObj.internet_connect = first.internet;
  //       }
  //       if (second.internetStatus === "false") {
  //         mergedObj.internet_disconnect = second.internet;
  //       } else if (second.internetStatus === "true") {
  //         mergedObj.internet_connect = second.internet;
  //       }
  //       mergedInternet.push(mergedObj);
  //     } else {
  //       const last = filter_internet[i];
  //       const mergedObj = {};
  //       if (last.internetStatus === "false") {
  //         mergedObj.internet_disconnect = last.internet;
  //         mergedObj.internet_connect = null
  //       } else if (last.internetStatus === "true") {
  //         mergedObj.internet_connect = last.internet;
  //         mergedObj.internet_disconnect = null;
  //       }
  //       mergedInternet.push(mergedObj);
  //     }
  //   }
  //   setInternet_arr(mergedInternet)
  // }, [currentView])



  useEffect(() => {
    specific_store()
  }, [])




  return (
    <div>
      <div className='back_arrow my-1'>
        <Link style={{ textDecoration: 'none' }} to={'/store-activity'}>
          <button style={{ background: 'none', border: 'none' }} title='Back to store activity'>
            <ArrowLeft size={16} />
          </button>
        </Link>
      </div>
      <h6>{json.label}</h6>
      {response.length > 0 ?
        <>
          <Card className="my-4" style={{ backgroundColor: "" }}>
            <Row>
              <Col
                className="h-100 align-middle px-4 text-right"
                style={{ height: "64px" }}
              >
                <ButtonGroup size="sm" style={{ borderRadius: "0px" }}>
                  {response !== undefined && response.length > 0 && response.map((item, index) => {
                    return (
                      <Button
                        style={tabStyle(item._id, currentView)}
                        key={index}
                        onClick={() => {
                          setCurrentView(item._id)
                        }}
                      >
                        {item.storeName}
                      </Button>
                    )
                  })}
                </ButtonGroup>
              </Col>
            </Row>
          </Card>
          <Row className="mb-3">
            <div className="main_connection">

              {machreverse &&
                <div className="main_ball">
                  <h5 style={{ textAlign: 'center' }}>Machine Connection Detail</h5>
                  {machine_arr !== undefined && machine_arr.length > 0 && machine_arr.map((item) => {
                    console.log("sjofopewf", machine_arr);
                    const zone = "UTC"
                    const machine_dis_final = convertToLocalTime(item.machine_disconnect, zone);
                    const machine_con_final = convertToLocalTime(item.machine_connect, zone)
                    return (
                      <>
                        <div className="status_hist">
                          {
                            item.machine_connect !== null &&
                            <div className="conn" style={{ background: item.machine_connect !== null || undefined || '' ? 'green' : '' }} > <div className='tim'>{item.machine_connect !== null || undefined || "" ? machine_con_final : 'NAN'}</div>Connect</div>
                          }

                          <div className="line_his"></div>
                          {
                            item.machine_disconnect !== null &&
                            <div className="diss" style={{ background: item.machine_disconnect !== null || undefined || '' ? 'red' : '' }} > <div className='tim'>{item.machine_disconnect !== null || undefined || "" ? machine_dis_final : 'NAN'}</div> Disconnect</div>
                          }

                        </div>
                      </>
                    )
                  })}
                </div>
              }

              {machreverse === false && (
                <div className="main_ball">
                  <h5 style={{ textAlign: 'center' }}>Machine Connection Detail</h5>
                  {machine_arr !== undefined && machine_arr.length > 0 && machine_arr.map((item) => {
                    console.log("sjofopewf", machine_arr);
                    const zone = "UTC"
                    const machine_dis_final = convertToLocalTime(item.machine_disconnect, zone);
                    const machine_con_final = convertToLocalTime(item.machine_connect, zone)
                    return (
                      <>
                        <div className="status_hist">
                          {
                            item.machine_disconnect !== null &&
                            <div className="diss" style={{ background: item.machine_disconnect !== null || undefined || '' ? 'red' : '' }} > <div className='tim'>{item.machine_disconnect !== null || undefined || "" ? machine_dis_final : 'NAN'}</div> Disconnect</div>
                          }
                          <div className="line_his"></div>
                          {
                            item.machine_connect !== null &&
                            <div className="conn" style={{ background: item.machine_connect !== null || undefined || '' ? 'green' : '' }} > <div className='tim'>{item.machine_connect !== null || undefined || "" ? machine_con_final : 'NAN'}</div>Connect</div>
                          }
                        </div>
                      </>
                    )
                  })}
                </div>

              )}

              {interreverse &&

                <div className="main_ball">
                  <h5 style={{ textAlign: 'center' }}>Internet Connection Detail</h5>
                  {internet_arr !== undefined && internet_arr.length > 0 && internet_arr.map((item) => {
                    const zone = "UTC"
                    const internet_dis_final = convertToLocalTime(item.internet_disconnect, zone);
                    const internet_con_final = convertToLocalTime(item.internet_connect, zone)
                    return (
                      <>
                        <div className="status_hist">
                          {
                            item.internet_connect !== null &&
                            <div className="conn" style={{ background: item.internet_connect !== null || undefined || '' ? 'green' : '' }} > <div className='tim'>{item.internet_connect !== null || undefined || "" ? internet_con_final : 'NAN'}</div>Connect</div>
                          }
                          <div className='line_his'></div>
                          {
                            item.internet_disconnect !== null &&
                            <div className="diss" style={{ background: item.internet_disconnect !== null || undefined || '' ? 'red' : '' }} > <div className='tim'>{item.internet_disconnect !== null || undefined || "" ? internet_dis_final : 'NAN'}</div> Disconnect</div>
                          }

                        </div>
                      </>
                    )
                  })}
                </div>

              }

              {interreverse === false && (
                <div className="main_ball">
                  <h5 style={{ textAlign: 'center' }}>Internet Connection Detail</h5>
                  {internet_arr !== undefined && internet_arr.length > 0 && internet_arr.map((item) => {
                    const zone = "UTC"
                    const internet_dis_final = convertToLocalTime(item.internet_disconnect, zone);
                    const internet_con_final = convertToLocalTime(item.internet_connect, zone)
                    return (
                      <>
                        <div className="status_hist">
                          {
                            item.internet_disconnect !== null &&
                            <div className="diss" style={{ background: item.internet_disconnect !== null || undefined || '' ? 'red' : '' }} > <div className='tim'>{item.internet_disconnect !== null || undefined || "" ? internet_dis_final : 'NAN'}</div> Disconnect</div>
                          }
                          <div className='line_his'></div>
                          {
                            item.internet_connect !== null &&
                            <div className="conn" style={{ background: item.internet_connect !== null || undefined || '' ? 'green' : '' }} > <div className='tim'>{item.internet_connect !== null || undefined || "" ? internet_con_final : 'NAN'}</div>Connect</div>
                          }
                        </div>
                      </>
                    )
                  })}
                </div>
              )}


            </div>
          </Row>
        </>
        :
        <div className="no_data">
          Nothing to show
          <TbMoodCry className='cry' />
        </div>
      }
    </div>
  )
}
export default Storehistory