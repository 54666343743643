import React from 'react'
import { Col, ListGroup, Row } from 'reactstrap'
import Slider from '../../Slider/Slider'
import "../MainFilter.css"
import "../../../App.css"

export default function TimeRangeSlider({heading, setTimeFrom,setTimeTo,timeTo,timeFrom }) {
    return (
        <div>
            <h4 className='filter-heading'>{heading}</h4>
            <div className='scrollbar ' id="style-3" style={{ width: "100%", overflowX: 'hidden' }}>
                <>
                    <div className='mt-3' >
                        <Slider
                            setFrom={(output) => setTimeFrom(output)}
                            setTo={(output) => setTimeTo(output)}
                            to={timeTo}
                            from={timeFrom}
                            min={0} max={24} />
                    </div>
                    <Row className='mt-4'>
                        <Col md={6} >
                            {(timeFrom)}
                        </Col>
                        <Col md={6} className='text-end'>
                            {(timeTo)}
                        </Col>
                    </Row>
                </>
            </div>
        </div>
    )
}
