import React from 'react'
import "../MainFilter.css"
import "../../../App.css"
import { Dropdown, DropdownMenu, DropdownToggle, UncontrolledDropdown, Row, Col, ListGroup, ListGroupItem, Button, Input, InputGroup, Label, ButtonGroup, CardBody, CardHeader, Card, DropdownItem } from 'reactstrap';


export default function StoresTypeHeader({ classifyByClasses, classifyFlag, setClassifyFlag, showToggle, setTrafficType, trafficType = [0], pageTitle='' }) {


    return (

        <div className='d-flex' style={{ width: "100%" }}>
            <h4 className='filter-heading'>Stores</h4>
            {showToggle &&
                <div className='d-flex' style={{ marginLeft: 'auto', paddingTop: '0.34rem' }}>

 
                    { pageTitle === "Traffic" &&
                        <UncontrolledDropdown className="me-2 " direction="start" style={{ marginTop: "-0.59rem" }}>
                            <DropdownToggle caret color="" className='filter-heading'>
                                {Array.isArray(trafficType) ? 'Both' :
                                    (trafficType == 0 ? 'Customer' : 'Employee')
                                }
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem onClick={() => setTrafficType('0')} style={{ fontSize: '12px' }} color="primary">
                                    Customer
                                </DropdownItem>
                                <DropdownItem onClick={() => setTrafficType('1')} style={{ fontSize: '12px' }} color="primary">
                                    Employee
                                </DropdownItem>
                                <DropdownItem onClick={() => setTrafficType(['0', '1'])} style={{ fontSize: '12px' }} color="primary">
                                    Both
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>

                    }

                    {Object.keys(classifyByClasses ? classifyByClasses : {}).length > 0 &&
                        <div className='d-flex'>

                            <Label htmlFor='classes' className="form-check-label filter-heading" style={{ marginTop: '-0.6rem' }}>Class</Label>
                            <div className='' style={{ marginTop: "" }}>
                                <Label className='switch'>
                                    <input className='classInput' type='checkbox' id='classes' name='classes' defaultChecked={classifyFlag === 'grade' && true} onChange={classifyFlag === 'city' ? () => setClassifyFlag('grade') : () => setClassifyFlag('city')}></input>
                                    <span className='sliderr round'></span>
                                </Label>
                            </div>


                        </div>

                    }


                </div>}
        </div>
    )
}
